import { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Button, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { get } from 'lodash';

import InputForm from 'components/Form/Input/Input';
import SwitchForm from 'components/Form/Switch/Switch';

const DealerForm: React.FC<any> = ({ dealer, edit, onSubmit }) => {
  const methods = useForm();
  const { handleSubmit, reset } = methods;

  useEffect(() => {
    if (dealer && edit) {
      reset({
        firstName: get(dealer, 'firstName', '') || '',
        lastName: get(dealer, 'lastName', '') || '',
        email: get(dealer, 'email', '') || '',
        phone: get(dealer, 'phone', '') || '',
        description: get(dealer, 'description', '') || '',
        active: get(dealer, 'active', false),
      });
    } else {
      reset({ active: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealer, edit]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container={true} spacing={2}>
          <Grid item xs={6} md={3}>
            <InputForm name="lastName" label="Příjmení" rules={{ required: 'Vyžadováno' }} />
          </Grid>
          <Grid item xs={6} md={3}>
            <InputForm name="firstName" label="Jméno" rules={{ required: 'Vyžadováno' }} />
          </Grid>

          <Grid item xs={12}>
            <Grid container={true} spacing={2}>
              <Grid item xs={6} md={3}>
                <InputForm
                  name="email"
                  label="Email"
                  rules={{
                    required: 'Vyžadováno',
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: 'Špatný formát emailu',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <InputForm
                  name="phone"
                  label="Telefon"
                  rules={{
                    required: 'Vyžadováno',
                    pattern: {
                      value: /[0-9]/,
                      message: 'Špatný formát telefonu',
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container={true} spacing={2}>
              <Grid item xs={6} md={3}>
                <InputForm name="description" label="Poznámka" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <SwitchForm name="active" label="Je aktivní?" />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="secondary"
              component={Link}
              to={'/settings?active=dealers'}
              sx={{ mr: '10px' }}
            >
              Zpět
            </Button>
            <Button variant="contained" color="primary" type="submit">
              Uložit
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default DealerForm;
