import { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { get, filter, includes } from 'lodash';

import AutocompleteForm from 'components/Form/Autocomplete/Autocomplete';
import { getLocations } from 'modules/Settings/Locations/_api';
import useLoader from 'utils/hooks/useLoader';
import { SAMPLE_STATES } from 'utils/constants';
import { returnItems } from './_api';

const LendingReturnDialog: React.FC<any> = ({
  open,
  setOpen,
  lending,
  lendingId,
  setLending,
  setRefreshItemCase,
}) => {
  const [locations, setLocations] = useState<any>([]);
  const methods = useForm();
  const { handleSubmit, reset } = methods;
  const setLoader = useLoader();

  const handleClose = () => {
    setOpen(false);
  };

  const loadEntities = async () => {
    setLoader();
    const [resp1] = await Promise.all([getLocations()]);
    if (resp1) {
      setLocations(filter(resp1, { active: true }));
    }
    setLoader(false);
  };

  const fields = [
    { name: 'reference', label: 'Reference' },
    { name: 'id', label: 'ID' },
    { name: 'description', label: 'Popis' },
    { name: 'isCaseLabel', label: 'Vzorek je kufr' },
  ];

  const onSubmit = async (values: any) => {
    setLoader();
    const reqItems = {
      loanId: lendingId,
      items: filter(lending.lentItems, (item) => !item.case && item.lent).map((item) => ({
        itemId: item.id,
        state: get(values, 'state.id'),
        locationId: get(values, 'location.id'),
      })),
    };

    const reqCases = {
      loanId: lendingId,
      items: filter(lending.lentItems, (item) => item.case && item.lent).map((item) => ({
        itemId: item.id,
        state: get(values, 'state.id'),
        locationId: get(values, 'location.id'),
      })),
    };

    console.log(reqItems, reqCases);

    const resp = await returnItems(reqItems);
    if (resp) {
      if (reqCases.items.length > 0) {
        const resp2 = await returnItems(reqCases);
        if (resp2) {
          setLending(resp2);
        } else {
          setLending(resp);
        }
      } else {
        setLending(resp);
      }
    }
    handleClose();
    setLoader(false);
  };

  useEffect(() => {
    loadEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Dialog fullWidth={true} maxWidth="md" open={open} onClose={handleClose}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>Vrácení všech vzorků</DialogTitle>
            <DialogContent>
              <Grid container={true} spacing={2}>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table aria-label="simple table" size="small">
                      <TableBody>
                        <TableRow sx={{ border: 0 }}>
                          <TableCell component="th" scope="row">
                            <b>Umístění</b>
                          </TableCell>
                          <TableCell align="left">
                            <AutocompleteForm
                              name="location"
                              label="Umístění"
                              options={locations.map((item: any) => ({
                                id: item.id,
                                label: item.name,
                              }))}
                              disableClearable={false}
                              rules={{ required: 'Vyžadováno' }}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ border: 0 }}>
                          <TableCell component="th" scope="row">
                            <b>Stav vzorku po vrácení</b>
                          </TableCell>
                          <TableCell align="left">
                            <AutocompleteForm
                              name="state"
                              label="Stav"
                              options={filter(
                                SAMPLE_STATES,
                                (item) =>
                                  !includes(
                                    [
                                      'INTERNAL_USAGE',
                                      'SOLD',
                                      'UNDEFINED',
                                      'LENT',
                                      'INCOMPLETE',
                                      'IN_CASE',
                                    ],
                                    item.id,
                                  ),
                              )}
                              disableClearable={false}
                              multiple={true}
                              rules={{ required: 'Vyžadováno' }}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button color="secondary" variant="contained" onClick={handleClose}>
                Zavřít
              </Button>
              <Button color="primary" variant="contained" type="submit">
                Potvrdit vrácení
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </>
  );
};

export default LendingReturnDialog;
