import { api } from 'utils/hooks/useApi';
import { get } from 'lodash';

export const getBarCodes = (data: any): Promise<any> =>
  api()
    .post(`/pae-api/barCode/generate/2D`, data)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });
