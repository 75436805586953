import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import DealerForm from './DealerForm';
import { createDealer } from './_api';
import useLoader from 'utils/hooks/useLoader';
import { get } from 'lodash';

const DealerCreate: React.FC<any> = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const setLoader = useLoader();

  const onSubmit = async (values: any) => {
    setLoader();
    const resp = await createDealer({
      firstName: values.firstName ? values.firstName : undefined,
      lastName: values.lastName ? values.lastName : undefined,
      email: values.email ? values.email : undefined,
      phone: values.phone ? values.phone : undefined,
      description: values.description ? values.description : undefined,
      active: values.active,
    });
    if (get(resp, 'status')) {
      enqueueSnackbar('Obchodník byl úspěšně vytvořen', { variant: 'success' });
      navigate(`/settings?active=dealers`);
    } else {
      enqueueSnackbar(get(resp, 'errorText') || 'Obchodníka se nepovedlo vytvořit', {
        variant: 'error',
      });
    }
    setLoader(false);
  };

  return (
    <div>
      <Typography variant="h6" gutterBottom component="div">
        Přidání obchodníka
      </Typography>
      <DealerForm onSubmit={onSubmit} />
    </div>
  );
};

export default DealerCreate;
