import { useEffect, useState, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Button, Grid, Paper, Box, Tooltip, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { get, find, throttle, compact, isEqual, isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';

import InputForm from 'components/Form/Input/Input';
import DateForm from 'components/Form/Date/Date';
import AutocompleteForm from 'components/Form/Autocomplete/Autocomplete';
import useLoader from 'utils/hooks/useLoader';
import { LENDING_PERIODS } from 'utils/constants';
import { getUsers } from 'modules/Settings/Users/_api';
import { getDealers } from 'modules/Settings/Dealers/_api';
import useSort from 'utils/hooks/useSort';
import SamplesResults from 'modules/Samples/SamplesResults';
import { removeSampleToReservation, setSamplesInReservation } from 'modules/Samples/_actions';
import { getCustomerCompanies } from 'modules/Settings/Customers/_api';
import useRoleValidation from 'utils/hooks/useRoleValidation';
import { saveLendigsValues } from 'modules/Lendings/_actions';
import moment from 'moment';

const LendingForm: React.FC<any> = ({
  onSubmit,
  setOpen,
  lending,
  edit,
  setAddCustomerOpen,
  addCustomerOpen,
  setAddPersonOpen,
  addPersonOpen,
  newCustomer,
  newPerson,
  resetForm,
}) => {
  const methods = useForm();
  const { handleSubmit, reset, watch, getValues, setValue } = methods;
  const [users, setUsers] = useState<any>([]);
  const [dealers, setDealers] = useState<any>([]);
  const [companies, setCompanies] = useState<any>([]);
  const setLoader = useLoader();
  const dispatch = useDispatch();
  const csSort = useSort();
  const { checkRole } = useRoleValidation();
  const reservationItems = useSelector((state: any) => get(state, 'app.reservationItems', []));
  const lendingForm = useSelector((state: any) => get(state, 'app.lendingForm'));
  const loggedUser = useSelector((state: any) => get(state, 'app.user', ''));
  const isAdmin = get(loggedUser, 'workplaces[0].id', '') === 1;

  const values = watch();
  console.log(values);
  const company = get(values, 'company');

  useEffect(() => {
    if (!isEqual(values, lendingForm)) {
      dispatch(saveLendigsValues(values));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const loadEntities = async () => {
    setLoader();
    const [resp1, resp2, resp3] = await Promise.all([
      getUsers(),
      getDealers(),
      getCustomerCompanies(),
    ]);
    if (resp1) {
      const sortedUsers = csSort(resp1, 'lastName'); // sort s diakritikou
      setUsers(sortedUsers);
    }
    if (resp2) {
      const sortedDealers = csSort(resp2, 'lastName'); // sort s diakritikou
      setDealers(sortedDealers);
    }
    if (resp3) {
      const sortedCompanies = csSort(resp3, 'name'); // sort s diakritikou
      setCompanies(sortedCompanies);
    }
    setLoader(false);
  };

  const columns = useMemo(
    () => [
      { name: 'code', title: 'ID' },
      { name: 'reference', title: 'Reference' },
      { name: 'description', title: 'Popis' },
      { name: 'stateLabel', title: 'Stav' },
      { name: 'case_note', title: 'Kufr/Poznámka' },
      {
        name: 'location',
        title: 'Umístění',
        getCellValue: (row: any) => get(row, 'location.name', ''),
      },
      {
        name: 'department',
        title: 'Oddělení',
        getCellValue: (row: any) => get(row, 'department.name', ''),
      },
      { name: 'createdDate', title: 'Vytvořeno' },
      {
        name: 'actions',
        title: 'Akce',
        getCellValue: (row) => (
          <Tooltip title="Odebrat">
            <IconButton
              color="primary"
              sx={{ p: '5px' }}
              size="small"
              onClick={() => {
                dispatch(removeSampleToReservation(row));
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ),
      },
    ],
    [dispatch],
  );

  useEffect(() => {
    if (lending && edit) {
      reset({
        createdDate: get(lending, 'createdDate', null) || null,
        daysForLoan: find(LENDING_PERIODS, { id: get(lending, 'daysForLoan') }) || '',
        description: get(lending, 'description', '') || '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lending, edit]);

  useEffect(() => {
    if (
      company &&
      !isEmpty(lendingForm) &&
      get(company, 'id', 0) !== get(lendingForm, 'company.id', 1)
    ) {
      setValue('contactPerson', null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, lendingForm]);

  useEffect(() => {
    if (newCustomer) {
      setValue('company', {
        ...newCustomer,
        label: `${newCustomer.name || ''} (adresa: ${newCustomer.address || ''})`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newCustomer]);

  useEffect(() => {
    if (newPerson) {
      setValue('contactPerson', {
        ...newPerson,
        label: `${newPerson.lastName || ''} ${newPerson.firstName || ''} (telefon: ${
          newPerson.phone || ''
        }, email: ${newPerson.email})`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPerson]);

  useEffect(() => {
    if (!isAdmin) {
      setValue('createdBy', {
        id: loggedUser.id,
        label: `${loggedUser.lastName || ''} ${loggedUser.firstName || ''}`,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser]);

  useEffect(() => {
    if (addCustomerOpen !== null || addPersonOpen !== null) {
      loadEntities();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addCustomerOpen, addPersonOpen]);

  useEffect(() => {
    if (resetForm) {
      reset({
        description: undefined,
        createdDate: null,
        loanedTo: null,
        createdBy: undefined,
        dealer: undefined,
        contactPerson: undefined,
        company: undefined,
      });
      saveLendigsValues(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetForm]);

  useEffect(() => {
    loadEntities();
    if (lendingForm) {
      reset(lendingForm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container={true} spacing={2}>
          <Grid item xs={12} sm={4} md={3} lg={2}>
            <DateForm
              name="createdDate"
              label="Zapůjčeno od"
              rules={{
                required: 'Vyžadováno',
                validate: (value: any) => {
                  return value <= moment().add(15, 'days')
                    ? true
                    : 'Nelze vytvořit zápůjčku, která začíná za více než 15 dní od dnešního data';
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3} lg={2}>
            <DateForm
              name="loanedTo"
              label="Zapůjčeno do"
              rules={{
                required: 'Vyžadováno',
                validate: (value: any) => {
                  return getValues().createdDate < value
                    ? true
                    : 'Zapůjčeno od musí být dříve než zapůjčeno do';
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container={true} spacing={2}>
              <Grid item xs={12} sm={4} md={3} lg={2}>
                <AutocompleteForm
                  name="createdBy"
                  label="Zapůjčil"
                  options={users.map((item: any) => ({
                    id: item.id,
                    label: `${item.lastName || ''} ${item.firstName || ''}`,
                  }))}
                  rules={{ required: 'Vyžadováno' }}
                  disabled={!isAdmin}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={2}>
                <AutocompleteForm
                  name="dealer"
                  label="Obchodník"
                  options={dealers.map((item: any) => ({
                    id: item.id,
                    label: `${item.lastName || ''} ${item.firstName || ''}`,
                  }))}
                  rules={{ required: 'Vyžadováno' }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container={true}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <AutocompleteForm
                  name="company"
                  label="Zákazník"
                  options={companies.map((item: any) => ({
                    ...item,
                    label: `${item.name || ''} (adresa: ${item.address || ''})`,
                  }))}
                  rules={{ required: 'Vyžadováno' }}
                />
              </Grid>
              <Grid item xs={2} sm={2} md={1} lg={1}>
                <Tooltip
                  title={`Přidat nového zákazníka ${
                    checkRole('ROLE_CUSTOMER_COMPANY_CREATE') ? '' : '(nemáte právo)'
                  }`}
                >
                  <span>
                    <IconButton
                      color="primary"
                      size="medium"
                      onClick={() => {
                        setAddCustomerOpen(true);
                      }}
                      disabled={!checkRole('ROLE_CUSTOMER_COMPANY_CREATE')}
                    >
                      <AddCircleIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container={true}
              spacing={2}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <AutocompleteForm
                  name="contactPerson"
                  label="Kontaktní osoba"
                  options={get(
                    find(companies, { id: get(company, 'id') }),
                    'contactPersons',
                    [],
                  ).map((item: any) => ({
                    ...item,
                    label: `${item.firstName || ''} ${item.lastName || ''} (telefon: ${
                      item.phone || ''
                    }, email: ${item.email})`,
                  }))}
                  rules={{ required: 'Vyžadováno' }}
                  disabled={!company}
                />
              </Grid>
              <Grid item xs={2} sm={2} md={1} lg={1}>
                <Tooltip
                  title={`Přidat novou kontaktní osobu ${
                    checkRole('ROLE_CUSTOMER_PERSON_CREATE')
                      ? company
                        ? ''
                        : '(musíte zvolit zákazníka)'
                      : '(nemáte právo)'
                  }`}
                >
                  <span>
                    <IconButton
                      color="primary"
                      size="medium"
                      onClick={() => {
                        setAddPersonOpen({ open: true, customer: company });
                      }}
                      disabled={!company || !checkRole('ROLE_CUSTOMER_PERSON_CREATE')}
                    >
                      <AddCircleIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container={true} spacing={2}>
              <Grid item xs={12} sm={8} md={6} lg={4}>
                <InputForm name="description" label="Poznámka" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <Box sx={{ m: '10px' }}>
                <b>Seznam vzorků v zápůjčce</b>
                <Button
                  variant="contained"
                  startIcon={<AddCircleIcon />}
                  size="small"
                  sx={{ m: '10px' }}
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Přidat vzorek
                </Button>
              </Box>
              <Box>
                <SamplesResults columns={columns} samples={reservationItems} />
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="secondary"
              sx={{ mr: '10px' }}
              onClick={() => {
                dispatch(setSamplesInReservation([]));
              }}
            >
              Smazat všechny vzorky
            </Button>
            <Button
              variant="contained"
              color="secondary"
              component={Link}
              to={'/lendings'}
              sx={{ mr: '10px' }}
            >
              Zpět
            </Button>

            <Button variant="contained" color="primary" type="submit">
              Uložit
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default LendingForm;
