import { get, find } from 'lodash';
import { ERROR_CODES } from './constants';

export const processError = (error: any) => {
  const respMessage = JSON.parse(get(error, 'response.data.message') || '');
  try {
    console.log('Error: ', get(respMessage, 'message'));

    const code = get(respMessage, 'code');
    console.log(code);
    const errorText = find(ERROR_CODES, { code });
    console.log(errorText);
    return {
      status: false,
      errorText: get(errorText, 'label'),
      errorMessage: get(respMessage, 'message'),
    };
  } catch (error) {
    return { status: false, errorText: '', errorMessage: get(respMessage, 'message') };
  }
};
