const sorter = new Intl.Collator('cs', { usage: 'sort' });

export const compareNumber = (a: any, b: any) => {
  return a - b;
};
export const compareName = (a: any, b: any) => {
  return sorter.compare(a, b);
};

export const translateGrid = {
  tableMessages: {
    noData: 'Žádná data',
  },
  filterRowMessages: {
    filterPlaceholder: 'Filtr...',
  },
  pagingPanelMessages: {
    showAll: 'Vše',
    rowsPerPage: 'Počet řádků na stránku',
    // info: 'Zeilen {from} bis {to} ({count} Elemente)',
  },
};

const useGridUtils = () => {
  return { compareName, compareNumber, translateGrid };
};

export default useGridUtils;
