import { get } from 'lodash';
import { api } from 'utils/hooks/useApi';
import { processError } from 'utils/utils';

export const getUsers = (active: boolean = true): Promise<any> =>
  api()
    .get(`/pae-api/user/getAll?allowedAuthMethodId=db&active=${active}`)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });

export const getUser = (id: any): Promise<any> =>
  api()
    .get(`/pae-api/user/get?id=${id}&allowedAuthMethodId=db`)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });

export const createUser = (values: any): Promise<any> =>
  api()
    .post(`/pae-api/user/create?allowedAuthMethodId=db`, values)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });

export const updateUser = (values: any): Promise<any> =>
  api()
    .post(`/pae-api/user/update?allowedAuthMethodId=db`, values)
    .then((response: any) => ({ status: true, data: get(response, 'data') }))
    .catch((error) => {
      return processError(error);
    });

export const getUserWorkplaces = (username: string): Promise<any> =>
  api()
    .get(`/pae-api/user/getUserWorkPlaces?username=${username}`)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });

export const getUserDepartments = (username: string): Promise<any> =>
  api()
    .get(`/pae-api/user/getUserDepartments?username=${username}`)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });

export const manageUserDepartments = (values: any): Promise<any> =>
  api()
    .post(`/pae-api/user/manageUserDepartments`, values)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });

export const deleteUser = (id: any): Promise<any> =>
  api()
    .delete(`/pae-api/user/delete?id=${id}&allowedAuthMethodId=db`)
    .then((response: any) => ({ status: true, data: get(response, 'data') }))
    .catch((error) => {
      return processError(error);
    });
