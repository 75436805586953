import { useDispatch } from 'react-redux';
import { ToggleLoader } from './_actions';

const useLoader = () => {
  const dispatch = useDispatch();
  const toggleLoader = (loaderState = true) => dispatch(ToggleLoader(loaderState));
  return toggleLoader;
};

export default useLoader;
