const localStorage = window.localStorage;

// These three functions are pulled from hook as they are needed outside React sometimes
export const getItem = (key: string) => localStorage.getItem(`${key}`);
export const putItem = (key: string, value: string) => localStorage.setItem(`${key}`, value);
export const removeItem = (key: string) => localStorage.removeItem(`${key}`);

export const useStorage = () => {
  return {
    putItem,
    getItem,
    removeItem: (key: string) => localStorage.removeItem(`${key}`),
    clear: () => localStorage.clear(),
  };
};
