import { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { get } from 'lodash';

import PersonForm from './PersonForm';
import { createCustomerPerson, getCustomerCompany } from './_api';
import useLoader from 'utils/hooks/useLoader';

const PersonCreate: React.FC<any> = () => {
  const [customer, setCustomer] = useState<any>(null);
  const navigate = useNavigate();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const setLoader = useLoader();

  const loadCustomer = async () => {
    setLoader();
    const resp = await getCustomerCompany(get(params, 'customerId'));
    if (resp) {
      setCustomer(resp);
    } else {
      setCustomer(false);
    }
    setLoader(false);
  };

  const onSubmit = async (values: any) => {
    setLoader();
    const resp = await createCustomerPerson({ companyId: get(params, 'customerId'), ...values });
    if (get(resp, 'status')) {
      enqueueSnackbar('Kontaktní osoba byla úspěšně vytvořena', { variant: 'success' });
      navigate(`/settings?active=customers`);
    } else {
      enqueueSnackbar(get(resp, 'errorText') || 'Kontaktní osobu se nepovedlo vytvořit', {
        variant: 'error',
      });
    }
    setLoader(false);
  };

  useEffect(() => {
    loadCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Typography variant="h6" gutterBottom component="div">
        Přidání kontaktní osoby, zákazník: {get(customer, 'name')}
      </Typography>
      <PersonForm onSubmit={onSubmit} />
    </div>
  );
};

export default PersonCreate;
