import { useController } from 'react-hook-form';
import { get, isEmpty } from 'lodash';
import { TextField, Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

const AutocompleteForm = ({
  control,
  name,
  label,
  options,
  placeholder,
  rules,
  disabled = false,
  disableClearable = true,
  defaultValue = null,
  groupBy = undefined,
}: any) => {
  const {
    field: { ref, onChange, value, ...inputProps },
    formState: { errors },
    // meta: { invalid, isTouched, isDirty },
  } = useController({
    name,
    control,
    defaultValue,
    rules,
  });
  let isError = false;
  let errorMessage = '';
  if (!isEmpty(errors) && get(errors, name) !== undefined) {
    isError = true;
    errorMessage = get(errors, `${name}.message`);
  }
  const required = get(rules, 'required', false);
  return (
    <Autocomplete
      {...inputProps}
      value={value || null}
      id={name}
      options={options}
      getOptionLabel={(option: any) => option.label}
      isOptionEqualToValue={(option: any, value: any) =>
        get(option, 'id', 1) === get(value, 'id', 2)
      }
      groupBy={groupBy}
      getOptionDisabled={(option) => get(option, 'disabled', false)}
      onChange={(_, data) => onChange(data)}
      disabled={disabled}
      disableClearable={disableClearable}
      renderOption={(props, option) => (
        <Box component="li" {...props} key={get(option, 'id')}>
          {get(option, 'label')}
        </Box>
      )}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={label}
            placeholder={placeholder}
            fullWidth
            name={name}
            variant="outlined"
            margin="dense"
            size="small"
            error={isError}
            helperText={errorMessage}
            InputLabelProps={{
              className: required ? 'required-label' : '',
              required: !!required,
            }}
          />
        );
      }}
    />
  );
};
export default AutocompleteForm;
