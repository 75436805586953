import React, { useState, useMemo } from 'react';
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Paper,
  Box,
  Tooltip,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { get, includes, isArray, filter, head } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import SampleSearchForm from 'modules/Samples/SampleSearchForm';
import SamplesResults from 'modules/Samples/SamplesResults';
import useLoader from 'utils/hooks/useLoader';
import { getSamples } from 'modules/Samples/_api';
import { addSampleToReservation, removeSampleToReservation } from 'modules/Samples/_actions';

import useItemConvert from 'utils/hooks/useItemConvert';
import useUserInfo from 'utils/hooks/useUserInfo';

const SampleAddDialog: React.FC<any> = ({ open, handleClose, samplesAdded }) => {
  const setLoader = useLoader();
  const dispatch = useDispatch();
  const { sampleConvert } = useItemConvert();
  const { enqueueSnackbar } = useSnackbar();
  const { userIsAdmin, userDepartments } = useUserInfo();
  const [samples, setSamples] = useState<any>(false);
  const reservationItems = useSelector((state: any) => get(state, 'app.reservationItems', []));
  const reservationItemsIds = reservationItems.map((item: any) => item.id);
  const reservationDepartmentId = get(head(reservationItems), 'department.id', null);

  const onSearch = async (values: any) => {
    setLoader();
    // console.log(values);
    const resp = await getSamples({
      code: values.code ? values.code : undefined,
      reference: values.reference ? values.reference : undefined,
      state:
        isArray(values.state) && values.state.length > 0
          ? values.state.map((item: any) => item.id)
          : undefined,
      description: values.description ? values.description : undefined,
      isCase: includes([1, 2], get(values, 'isCase.id'))
        ? values.isCase.id === 1
          ? true
          : false
        : undefined,
      locationId: get(values, 'location.id'),
      departmentId: get(values, 'department.id'),
    });
    if (isArray(resp)) {
      const filtered = filter(resp, (item) => item.itemCase === null);
      setSamples(filtered.map((item: any) => sampleConvert(item)));
    } else {
      setSamples([]);
    }
    setLoader(false);
  };

  const columns = [
    { name: 'reference', title: 'Reference' },
    { name: 'code', title: 'ID' },
    { name: 'description', title: 'Popis' },
    { name: 'stateLabel', title: 'Stav' },
    { name: 'case_note', title: 'Kufr/Poznámka' },
    {
      name: 'location',
      title: 'Umístění',
      getCellValue: (row: any) => get(row, 'location.name', ''),
    },
    {
      name: 'department',
      title: 'Oddělení',
      getCellValue: (row: any) => get(row, 'department.name', ''),
    },
    { name: 'createdDate', title: 'Vytvořeno' },
    {
      name: 'actions',
      title: 'Akce',
      getCellValue: (row: any) => {
        const canByDepartments =
          userIsAdmin ||
          includes(
            userDepartments.map((dep: any) => dep.id),
            get(row, 'department.id'),
          );
        return !includes(reservationItemsIds, row.id) && row.canLent ? (
          <Tooltip title="Přidat do zápůjčky">
            <span>
              <IconButton
                color="primary"
                sx={{ p: '5px' }}
                size="small"
                onClick={() => {
                  dispatch(addSampleToReservation(row));
                  enqueueSnackbar(
                    `Vzorek ${row.reference} (id: ${row.code}) byl přidán do zápůjčky`,
                    {
                      variant: 'success',
                    },
                  );
                }}
                disabled={
                  !canByDepartments ||
                  // lze vypůjčit vzorky pouze z jednoho oddělení
                  (reservationDepartmentId !== null &&
                    reservationDepartmentId !== get(row, 'department.id', 0))
                }
              >
                <AddCircleIcon />
              </IconButton>
            </span>
          </Tooltip>
        ) : null;
      },
    },
  ];

  const columnsSelected = useMemo(
    () => [
      { name: 'code', title: 'ID' },
      { name: 'reference', title: 'Reference' },
      { name: 'description', title: 'Popis' },
      { name: 'stateLabel', title: 'Stav' },
      { name: 'case_note', title: 'Kufr/Poznámka' },
      {
        name: 'location',
        title: 'Umístění',
        getCellValue: (row: any) => get(row, 'location.name', ''),
      },
      {
        name: 'department',
        title: 'Oddělení',
        getCellValue: (row: any) => get(row, 'department.name', ''),
      },
      { name: 'createdDate', title: 'Vytvořeno' },
      {
        name: 'actions',
        title: 'Akce',
        getCellValue: (row: any) => (
          <Tooltip title="Odebrat">
            <IconButton
              color="primary"
              sx={{ p: '5px' }}
              size="small"
              onClick={() => {
                dispatch(removeSampleToReservation(row));
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ),
      },
    ],
    [dispatch],
  );

  return (
    <Dialog fullScreen open={open} onClose={handleClose}>
      <AppBar sx={{ position: 'relative', color: 'white' }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Výběr vzorků do zápůjčky
          </Typography>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box>
        <Box sx={{ m: '15px' }}>
          <b>Vybrané vzorky</b>
        </Box>
        <SamplesResults columns={columnsSelected} samples={reservationItems} />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="contained"
          sx={{ ml: '10px' }}
          onClick={() => {
            handleClose();
          }}
        >
          Ukončit výběr vzorků
        </Button>
      </Box>
      <Box sx={{ m: '15px' }}>
        <b>Vyhledat vzorky</b>
      </Box>
      <Box sx={{ m: '10px' }}>
        <SampleSearchForm onSearch={onSearch} lendings={true} resultsCount={samples.length} />
      </Box>
      <Box>
        <Paper>
          {samples !== false ? (
            <Paper>
              <SamplesResults columns={columns} samples={samples} />
            </Paper>
          ) : null}
        </Paper>
      </Box>
    </Dialog>
  );
};

export default SampleAddDialog;
