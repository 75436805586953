import { get } from 'lodash';
import { api } from 'utils/hooks/useApi';
import { processError } from 'utils/utils';

export const getSamples = (values: any): Promise<any> =>
  api()
    .post(`/pae-api/item/find`, values)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });

export const getSample = (id: any): Promise<any> =>
  api()
    .get(`/pae-api/item/get?id=${id}`)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });

export const createSample = (values: any, useObtainedCode: boolean = false): Promise<any> =>
  api()
    .post(`/pae-api/item/create`, values)
    .then((response: any) => ({ status: true, useObtainedCode, data: get(response, 'data') }))
    .catch((error) => {
      return processError(error);
    });

export const updateSample = (values: any, updateCaseContent: boolean = false): Promise<any> =>
  api()
    .post(`/pae-api/item/update?updateCaseContent=${updateCaseContent}`, values)
    .then((response: any) => ({ status: true, data: get(response, 'data') }))
    .catch((error) => {
      return processError(error);
    });

export const getCases = (): Promise<any> =>
  api()
    .post(`/pae-api/item/find`, { isCase: true })
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });

export const getItemsInCase = (id: number): Promise<any> =>
  api()
    .post(`/pae-api/item/find`, { itemCaseId: id })
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });

export const multipleUpdate = (values: any): Promise<any> =>
  api()
    .post(`/pae-api/item/multipleUpdate`, values)
    .then((response: any) => ({ status: true, data: get(response, 'data') }))
    .catch((error) => {
      return processError(error);
    });

export const addItemsToCase = (values: any): Promise<any> =>
  api()
    .post(`/pae-api/item/addItemsToCase`, values)
    .then((response: any) => ({ status: true, data: get(response, 'data') }))
    .catch((error) => {
      return processError(error);
    });

export const removeItemsFromCase = (values: any): Promise<any> =>
  api()
    .post(`/pae-api/item/removeItemsFromCase`, values)
    .then((response: any) => ({ status: true, data: get(response, 'data') }))
    .catch(() => {
      return false;
    });

export const multipleCreateSample = (values: any, useObtainedCode: boolean = false): Promise<any> =>
  api()
    .post(`/pae-api/item/multipleCreate`, { useObtainedCode, items: values })
    .then((response: any) => ({ status: true, data: get(response, 'data') }))
    .catch((error) => {
      return processError(error);
    });
