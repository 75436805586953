import { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Button, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { get } from 'lodash';

import InputForm from 'components/Form/Input/Input';
import SwitchForm from 'components/Form/Switch/Switch';

const PersonForm: React.FC<any> = ({ person, edit, onSubmit, backFunction }) => {
  const methods = useForm();
  const { handleSubmit, reset } = methods;

  useEffect(() => {
    if (person && edit) {
      reset({
        firstName: get(person, 'firstName', '') || '',
        lastName: get(person, 'lastName', '') || '',
        phone: get(person, 'phone', '') || '',
        email: get(person, 'email', '') || '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [person, edit]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container={true} spacing={2}>
          <Grid item xs={6} md={3}>
            <InputForm name="firstName" label="Jméno" rules={{ required: 'Vyžadováno' }} />
          </Grid>
          <Grid item xs={6} md={3}>
            <InputForm name="lastName" label="Příjmení" rules={{ required: 'Vyžadováno' }} />
          </Grid>
          <Grid item xs={6} md={3}>
            <InputForm
              name="phone"
              label="Telefon"
              rules={{
                required: 'Vyžadováno',
                pattern: {
                  value: /[0-9]/,
                  message: 'Špatný formát telefonu',
                },
              }}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <InputForm
              name="email"
              label="Email"
              rules={{
                required: 'Vyžadováno',
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'Špatný formát emailu',
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            {backFunction ? (
              <Button
                variant="contained"
                color="secondary"
                sx={{ mr: '10px' }}
                onClick={() => {
                  backFunction();
                }}
              >
                Zpět
              </Button>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                component={Link}
                to={'/settings?active=customers'}
                sx={{ mr: '10px' }}
              >
                Zpět
              </Button>
            )}
            <Button variant="contained" color="primary" type="submit">
              Uložit
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default PersonForm;
