import { get } from 'lodash';
import { useSelector } from 'react-redux';

const useUserInfo = () => {
  const user = useSelector((state: any) => get(state, 'app.user', []));
  const userIsAdmin = get(user, 'workplaces[0].code') === 'ADMIN';

  const userWorkplace = get(user, 'workplaces[0]');
  const userDepartments = get(user, 'departments', []);

  return { userIsAdmin, userWorkplace, userDepartments };
};

export default useUserInfo;
