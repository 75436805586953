import { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { get, pickBy, uniq, isEmpty, filter } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import LendingForm from './LendingForm';
import { createLending } from './_api';
import useLoader from 'utils/hooks/useLoader';
import SampleAddDialog from './LendingsSampleAddDialog';
import { setSamplesInReservation } from 'modules/Samples/_actions';
import LendingAddCustomerDialog from './LendingAddCustomerDialog';
import { createCustomerCompany, createCustomerPerson } from 'modules/Settings/Customers/_api';
import LendingAddPersonDialog from './LendingAddPersonDialog';
import ConfirmationDialog from 'components/ConfirmDialog/ConfirmDialog';
import useRoleValidation from 'utils/hooks/useRoleValidation';
import { saveLendigsValues } from './_actions';

const LendingCreate: React.FC<any> = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const setLoader = useLoader();
  const dispatch = useDispatch();
  const { checkRole } = useRoleValidation();

  const [openDialog, setOpenDialog] = useState<any>(false);
  const [resetForm, setResetForm] = useState<any>(false);
  const [addCustomerOpen, setAddCustomerOpen] = useState<any>(null);
  const [addPersonOpen, setAddPersonOpen] = useState<any>(null);
  const [deleteDialog, setDeleteDialog] = useState<any>({
    open: false,
    snackBarText: { ok: '', error: '' },
  });
  const [newCustomer, setNewCustomer] = useState<any>(null);
  const [newPerson, setNewPerson] = useState<any>(null);

  const [searchParams] = useSearchParams();
  const action = searchParams.get('action');
  const from = searchParams.get('from');

  const reservationItems = useSelector((state: any) => get(state, 'app.reservationItems', []));
  const lendingForm = useSelector((state: any) => get(state, 'app.lendingForm'));

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const onSubmit = async (values: any) => {
    setLoader();
    const resp = await createLending({
      createdDate: values.createdDate,
      loanedTo: values.loanedTo,
      createdById: get(values, 'createdBy.id') ? get(values, 'createdBy.id') : undefined,
      dealerId: get(values, 'dealer.id') ? get(values, 'dealer.id') : undefined,
      company: get(values, 'company') ? get(values, 'company.name') : undefined,
      companyAddress: get(values, 'company') ? get(values, 'company.address') : undefined,
      contactPersonFirstName: get(values, 'contactPerson')
        ? get(values, 'contactPerson.firstName')
        : undefined,
      contactPersonLastName: get(values, 'contactPerson')
        ? get(values, 'contactPerson.lastName')
        : undefined,
      contactPhone: get(values, 'contactPerson') ? get(values, 'contactPerson.phone') : undefined,
      contactMail: get(values, 'contactPerson') ? get(values, 'contactPerson.email') : undefined,
      description: get(values, 'description') ? get(values, 'description') : undefined,
      price: values.price,
      itemIds: uniq(reservationItems.map((item: any) => item.id)),
      protocolCompleted: true,
    });
    if (get(resp, 'status')) {
      setResetForm(true);
      enqueueSnackbar('Zápůjčka byla úspěšně vytvořena', { variant: 'success' });
      navigate(`/lendings/${get(resp, 'data.id')}`);
      dispatch(setSamplesInReservation([]));
    } else {
      enqueueSnackbar(get(resp, 'errorText') || 'Zápůjčku se nepovedlo vytvořit', {
        variant: 'error',
      });
    }
    setLoader(false);
  };

  const onSubmitCreateCustomer = async (values: any) => {
    setLoader();
    const resp = await createCustomerCompany(values);
    if (get(resp, 'status')) {
      enqueueSnackbar('Zákazník byl úspěšně vytvořen', { variant: 'success' });
      setAddCustomerOpen(false);
      setNewCustomer(get(resp, 'data'));
    } else {
      enqueueSnackbar('Zákazníka se nepovedlo vytvořit', { variant: 'error' });
    }
    setLoader(false);
  };

  const onSubmitCreatePerson = async (values: any) => {
    setLoader();
    const resp = await createCustomerPerson({
      companyId: get(addPersonOpen, 'customer.id'),
      ...values,
    });
    if (get(resp, 'status')) {
      enqueueSnackbar('Kontaktní osoba byla úspěšně vytvořena', { variant: 'success' });
      setAddPersonOpen({ open: false });
      setNewPerson(get(resp, 'data'));
    } else {
      enqueueSnackbar('Kontaktní osobu se nepovedlo vytvořit', { variant: 'error' });
    }
    setLoader(false);
  };

  useEffect(() => {
    if ((reservationItems.length > 1 || !isEmpty(pickBy(lendingForm))) && action !== 'fast') {
      setDeleteDialog({
        open: true,
        title: 'Máte v zápujčce vyplněna data',
        text: `Chcete data ponechat?`,
        snackBarText: { ok: false, error: false },
        onClickSubmit: () => {
          setDeleteDialog({ open: false });
        },
        onClose: () => {
          dispatch(
            setSamplesInReservation([
              ...filter(reservationItems, (item) => item.id == parseInt(from || '0', 10)),
            ]),
          );
          setResetForm(true);
          setDeleteDialog({ open: false, snackBarText: { ok: '', error: '' } });
        },
        deleteFnc: null,
        closeButtonTitle: 'Resetovat',
        submitButtonTitle: 'Ponechat',
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return checkRole('ROLE_LOAN_CREATE') ? (
    <div>
      <Typography variant="h6" gutterBottom component="div">
        Přidání zápůjčky
      </Typography>
      <LendingForm
        onSubmit={onSubmit}
        setOpen={setOpenDialog}
        addCustomerOpen={addCustomerOpen}
        setAddCustomerOpen={setAddCustomerOpen}
        addPersonOpen={addPersonOpen}
        setAddPersonOpen={setAddPersonOpen}
        newCustomer={newCustomer}
        newPerson={newPerson}
        resetForm={resetForm}
      />
      <SampleAddDialog open={openDialog} handleClose={handleCloseDialog} />
      <LendingAddCustomerDialog
        open={addCustomerOpen}
        setOpen={setAddCustomerOpen}
        onSubmit={onSubmitCreateCustomer}
      />
      <LendingAddPersonDialog
        open={addPersonOpen}
        setOpen={setAddPersonOpen}
        onSubmit={onSubmitCreatePerson}
      />
      <ConfirmationDialog deleteDialog={deleteDialog} setDeleteDialog={setDeleteDialog} />
    </div>
  ) : (
    <div>Nemáte práva na vytvoření zápůjčky</div>
  );
};

export default LendingCreate;
