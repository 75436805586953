import { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { range } from 'lodash';

import { getBarCodes } from './_api';
import useLoader from 'utils/hooks/useLoader';
import BarCodeForm from './BarCodeForm';

const BarCodeCreate: React.FC<any> = () => {
  const params = useParams();
  const setLoader = useLoader();

  const onSubmit = async (values: any) => {
    setLoader();
    const regenerate = values.generateOldCodes;
    const from = parseInt(values.from, 10);
    const count = parseInt(values.count, 10);
    const skipPositions = values.skipPositions ? parseInt(values.skipPositions, 10) : undefined;
    const to = from + count - 1;
    const codes = range(from, to + 1);

    const data = await getBarCodes({
      departmentId: parseInt(values.department.id, 10),
      count,
      codes: regenerate ? codes : undefined,
      skipPositions,
    });
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    var fileURL = URL.createObjectURL(blob);
    window.open(fileURL, '_blank');
    /*const linkSource = `data:application/pdf;base64,${data}`;
    var a = document.createElement('a');

    a.href = linkSource;
    a.download = `stitky_${values.from}-${to}.pdf`;
    document.body.appendChild(a);
    a.click();
    a.remove();*/
    setLoader(false);
  };

  return (
    <div style={{ marginTop: 10 }}>
      <BarCodeForm onSubmit={onSubmit} />
    </div>
  );
};

export default BarCodeCreate;
