import { useCallback, useState } from 'react';
import { Paper, Popover, Typography } from '@mui/material';
import moment from 'moment';
import {
  SortingState,
  PagingState,
  FilteringState,
  RowDetailState,
  IntegratedPaging,
  IntegratedSorting,
  IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import {
  Grid as GridTable,
  VirtualTable,
  TableHeaderRow,
  PagingPanel,
  TableFilterRow,
  TableRowDetail,
} from '@devexpress/dx-react-grid-material-ui';
import { get, filter, isNumber } from 'lodash';
import useItemConvert from 'utils/hooks/useItemConvert';

import useFormatters from 'utils/hooks/useFormatters';
import SamplesResults from 'modules/Samples/SamplesResults';

const LendingsResults: React.FC<any> = ({ rows, columns }) => {
  const [sorting, setSorting] = useState<any>([{ columnName: 'id', direction: 'desc' }]);
  const [filters, setFilters] = useState<any>([]);
  const [expandedRowIds, setExpandedRowIds] = useState<any>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const { DateTypeProvider } = useFormatters();
  const { sampleConvert } = useItemConvert();

  const handlePopoverOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setPopoverOpen(true);
  }, []);

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
    setPopoverOpen(false);
  }, []);

  const TableCell = useCallback(
    (props: any) => {
      const { column } = props;
      if (column.name === 'sampleCount') {
        return (
          /* <VirtualTable.Cell
            {...props}
            style={{ paddingTop: 0, paddingBottom: 0 }}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          />*/
          <VirtualTable.Cell {...props} style={{ paddingTop: 0, paddingBottom: 0 }} />
        );
      } else {
        return <VirtualTable.Cell {...props} style={{ paddingTop: 0, paddingBottom: 0 }} />;
      }
    },
    [handlePopoverOpen, handlePopoverClose],
  );

  const TableRow = ({ row, ...restProps }: any) => {
    let color = 'white';
    if (!row.returned && moment(row.loanedTo) < moment()) {
      color = '#f2b6b6';
    } else if (row.returned) {
      color = '#c5eacc';
    }

    return (
      <VirtualTable.Row
        {...restProps}
        sx={{
          backgroundColor: color,
        }}
      />
    );
  };
  const columnsSamples = [
    { name: 'reference', title: 'Reference' },
    { name: 'code', title: 'ID' },
    { name: 'description', title: 'Popis' },
    { name: 'stateLabel', title: 'Stav' },
    { name: 'case_note', title: 'Kufr/Poznámka' },
    {
      name: 'location',
      title: 'Umístění',
      getCellValue: (row: any) => get(row, 'location.name', ''),
    },
    {
      name: 'department',
      title: 'Oddělení',
      getCellValue: (row: any) => get(row, 'department.name', ''),
    },
    { name: 'createdDate', title: 'Vytvořeno' },
    {
      name: 'returnedBy',
      title: 'Vrátil',
      getCellValue: (row: any) =>
        `${get(row, 'returnedBy.lastName') || get(row, 'returnedBy.username') || ''} ${
          get(row, 'returnedBy.firstName') || ''
        }`,
    },
    {
      name: 'actions',
      title: 'Akce',
      getCellValue: (row: any) => null,
    },
  ];

  const RowDetail = ({ row }: any) => {
    const samples = get(row, 'lentItems', []).map((item: any) => sampleConvert(item));

    return (
      <Paper>
        <SamplesResults
          columns={columnsSamples}
          samples={filter(samples, (item: any) => !item.itemCase)}
          toolbar={false}
        />
      </Paper>
    );
  };

  const elem = document.getElementById('search-results');
  const gridTop = elem?.getBoundingClientRect()?.top;
  const gridHeight = window.innerHeight - (isNumber(gridTop) ? gridTop : 0) - 85;

  return (
    <Paper id="search-results">
      {console.log('grid render')}
      <GridTable rows={rows} columns={columns}>
        <DateTypeProvider for={['createdDate', 'loanedTo']} />
        <FilteringState filters={filters} onFiltersChange={setFilters} />
        <SortingState sorting={sorting} onSortingChange={setSorting} />
        <PagingState defaultCurrentPage={0} pageSize={100} />
        <RowDetailState
          expandedRowIds={expandedRowIds}
          onExpandedRowIdsChange={setExpandedRowIds}
        />
        <IntegratedFiltering />
        <IntegratedSorting />
        <IntegratedPaging />
        <VirtualTable
          height={gridHeight > 500 ? gridHeight : 500}
          columnExtensions={[{ columnName: 'description', wordWrapEnabled: true }]}
          cellComponent={TableCell}
          rowComponent={TableRow}
        />
        <TableHeaderRow showSortingControls={true} />
        <TableFilterRow />
        <TableRowDetail contentComponent={RowDetail} />
        <PagingPanel />
      </GridTable>
      <Popover
        id={`mouse-over-popover`}
        sx={{
          pointerEvents: 'none',
        }}
        open={popoverOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        //  disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>I use Popover.</Typography>
      </Popover>
    </Paper>
  );
};

export default LendingsResults;
