import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Button,
  Box,
  Paper,
  Tooltip,
  IconButton,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { get, includes, filter, flatten } from 'lodash';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArticleIcon from '@mui/icons-material/Article';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { getSample, getItemsInCase } from './_api';
import { addSampleToReservation } from './_actions';
import { getLendings } from 'modules/Lendings/_api';
import useLoader from 'utils/hooks/useLoader';
import useUserInfo from 'utils/hooks/useUserInfo';
import LendingsResults from 'modules/Lendings/LendingsResults';
import SamplesResults from './SamplesResults';
import ConfirmationDialog from 'components/ConfirmDialog/ConfirmDialog';

import useItemConvert from 'utils/hooks/useItemConvert';
import useRoleValidation from 'utils/hooks/useRoleValidation';
import queryString from 'query-string';

const SampleDetail: React.FC<any> = () => {
  const [sample, setSample] = useState<any>(null);
  const [lendings, setLendings] = useState<any>([]);
  const [samplesInCase, setSamplesInCase] = useState<any>([]);
  const [deleteDialog, setDeleteDialog] = useState<any>({
    open: false,
    snackBarText: { ok: '', error: '' },
  });
  const setLoader = useLoader();
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { checkRole, checkRoleLabel } = useRoleValidation();
  const { sampleConvert } = useItemConvert();
  const { userIsAdmin, userDepartments } = useUserInfo();
  const reservationItems = useSelector((state: any) => get(state, 'app.reservationItems', []));

  const { stockTakingId, locationId } = queryString.parse(window.location.search);

  const sampleId = parseInt(get(params, 'sampleId', ''), 10);

  const loadSample = async () => {
    setLoader();
    const [resp, resp2] = await Promise.all([
      getSample(sampleId),
      getLendings({ itemId: sampleId }),
    ]);
    if (resp) {
      setSample(sampleConvert(resp));
      if (get(resp, 'case', false)) {
        const itemsInCase = await getItemsInCase(get(resp, 'id'));
        if (itemsInCase) {
          setSamplesInCase(itemsInCase.map((item: any) => sampleConvert(item)));
        }
      }
    } else {
      setSample(false);
    }
    if (resp2) {
      setLendings(resp2);
    }
    setLoader(false);
  };

  useEffect(() => {
    loadSample();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sampleId]);

  const fields = [
    { name: 'reference', label: 'Reference' },
    { name: 'code', label: 'ID' },
    { name: 'description', label: 'Popis' },
    { name: 'internalNote', label: 'Interní poznámka' },
    {
      name: 'stateLabel',
      label: 'Stav',
      getValue: (row: any) => {
        const itemCase = get(row, 'itemCase');
        return (
          <span>
            {get(row, 'stateLabel')}{' '}
            <Link to={`/samples/${get(itemCase, 'id')}`}>{get(itemCase, 'reference')}</Link>
          </span>
        );
      },
    },
    { name: 'isCaseLabel', label: 'Je kufr' },
    { name: 'canLentLabel', label: 'Lze zapůjčit' },
    { name: 'department', label: 'Oddělení', getValue: (row: any) => get(row, 'department.name') },
    {
      name: 'location',
      label: 'Umístění',
      getValue: (row: any) => (get(row, 'user') ? '' : get(row, 'location.name')),
    },
    {
      name: 'createdDate',
      label: 'Vytvořeno',
      getValue: (row: any) =>
        moment(get(row, 'createdDate')).isValid()
          ? moment(get(row, 'createdDate')).format('DD.MM.YYYY HH:mm:ss')
          : '',
    },
    { name: 'discountGroup', label: 'Rabatová skupina' },
    { name: 'activity', label: 'Aktivita' },
    { name: 'commercializationStatus', label: 'Status komercializace' },
    {
      name: 'standardCaseContent',
      labelFnc: (row: any) => {
        const standardCaseContent = JSON.parse(get(sample, 'standardCaseContent') || '[]');

        //musí sedět počet položek v kufru a v standardCaseContent a musí sedět i jejich počet
        const isStandardCaseContentOk =
          standardCaseContent.length === samplesInCase.length &&
          JSON.parse(get(sample, 'standardCaseContent') || '[]')
            .map((item: any) => item.id)
            .every((el: any) => samplesInCase.map((item: any) => item.id).includes(el));
        if (isStandardCaseContentOk) {
          return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              Výchozí obsah kufru <CheckIcon style={{ marginLeft: 10 }} sx={{ color: 'green' }} />
            </div>
          );
        } else {
          return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              Výchozí obsah kufru <CloseIcon sx={{ color: 'red' }} />
            </div>
          );
        }
      },
      onlyCase: true,
      getValue: (row: any) => {
        try {
          const content = JSON.parse(get(row, 'standardCaseContent', []) || '[]');
          return flatten(
            content.map((item: any) => [`Reference: ${item.reference}, ID:${item.code}`, <br />]),
          );
        } catch (error) {
          return '';
        }
      },
    },
  ];

  const columns = [
    { name: 'serialNumber', title: 'ID' },
    { name: 'returned', title: 'Vráceno' },
    {
      name: 'loanedBy"',
      title: 'Zapůjčil',
      getCellValue: (row: any) =>
        `${get(row, 'loanedBy.lastName')} ${get(row, 'loanedBy.firstName')}`,
    },
    {
      name: 'dealer',
      title: 'Obchodník',
      getCellValue: (row: any) => `${get(row, 'dealer.lastName')} ${get(row, 'dealer.firstName')}`,
    },
    { name: 'company', title: 'Komu' },
    { name: 'contactPerson', title: 'Kontaktní osoba' },
    { name: 'description', title: 'Poznámka' },
    { name: 'createdDate', title: 'Zapůjčeno od' },
    { name: 'loanedTo', title: 'Zapůjčeno do' },
    {
      name: 'sampleCount',
      title: 'Počet vzorků',
      getCellValue: (row: any) => get(row, 'lentItems', []).length,
    },
    {
      name: 'actions',
      title: 'Akce',
      getCellValue: (row: any) => (
        <Box>
          <>
            <Tooltip title="Detail">
              <IconButton
                color="primary"
                sx={{ p: '5px' }}
                size="small"
                onClick={() => {
                  navigate(`/lendings/${row.id}`);
                }}
              >
                <ArticleIcon />
              </IconButton>
            </Tooltip>
            {/*<Tooltip title="Upravit">
              <IconButton
                color="primary"
                sx={{ p: '5px' }}
                onClick={() => {
                  navigate(`/lendings/${row.id}/edit`);
                }}
              >
                <EditIcon />
              </IconButton>
              </Tooltip> */}
          </>
        </Box>
      ),
    },
  ];

  const columnsItemsInCase = [
    { name: 'reference', title: 'Reference' },
    { name: 'code', title: 'ID' },
    { name: 'description', title: 'Popis' },
    { name: 'stateLabel', title: 'Stav' },
    { name: 'case_note', title: 'Kufr/Poznámka' },
    {
      name: 'location',
      title: 'Umístění',
      getCellValue: (row: any) => get(row, 'location.name', ''),
    },
    {
      name: 'department',
      title: 'Oddělení',
      getCellValue: (row: any) => get(row, 'department.name', ''),
    },
    { name: 'createdDate', title: 'Vytvořeno' },

    /*{
      name: 'actions',
      title: 'Akce',
      getCellValue: (row: any) => (
        <Tooltip title="Odstranit vzorek z kufru">
          <span>
            <IconButton
              color="primary"
              sx={{ p: '5px' }}
              size="small"
              onClick={() => {
                setDeleteDialog({
                  open: true,
                  id: row.id,
                  title: 'Odstranění vzorku z kufru',
                  text: `Opravdu chcete odstranit vzorek (${row.reference}, id: ${row.id}) z kufru?`,
                  snackBarText: {
                    ok: 'Smazání vzorku z kufru proběhlo úspěšně',
                    error: 'Smazání vzorku z kufru se nepodařilo',
                  },
                  deleteFnc: removeItemsFromCase,
                  values: { caseId: sample.id, itemIds: [row.id] },
                  loadEntities: loadSample,
                });
              }}
              disabled={sample.lent}
            >
              <DeleteIcon />
            </IconButton>
          </span>
        </Tooltip>
      ),
    },*/
  ];

  const canByDepartments =
    userIsAdmin ||
    includes(
      userDepartments.map((dep: any) => dep.id),
      get(sample, 'department.id'),
    );

  return (
    <div>
      <Typography variant="h6" gutterBottom component="div">
        Detailní informace o vzorku
      </Typography>
      <Box sx={{ mb: '10px' }}>
        <Button
          variant="contained"
          component={Link}
          to={
            stockTakingId && locationId
              ? `/stockTakings/${stockTakingId}?locationId=${locationId}`
              : `/samples?action=back`
          }
          startIcon={<ArrowBackIcon />}
          size="small"
          sx={{ ml: '10px' }}
        >
          Zpět
        </Button>
        <Tooltip title={`Upravit vzorek ${checkRoleLabel('ROLE_ITEM_UPDATE', canByDepartments)}`}>
          <span>
            <Button
              variant="contained"
              component={Link}
              to={`/samples/${sampleId}/edit`}
              startIcon={<EditIcon />}
              size="small"
              sx={{ ml: '10px' }}
              disabled={!(checkRole('ROLE_ITEM_UPDATE') && canByDepartments)}
            >
              Upravit
            </Button>
          </span>
        </Tooltip>
        <Tooltip
          title={`Vytvořit zápůjčku ${
            canByDepartments && !get(sample, 'lent') && get(sample, 'state') === 'AVAILABLE'
              ? ''
              : '(nemáte právo)'
          }`}
        >
          <span>
            <Button
              variant="contained"
              startIcon={<AddCircleIcon />}
              size="small"
              sx={{ ml: '10px' }}
              onClick={() => {
                dispatch(addSampleToReservation(sample));
                navigate(`/lendings/create?from=${sampleId}`);
              }}
              disabled={
                get(sample, 'state') !== 'AVAILABLE' ||
                get(sample, 'itemCase') ||
                sample.lent ||
                !canByDepartments ||
                !get(sample, 'canLent') ||
                includes(
                  reservationItems.map((item: any) => item.id),
                  get(sample, 'id'),
                )
              }
            >
              Vytvořit zápůjčku
            </Button>
          </span>
        </Tooltip>
      </Box>
      {sample ? (
        <Grid container={true} spacing={2}>
          <Grid item xs={12} sm={8} md={6} lg={6}>
            <TableContainer>
              <Table aria-label="simple table" size="small">
                <TableBody>
                  {filter(fields, (item: any) =>
                    get(item, 'onlyCase', false) && !get(sample, 'case') ? false : true,
                  ).map((field: any) => (
                    <TableRow key={field.name} sx={{ border: 0 }}>
                      <TableCell component="th" scope="row">
                        <b>
                          {typeof get(field, 'labelFnc', '') === 'function'
                            ? field.labelFnc(sample)
                            : field.label}
                        </b>
                      </TableCell>
                      <TableCell align="left">
                        {typeof get(field, 'getValue', '') === 'function'
                          ? field.getValue(sample)
                          : get(sample, field.name, '')}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {get(sample, 'case', false) && (
            <Grid item xs={12}>
              <Grid container={true} spacing={2}>
                <Grid item xs={6} sm={4} md={3} lg={2}>
                  <Typography variant="h6" gutterBottom component="div">
                    Obsah kufru
                  </Typography>
                </Grid>
              </Grid>

              <Paper>
                <SamplesResults columns={columnsItemsInCase} samples={samplesInCase} />
              </Paper>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom component="div">
              Historie zápůjček
            </Typography>
            <LendingsResults rows={lendings} columns={columns} />
          </Grid>
        </Grid>
      ) : null}
      {sample === false ? 'Nepodařilo se načíst vzorek' : null}
      <ConfirmationDialog deleteDialog={deleteDialog} setDeleteDialog={setDeleteDialog} />
    </div>
  );
};

export default SampleDetail;
