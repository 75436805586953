import { useState, useEffect, useMemo, useCallback } from 'react';
import { Button, Typography, Box, Tooltip, IconButton, Popover } from '@mui/material';
import { Link, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArticleIcon from '@mui/icons-material/Article';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { isArray, get } from 'lodash';

import useLoader from 'utils/hooks/useLoader';
import { getLendings } from './_api';
import { addLendings } from './_actions';
import LendingsResults from './LendingsResults';
import LendingsSearchForm from './LendingsSearchForm';
import useRoleValidation from 'utils/hooks/useRoleValidation';

const Lendings: React.FC<any> = () => {
  const setLoader = useLoader();
  const dispatch = useDispatch();
  const lendings = useSelector((state: any) => get(state, 'app.lendings', []));

  const { checkRole } = useRoleValidation();

  const [searchParams] = useSearchParams();
  const action = searchParams.get('action');

  const setLendings = (values: any) => {
    dispatch(addLendings(values));
  };

  const loadLendings = async (values: any) => {
    setLoader();
    const resp = await getLendings(values);
    if (resp) {
      if (isArray(resp)) {
        setLendings(
          resp.map((item: any) => ({
            ...item,
            returnedLabel: item.returned ? 'Vráceno' : 'Zapůjčeno',
            mailSentLabel: item.mailSent ? 'Ano' : 'Ne',
          })),
        );
      }
    }
    setLoader(false);
  };

  const onSearch = (values: any) => {
    loadLendings({
      returned: get(values, 'returned') ? (values.returned.id === 1 ? false : true) : undefined,
      createdDateStart: values.createdDateStart ? values.createdDateStart : undefined,
      createdDateEnd: values.createdDateEnd ? values.createdDateEnd : undefined,
    });
  };

  const columns = useMemo(
    () => [
      { name: 'serialNumber', title: 'Číslo zápůjčky' },
      { name: 'returnedLabel', title: 'Stav' },
      {
        name: 'loanedBy"',
        title: 'Zapůjčil',
        getCellValue: (row: any) =>
          `${get(row, 'loanedBy.lastName') || get(row, 'loanedBy.username')} ${
            get(row, 'loanedBy.firstName') || ''
          }`,
      },
      {
        name: 'department',
        title: 'Oddělení',
        getCellValue: (row: any) => get(row, 'department.name') || '',
      },
      {
        name: 'dealer',
        title: 'Obchodník',
        getCellValue: (row: any) =>
          `${get(row, 'dealer.lastName') || ''} ${get(row, 'dealer.firstName') || ''}`,
      },
      { name: 'company', title: 'Komu' },
      {
        name: 'contactPerson',
        title: 'Kontaktní osoba',
        getCellValue: (row: any) =>
          `${get(row, 'contactPersonLastName') || ''} ${get(row, 'contactPersonFirstName') || ''}`,
      },
      { name: 'description', title: 'Poznámka' },
      { name: 'createdDate', title: 'Zapůjčeno od' },
      { name: 'loanedTo', title: 'Zapůjčeno do' },
      { name: 'mailSentLabel', title: 'Odeslán email' },
      {
        name: 'sampleCount',
        title: 'Počet vzorků',
        getCellValue: (row: any) => (
          <div>
            {get(row, 'lentItems', []).length}
            {/*<IconButton aria-haspopup="true" size="small">
              <QuestionMarkIcon />
        </IconButton> */}
          </div>
        ),
      },
      {
        name: 'actions',
        title: 'Akce',
        getCellValue: (row: any) => (
          <Box>
            <>
              <Tooltip title="Detail zápůjčky">
                <IconButton
                  color="primary"
                  sx={{
                    p: '5px',
                    '&:hover': {
                      backgroundColor: 'white',
                    },
                  }}
                  size="small"
                  component={Link}
                  to={`/lendings/${row.id}`}
                  target="_blank"
                >
                  <ArticleIcon />
                </IconButton>
              </Tooltip>
              {/*<Tooltip title="Upravit">
              <IconButton
                color="primary"
                sx={{ p: '5px' }}
                onClick={() => {
                  navigate(`/lendings/${row.id}/edit`);
                }}
              >
                <EditIcon />
              </IconButton>
              </Tooltip> */}
            </>
          </Box>
        ),
      },
    ],
    [],
  );
  useEffect(() => {
    if (action !== 'back') {
      setLendings([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  return checkRole('ROLE_LOAN_VIEW') ? (
    <div>
      {console.log('lendings render')}
      <Typography variant="h6" gutterBottom component="div">
        Správa zápůjček
        <Tooltip title={`Přidat zápůjčku${checkRole('ROLE_LOAN_CREATE') ? '' : ' (nemáte právo)'}`}>
          <span>
            <Button
              variant="contained"
              component={Link}
              to={'/lendings/create'}
              startIcon={<AddCircleIcon />}
              size="small"
              sx={{ ml: '10px' }}
              disabled={!checkRole('ROLE_LOAN_CREATE')}
            >
              Nová
            </Button>
          </span>
        </Tooltip>
      </Typography>
      <Box sx={{ mb: '10px' }}>
        <LendingsSearchForm onSearch={onSearch} resultsCount={lendings.length} />
      </Box>
      {lendings !== false && <LendingsResults rows={lendings} columns={columns} />}
    </div>
  ) : (
    <div>Nemáte právo prohlížet zápůjčky</div>
  );
};

export default Lendings;
