import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { get, filter } from 'lodash';
import { useSnackbar } from 'notistack';

import SampleForm from './SampleForm';
import {
  addItemsToCase,
  getItemsInCase,
  getSample,
  updateSample,
  removeItemsFromCase,
} from './_api';
import {} from './_api';
import useLoader from 'utils/hooks/useLoader';
import SampleAddDialog from './SampleAddDialog';
import useRoleValidation from 'utils/hooks/useRoleValidation';

const SampleEdit: React.FC<any> = () => {
  const [sample, setSample] = useState<any>(null);
  const [caseItems, setCaseItems] = useState<any>([]);
  const [newCaseItems, setNewCaseItems] = useState<any>([]);
  const [removeCaseItems, setRemoveCaseItems] = useState<any>([]);
  const [openAddSampleDialog, setOpenAddSampleDialog] = useState<any>(false);
  const params = useParams();
  const { checkRole } = useRoleValidation();
  const setLoader = useLoader();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  console.log('removeCaseItems', removeCaseItems);

  const sampleId = parseInt(get(params, 'sampleId', ''), 10);

  const handleCloseDialog = () => {
    setOpenAddSampleDialog(false);
  };

  const loadSample = async () => {
    setLoader();
    const resp = await getSample(sampleId);
    if (resp) {
      setSample(resp);
      if (get(resp, 'case')) {
        const resp2 = await getItemsInCase(sampleId);
        setCaseItems(resp2);
      } else {
        setSample(resp);
      }
    } else {
      setSample(false);
    }
    setLoader(false);
  };

  const removeNewItem = (id: any) => {
    setNewCaseItems([...filter(newCaseItems, (item: any) => item.id !== id)]);
  };

  const removeOldItem = (id: any) => {
    setRemoveCaseItems((prev: any) => [...prev, id]);
  };

  const onSubmit = async (values: any) => {
    setLoader();

    if (values.case.id === 2 && removeCaseItems.length > 0) {
      const resp = await removeItemsFromCase({
        caseId: sample.id,
        itemIds: removeCaseItems.map((item: any) => item.id),
      });
      if (!get(resp, 'status')) {
        enqueueSnackbar(get(resp, 'errorText') || 'Nepodařilo se smazat vzorky z kufru', {
          variant: 'error',
        });
      } else {
        setRemoveCaseItems([]);
      }
    }

    if (values.case.id === 2 && newCaseItems.length > 0) {
      const resp = await addItemsToCase({
        caseId: sampleId,
        itemIds: newCaseItems.map((item: any) => item.id),
        updateStandardCaseContent: values.updateStandardCaseContent.id === 2 ? true : false,
      });
      if (!get(resp, 'status')) {
        enqueueSnackbar(get(resp, 'errorText') || 'Nepodařilo se uložit vzorky do kufru', {
          variant: 'error',
        });
      }
    }
    const resp = await updateSample(
      {
        ...values,
        id: sampleId,
        case: values.case.id === 2 ? true : false,
        isInCase: undefined,
        itemCase: get(values, 'itemCase.id'),
        locationId: get(values, 'location.id'),
        location: undefined,
        departmentId: get(values, 'department.id'),
        department: undefined,
        state: get(values, 'state.id'),
        canLent: values.canLent.id === 2 ? true : false,
        userId:
          values?.user && get(values, 'state.id') === 'INTERNAL_USAGE' ? values.user.id : undefined,
        description: get(values, 'description', '') || ' ',
        discountGroup: get(values, 'discountGroup', ''),
        activity: get(values, 'activity', ''),
        commercializationStatus: get(values, 'commercializationStatus', ''),
        updateStandardCaseContent: values.updateStandardCaseContent.id === 2 ? true : false,
      },
      values.case.id === 2 ? true : false, // updateCaseContent
    );
    if (get(resp, 'status')) {
      enqueueSnackbar('Vzorek byl úspěšně uložen', { variant: 'success' });
      navigate(`/samples/${sampleId}`);
    } else {
      enqueueSnackbar(get(resp, 'errorText') || 'Vzorek se nepodařilo uložit', {
        variant: 'error',
      });
    }
    setLoader(false);
  };

  useEffect(() => {
    loadSample();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return checkRole('ROLE_ITEM_UPDATE') ? (
    <div>
      <Typography variant="h6" gutterBottom component="div">
        Úprava vzorku
      </Typography>
      {sample ? (
        <>
          <SampleForm
            edit={true}
            sample={sample}
            loadSample={loadSample}
            caseItems={caseItems}
            newCaseItems={newCaseItems}
            setNewCaseItems={setNewCaseItems}
            onSubmit={onSubmit}
            setOpenAddSampleDialog={setOpenAddSampleDialog}
            removeNewItem={removeNewItem}
            removeOldItem={removeOldItem}
            removeCaseItems={removeCaseItems}
          />
          <SampleAddDialog
            caseItems={caseItems}
            newCaseItems={newCaseItems}
            setNewCaseItems={setNewCaseItems}
            open={openAddSampleDialog}
            handleClose={handleCloseDialog}
            editedCase={sample}
            removeNewItem={removeNewItem}
          />
        </>
      ) : null}
      {sample === false ? 'Nepodařilo se načíst vzorek' : null}
    </div>
  ) : (
    <div>Nemáte právo měnit vzorek</div>
  );
};

export default SampleEdit;
