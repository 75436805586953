import { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { get, filter, includes, compact } from 'lodash';

import AutocompleteForm from 'components/Form/Autocomplete/Autocomplete';
import { getLocations } from 'modules/Settings/Locations/_api';
import useLoader from 'utils/hooks/useLoader';
import { SAMPLE_STATES } from 'utils/constants';
import { returnItems } from './_api';
import { useSnackbar } from 'notistack';

const LendingReturnDialog: React.FC<any> = ({
  open,
  setOpen,
  sample,
  lendingId,
  setLending,
  setRefreshItemCase,
  lending,
}) => {
  const [locations, setLocations] = useState<any>([]);
  const methods = useForm();
  const { handleSubmit, reset, watch } = methods;
  const setLoader = useLoader();
  const { enqueueSnackbar } = useSnackbar();

  const location = watch('location');

  const handleClose = () => {
    setOpen(false);
  };

  const loadEntities = async () => {
    setLoader();
    const [resp1] = await Promise.all([getLocations()]);
    if (resp1) {
      setLocations(filter(resp1, { active: true }));
    }
    setLoader(false);
  };

  const fields = [
    { name: 'reference', label: 'Reference' },
    { name: 'code', label: 'ID' },
    { name: 'description', label: 'Popis' },
    { name: 'isCaseLabel', label: 'Vzorek je kufr' },
  ];

  const onSubmit = async (values: any) => {
    setLoader();
    const reqItems = {
      loanId: lendingId,
      items: sample.case
        ? filter(
            lending.lentItems,
            (item) => !item.case && get(item, 'itemCase.id') === sample.id,
          ).map((item: any) => ({
            itemId: item.id,
            state: get(values, 'state.id'),
            locationId: get(values, 'location.id'),
          }))
        : [
            {
              itemId: sample.id,
              state: get(values, 'state.id'),
              locationId: get(values, 'location.id'),
            },
          ],
    };
    const reqCases = {
      loanId: lendingId,
      items: filter(lending.lentItems, (item) => item.case && get(item, 'id') === sample.id).map(
        (item: any) => ({
          itemId: item.id,
          state: get(values, 'state.id'),
          locationId: get(values, 'location.id'),
        }),
      ),
    };

    const resp = await returnItems(reqItems);
    if (resp) {
      if (reqCases.items.length > 0) {
        const resp2 = await returnItems(reqCases);
        setLending(resp2);
      } else {
        setLending(resp);
      }
    }
    handleClose();
    setLoader(false);
    if (sample.itemCase) {
      setRefreshItemCase(sample.itemCase.id);
    }
  };

  useEffect(() => {
    reset({
      location: { id: get(sample, 'location.id'), label: get(sample, 'location.name') },
      state: { id: 'AVAILABLE', label: 'k dispozici' },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sample]);

  useEffect(() => {
    loadEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      get(sample, 'itemCase.location.id') &&
      get(location, 'id') &&
      get(sample, 'itemCase.location.id') !== get(location, 'id')
    ) {
      enqueueSnackbar('Umístění vzorku neodpovídá umístění kufru.', { variant: 'warning' });
    }
  }, [location, sample, enqueueSnackbar]);

  return (
    <>
      <Dialog fullWidth={true} maxWidth="md" open={open} onClose={handleClose}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>Vrácení vzorku</DialogTitle>
            <DialogContent>
              <Grid container={true} spacing={2}>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table aria-label="simple table" size="small">
                      <TableBody>
                        {fields.map((field: any) => (
                          <TableRow key={field.name} sx={{ border: 0 }}>
                            <TableCell component="th" scope="row">
                              <b>{field.label}</b>
                            </TableCell>
                            <TableCell align="left">
                              {typeof get(field, 'getValue', '') === 'function'
                                ? field.getValue(sample)
                                : get(sample, field.name, '')}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow sx={{ border: 0 }}>
                          <TableCell component="th" scope="row">
                            <b>Umístění</b>
                          </TableCell>
                          <TableCell align="left">
                            <AutocompleteForm
                              name="location"
                              label="Umístění"
                              options={locations.map((item: any) => ({
                                id: item.id,
                                label: item.name,
                              }))}
                              disableClearable={false}
                              rules={{ required: 'Vyžadováno' }}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ border: 0 }}>
                          <TableCell component="th" scope="row">
                            <b>Stav vzorku po vrácení</b>
                          </TableCell>
                          <TableCell align="left">
                            <AutocompleteForm
                              name="state"
                              label="Stav"
                              options={filter(
                                SAMPLE_STATES,
                                (item) =>
                                  !includes(
                                    compact([
                                      'INTERNAL_USAGE',
                                      'SOLD',
                                      'UNDEFINED',
                                      'LENT',
                                      'IN_CASE',
                                      get(sample, 'case', false) ? '' : 'INCOMPLETE',
                                    ]),
                                    item.id,
                                  ),
                              )}
                              disableClearable={false}
                              multiple={true}
                              rules={{ required: 'Vyžadováno' }}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button color="secondary" variant="contained" onClick={handleClose}>
                Zavřít
              </Button>
              <Button color="primary" variant="contained" type="submit">
                Potvrdit vrácení
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </>
  );
};

export default LendingReturnDialog;
