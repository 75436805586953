import { isArray, get } from 'lodash';

const sorter = new Intl.Collator('cs', { usage: 'sort' });

const useSort = () => {
  const csSort = (arr: any, sortAttr: string) => {
    if (isArray(arr)) {
      return arr.sort((a, b) => sorter.compare(get(a, sortAttr), get(b, sortAttr)));
    } else {
      return [];
    }
  };
  return csSort;
};

export default useSort;
