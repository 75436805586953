import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider, useSnackbar } from 'notistack';

import Loader from 'components/Loader/Loader';
import Main from 'modules/Main/Main';
import configureStore from 'utils/store/index';
import { theme } from './theme';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const store = configureStore();

function App() {
  const DismissAction = ({ id }: any) => {
    const { closeSnackbar } = useSnackbar();
    return (
      <IconButton aria-label="cancel" size="small" onClick={() => closeSnackbar(id)}>
        <CloseIcon sx={{ color: 'white' }} fontSize="inherit" />
      </IconButton>
    );
  };
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <BrowserRouter>
          <Loader />
          <SnackbarProvider maxSnack={3} action={(snackbarId) => <DismissAction id={snackbarId} />}>
            <Main />
          </SnackbarProvider>
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
