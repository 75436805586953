import { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
} from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { get, filter, includes } from 'lodash';
import { useSnackbar } from 'notistack';

import AutocompleteForm from 'components/Form/Autocomplete/Autocomplete';
import { getLocations } from 'modules/Settings/Locations/_api';
import useLoader from 'utils/hooks/useLoader';
import { SAMPLE_STATES } from 'utils/constants';
import SamplesResults from './SamplesResults';
import { getDepartments } from 'modules/Settings/Departments/_api';
import { multipleUpdate, addItemsToCase, getCases } from './_api';
import { useNavigate } from 'react-router-dom';

const MultipleEditDialog: React.FC<any> = ({
  open,
  setOpen,
  samples,
  setSamples,
  selection,
  setSelection,
  stocking,
  onSubmitCallback,
}) => {
  const [departments, setDepartments] = useState<any>([]);
  const [locations, setLocations] = useState<any>([]);
  const [cases, setCases] = useState<any>([]);
  const [samplesSelected, setSamplesSelected] = useState<any>([]);
  const navigate = useNavigate();
  const methods = useForm();
  const { handleSubmit, reset } = methods;
  const setLoader = useLoader();
  const { enqueueSnackbar } = useSnackbar();

  const columnsSample = [
    { name: 'code', title: 'ID' },
    { name: 'reference', title: 'Reference' },
    { name: 'description', title: 'Popis' },
    { name: 'stateLabel', title: 'Stav' },
    { name: 'case_note', title: 'Kufr/Poznámka' },
    {
      name: 'location',
      title: 'Umístění',
      getCellValue: (row: any) => get(row, 'location.name', ''),
    },
    {
      name: 'department',
      title: 'Oddělení',
      getCellValue: (row: any) => get(row, 'department.name', ''),
    },
    { name: 'createdDate', title: 'Vytvořeno' },
    {
      name: 'actions',
      title: 'Akce',
    },
  ];

  const handleClose = () => {
    setOpen(false);
  };

  const loadEntities = async () => {
    setLoader();
    const [resp1, resp2, resp3] = await Promise.all([getLocations(), getDepartments(), getCases()]);
    if (resp1) {
      setLocations(resp1);
    }
    if (resp2) {
      setDepartments(resp2);
    }
    if (resp3) {
      setCases(resp3);
    }
    setLoader(false);
  };

  const onSubmit = async (values: any) => {
    setLoader();
    let resp;
    if (get(values, 'itemCase')) {
      resp = await addItemsToCase({
        caseId: get(values, 'itemCase.id'),
        itemIds: samplesSelected.map((item: any) => item.id),
      });
    } else {
      resp = await multipleUpdate({
        state: values.state ? values.state.id : undefined,
        locationId: get(values, 'location.id') ? values.location.id : undefined,
        departmentId: get(values, 'department.id') ? values.department.id : undefined,
        itemIds: samplesSelected.map((item: any) => item.id),
        description: ' ',
      });
    }

    if (get(resp, 'status')) {
      setSamples([]);
      setSelection([]);
      enqueueSnackbar('Úpravy byly úspěšně provedeny', { variant: 'success' });
    } else {
      enqueueSnackbar(get(resp, 'errorText') || 'Úpravy se nepodařilo provést', {
        variant: 'error',
      });
    }

    setOpen(false);
    setLoader(false);
    if (get(values, 'itemCase')) {
      navigate(`/samples/${get(values, 'itemCase.id')}`);
    }
    if (onSubmitCallback) {
      onSubmitCallback();
    }
  };

  useEffect(() => {
    loadEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset({});
    setSamplesSelected([...filter(samples, (item) => includes(selection, item.id))]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <Dialog fullWidth={true} maxWidth="xl" open={open} onClose={handleClose}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>Hromadná editace</DialogTitle>
            <DialogContent>
              <Grid container={true} spacing={2}>
                <Grid item xs={12} md={3} lg={3}>
                  <AutocompleteForm
                    name="state"
                    label="Stav"
                    options={
                      stocking
                        ? [
                            { id: 'DISCARDED', label: 'Vyřazený' },
                            { id: 'SOLD', label: 'Prodán' },
                            // { id: 'UNDEFINED', label: 'Neznámý' },
                          ]
                        : filter(
                            SAMPLE_STATES,
                            (item) =>
                              !includes(
                                ['INTERNAL_USAGE', 'LENT', 'INCOMPLETE', 'IN_CASE'],
                                item.id,
                              ),
                          )
                    }
                    disableClearable={false}
                    multiple={true}
                  />
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <AutocompleteForm
                    name="department"
                    label="Oddělení"
                    options={departments.map((item: any) => ({ id: item.id, label: item.name }))}
                    disableClearable={false}
                  />
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <AutocompleteForm
                    name="location"
                    label="Umístění"
                    options={locations.map((item: any) => ({
                      id: item.id,
                      label: item.name,
                    }))}
                    disableClearable={false}
                  />
                </Grid>
                {!stocking && (
                  <Grid item xs={12} md={3} lg={3}>
                    <AutocompleteForm
                      name="itemCase"
                      label="Přidat vzorky do kufru"
                      options={cases.map((item: any) => ({
                        id: item.id,
                        label: `${item.reference} (id: ${item.code})`,
                      }))}
                      disableClearable={false}
                      disable={true}
                    />
                  </Grid>
                )}
              </Grid>
              <Paper sx={{ mt: '15px' }}>
                <SamplesResults columns={columnsSample} samples={samplesSelected} />
              </Paper>
            </DialogContent>
            <DialogActions>
              <Button color="secondary" variant="contained" onClick={handleClose}>
                Zavřít
              </Button>
              <Button color="primary" variant="contained" type="submit">
                Potvrdit změnu
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </>
  );
};

export default MultipleEditDialog;
