import { useEffect, useState } from 'react';
import {
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Box,
  Paper,
  Button,
  Tooltip,
} from '@mui/material';
import { useParams, Link } from 'react-router-dom';
import { get, filter, find, includes } from 'lodash';
import moment from 'moment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PrintIcon from '@mui/icons-material/Print';
import { useSnackbar } from 'notistack';

import { getLending, getPrintablePdf, sentEmailLending } from './_api';
import useLoader from 'utils/hooks/useLoader';
import useDownloadPdf from 'utils/hooks/useDownloadPdf';
import SamplesResults from 'modules/Samples/SamplesResults';
import useItemConvert from 'utils/hooks/useItemConvert';
import LendingReturnDialog from './LendingReturnDialog';
import ExtendDialog from './LendingExtendDialog';
import LendingReturnMoreDialog from './LendingReturnMoreDialog';
import LendingReturnAllDialog from './LendingReturnAllDialog';
import useRoleValidation from 'utils/hooks/useRoleValidation';
import useUserInfo from 'utils/hooks/useUserInfo';

const LendingDetail: React.FC<any> = () => {
  const [lending, setLending] = useState<any>([]);
  const [sampleDetailOpen, setSampleDetailOpen] = useState<any>(false);
  const [sampleMoreDetailOpen, setSampleMoreDetailOpen] = useState<any>(false);
  const [sampleAllDetailOpen, setSampleAllDetailOpen] = useState<any>(false);
  const [extendDialogOpen, setExtendDialogOpen] = useState<any>(false);
  const [sampleToReturn, setSampleToReturn] = useState<any>(false);
  const [refreshItemCase, setRefreshItemCase] = useState<any>(0);
  const [selectionInCase, setSelectionInCase] = useState<any>([]);
  const [selection, setSelection] = useState<any>([]);
  const params = useParams();
  const setLoader = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const { sampleConvert } = useItemConvert();
  const downloadPdf = useDownloadPdf();
  const { checkRole } = useRoleValidation();
  const { userDepartments, userIsAdmin } = useUserInfo();

  const lendingId = parseInt(get(params, 'lendingId', ''), 10);
  const samples = get(lending, 'lentItems', []).map((item: any) => ({
    ...sampleConvert(item),
    lendingId,
  }));

  const casesIds = filter(samples, (item) => item.case).map((item) => item.id);

  const loadLending = async () => {
    setLoader();
    const resp = await getLending(lendingId);
    if (resp) {
      setLending({
        ...resp,
      });
    } else {
      setLending(false);
    }
    setLoader(false);
  };

  const sendEmail = async () => {
    setLoader();
    const resp = await sentEmailLending(lendingId);
    if (resp) {
      enqueueSnackbar('Email byl úspěšně odeslán', { variant: 'success' });
      setLoader(false);
      loadLending();
    } else {
      enqueueSnackbar('Email se nepodařilo odeslat', { variant: 'error' });
      setLoader(false);
    }
  };

  const columnsSample = [
    { name: 'code', title: 'ID' },
    { name: 'reference', title: 'Reference' },
    { name: 'description', title: 'Popis' },
    { name: 'stateLabel', title: 'Stav' },
    { name: 'case_note', title: 'Kufr/Poznámka' },
    {
      name: 'location',
      title: 'Umístění',
      getCellValue: (row: any) => get(row, 'location.name', ''),
    },
    {
      name: 'department',
      title: 'Oddělení',
      getCellValue: (row: any) => get(row, 'department.name', ''),
    },
    { name: 'createdDate', title: 'Vytvořeno' },
    {
      name: 'returnedBy',
      title: 'Vrátil',
      getCellValue: (row: any) =>
        `${get(row, 'returnedBy.lastName') || get(row, 'returnedBy.username') || ''} ${
          get(row, 'returnedBy.firstName') || ''
        }`,
    },
    {
      name: 'actions',
      title: 'Akce',
      getCellValue: (row: any) =>
        row.lent && row.lentId === lendingId ? (
          <Button
            variant="contained"
            startIcon={<ArrowBackIcon />}
            size="small"
            onClick={() => {
              console.log(row);
              setSampleToReturn(sampleConvert(row));
              setSampleDetailOpen(true);
            }}
            disabled={
              !checkRole('ROLE_LOAN_RETURN') ||
              !(
                userIsAdmin ||
                includes(
                  userDepartments.map((dep: any) => dep.id),
                  get(row, 'department.id'),
                )
              )
            }
          >
            Vrátit
          </Button>
        ) : (
          'Vráceno'
        ),
    },
  ];

  useEffect(() => {
    loadLending();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fields = [
    { name: 'serialNumber', label: 'Číslo zápůjčky' },
    {
      name: 'createdDate',
      label: 'Zapůjčeno od',
      getValue: (row: any) =>
        moment(get(row, 'createdDate')).isValid()
          ? moment(get(row, 'createdDate')).format('DD.MM.YYYY')
          : '',
    },
    {
      name: 'createdDateEnd',
      label: 'Zapůjčeno do',
      getValue: (row: any) =>
        moment(get(row, 'loanedTo')).isValid()
          ? moment(get(row, 'loanedTo')).format('DD.MM.YYYY')
          : '',
    },
    { name: 'returned', label: 'Vráceno', getValue: (row: any) => (row.returned ? 'Ano' : 'Ne') },
    {
      name: 'mailSent',
      label: 'Email odeslán',
      getValue: (row: any) => (row.mailSent ? 'Ano' : 'Ne'),
    },
    {
      name: 'loanedBy',
      label: 'Zapůjčil',
      getValue: (row: any) =>
        `${get(row, 'loanedBy.lastName') || ''} ${get(row, 'loanedBy.firstName') || ''}`,
    },
    {
      name: 'dealer',
      label: 'Obchodník',
      getValue: (row: any) =>
        `${get(row, 'dealer.lastName') || ''} ${get(row, 'dealer.firstName') || ''}`,
    },
    { name: 'company', label: 'Zapůjčeno firmě' },
    { name: 'companyAddress', label: 'Adresa firmy' },
    {
      name: 'contactPerson',
      label: 'Kontaktní osoba',
      getValue: (row: any) =>
        `${row.contactPersonLastName || ''} ${row.contactPersonFirstName || ''}`,
    },
    { name: 'contactPhone', label: 'Telefon' },
    { name: 'contactMail', label: 'Email' },
    { name: 'description', label: 'Poznámka' },
  ];

  return (
    <div>
      <div>
        <Typography variant="h6" gutterBottom component="div">
          Detailní informace o zápůjčce
        </Typography>
        <div>
          <Button
            variant="contained"
            component={Link}
            to={`/lendings?action=back`}
            startIcon={<ArrowBackIcon />}
            size="small"
            sx={{ ml: '10px' }}
          >
            Zpět
          </Button>
          <Tooltip title={`Tisk zápůjčky${checkRole('ROLE_LOAN_PRINT') ? '' : ' (nemáte právo)'}`}>
            <span>
              <Button
                variant="contained"
                startIcon={<PrintIcon />}
                size="small"
                sx={{ ml: '10px' }}
                onClick={async () => {
                  // downloadPdf(getPrintablePdf, lending);
                  const data = await getPrintablePdf(lending.id);
                  /*const linkSource = `data:application/pdf;base64,${data}`;
                  window.open(linkSource, '_blank');*/
                  const byteCharacters = atob(data);
                  const byteNumbers = new Array(byteCharacters.length);
                  for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                  }
                  const byteArray = new Uint8Array(byteNumbers);
                  const blob = new Blob([byteArray], { type: 'application/pdf' });
                  var fileURL = URL.createObjectURL(blob);
                  window.open(fileURL, '_blank');
                }}
                disabled={!checkRole('ROLE_LOAN_PRINT')}
              >
                PDF
              </Button>
            </span>
          </Tooltip>
          <Tooltip
            title={`Odeslat email${checkRole('ROLE_LOAN_SEND_MAIL') ? '' : ' (nemáte právo)'}`}
          >
            <span>
              <Button
                variant="contained"
                size="small"
                sx={{ ml: '10px' }}
                onClick={() => {
                  sendEmail();
                }}
                disabled={get(lending, 'mailSent', false) || !checkRole('ROLE_LOAN_SEND_MAIL')}
              >
                Odeslat email
              </Button>
            </span>
          </Tooltip>
          <Tooltip
            title={`Prodloužit zápůjčku${checkRole('ROLE_LOAN_EXTEND') ? '' : ' (nemáte právo)'}`}
          >
            <span>
              <Button
                variant="contained"
                size="small"
                sx={{ ml: '10px' }}
                onClick={() => {
                  setExtendDialogOpen(true);
                }}
                disabled={get(lending, 'returned', false) || !checkRole('ROLE_LOAN_EXTEND')}
              >
                Prodloužit
              </Button>
            </span>
          </Tooltip>
        </div>
      </div>
      <Box sx={{ mb: '10px' }}>
        {/*<Button
          variant="contained"
          component={Link}
          to={`/lendings/${lendingId}/edit`}
          startIcon={<EditIcon />}
          size="small"
          sx={{ ml: '10px' }}
        >
          Upravit
        </Button>*/}
        {/*<Button
          variant="contained"
          startIcon={<KeyboardReturnIcon />}
          size="small"
          sx={{ ml: '10px' }}
          disabled={get(lending, 'returned', false)}
          onClick={async () => {
            const resp = await returnLending(get(lending, 'id'));
            if (resp) {
              loadLending();
              enqueueSnackbar('Zápůjčka byla vrácena', { variant: 'success' });
            }
          }}
        >
          Vrátit zápůjčku
        </Button>*/}
      </Box>
      {lending ? (
        <Grid container={true} spacing={2}>
          <Grid item xs={12} sm={8} md={6} lg={6}>
            <TableContainer>
              <Table aria-label="simple table" size="small">
                <TableBody>
                  {fields.map((field: any) => (
                    <TableRow key={field.name} sx={{ border: 0 }}>
                      <TableCell component="th" scope="row">
                        <b>{field.label}</b>
                      </TableCell>
                      <TableCell align="left">
                        {typeof get(field, 'getValue', '') === 'function'
                          ? field.getValue(lending)
                          : get(lending, field.name, '')}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container={true}
              spacing={2}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                <Tooltip
                  title={`Vrátit vybrané vzorky${
                    checkRole('ROLE_LOAN_RETURN') ? '' : ' (nemáte právo)'
                  }`}
                >
                  <span>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ ml: '10px' }}
                      onClick={() => {
                        setSampleMoreDetailOpen(true);
                      }}
                      disabled={
                        !(selection && selection.length > 0) ||
                        !checkRole('ROLE_LOAN_RETURN') ||
                        !(
                          userIsAdmin ||
                          includes(
                            userDepartments.map((dep: any) => dep.id),
                            get(lending, 'department.id'),
                          )
                        )
                      }
                    >
                      Vrátit vybrané
                    </Button>
                  </span>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip
                  title={`Vrátit celou zápůjčku${
                    checkRole('ROLE_LOAN_RETURN') ? '' : ' (nemáte právo)'
                  }`}
                >
                  <span>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ ml: '10px' }}
                      onClick={() => {
                        setSampleAllDetailOpen(true);
                      }}
                      disabled={
                        lending?.returned ||
                        !checkRole('ROLE_LOAN_RETURN') ||
                        !(
                          userIsAdmin ||
                          includes(
                            userDepartments.map((dep: any) => dep.id),
                            get(lending, 'department.id'),
                          )
                        )
                      }
                    >
                      Vrátit vše
                    </Button>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <b>Seznam vzorků v zápůjčce</b>
              <Paper>
                <SamplesResults
                  columns={columnsSample}
                  samples={filter(samples, (item) => !includes(casesIds, get(item, 'itemCase.id')))}
                  detailColumns={columnsSample}
                  refreshItemCase={refreshItemCase}
                  canSelect={true}
                  selection={selection}
                  setSelection={setSelection}
                  isLendingDetail={true}
                  /*selectionInCase={selectionInCase}
                  setSelectionInCase={setSelectionInCase}
                  canSelectInCase={true}
                  selectionSubmit={}*/
                />
              </Paper>
            </Box>
          </Grid>
        </Grid>
      ) : null}
      {lending === false ? 'Nepodařilo se načíst zápůjčku' : null}
      {sampleDetailOpen && (
        <LendingReturnDialog
          open={sampleDetailOpen}
          setOpen={setSampleDetailOpen}
          sample={sampleToReturn}
          lendingId={lendingId}
          setLending={setLending}
          setRefreshItemCase={setRefreshItemCase}
          lending={lending}
        />
      )}

      <LendingReturnMoreDialog
        open={sampleMoreDetailOpen}
        setOpen={setSampleMoreDetailOpen}
        samples={selection}
        setSelection={setSelection}
        lendingId={lendingId}
        setLending={setLending}
        setRefreshItemCase={setRefreshItemCase}
        lending={lending}
      />

      <LendingReturnAllDialog
        open={sampleAllDetailOpen}
        setOpen={setSampleAllDetailOpen}
        lending={lending}
        lendingId={lendingId}
        setLending={setLending}
        setRefreshItemCase={setRefreshItemCase}
      />

      <ExtendDialog
        open={extendDialogOpen}
        setOpen={setExtendDialogOpen}
        lending={lending}
        loadLending={loadLending}
      />
    </div>
  );
};

export default LendingDetail;
