import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { get, isArray, pickBy } from 'lodash';
import { useSnackbar } from 'notistack';

import UserForm from './UserForm';
import {
  getUser,
  getUserWorkplaces,
  updateUser,
  manageUserDepartments,
  getUserDepartments,
} from './_api';
import useLoader from 'utils/hooks/useLoader';
import { getWorkplaces, manageUsers } from '../Workplaces/_api';
import useRoleValidation from 'utils/hooks/useRoleValidation';

const UserEdit: React.FC<any> = () => {
  const [user, setUser] = useState<any>(null);
  const [workplaces, setWorkplaces] = useState<any>(null);
  const params = useParams();
  const setLoader = useLoader();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { checkRole } = useRoleValidation();

  const loadUser = async () => {
    setLoader();
    const [resp, resp3] = await Promise.all([getUser(get(params, 'userId')), getWorkplaces()]);
    if (resp3) {
      setWorkplaces(resp3);
    }
    if (resp) {
      const resp2 = await getUserWorkplaces(resp?.username);
      const resp4 = await getUserDepartments(resp?.username);
      setUser({
        ...resp,
        workplaces: resp2.map((item: any) => ({
          label: item.name,
          id: item.id,
        })),
        departments: resp4.map((item: any) => ({ id: item.id, label: item.name })),
      });
    } else {
      setUser(false);
    }
    setLoader(false);
  };

  const onSubmit = async (values: any) => {
    setLoader();
    const resp = await updateUser({ ...pickBy(values), id: get(params, 'userId') });
    if (get(resp, 'status')) {
      const resp2 = await manageUsers({
        users: [{ userId: get(params, 'userId'), workPlaceIds: [get(values, 'workplace.id')] }],
      });

      const departments = isArray(get(values, 'departments'))
        ? get(values, 'departments')
        : get(values, 'departments')
        ? [get(values, 'departments')]
        : [];

      const resp3 = await manageUserDepartments({
        userId: get(params, 'userId'),
        departmentIds: (departments || []).map((item: any) => item.id),
      });
      if (resp2 && resp3) {
        enqueueSnackbar('Uživatel byl úspěšně uložen', { variant: 'success' });
        navigate(`/settings?active=users`);
      } else {
        enqueueSnackbar('Uživatele se nepodařilo uložit', { variant: 'error' });
      }
    } else {
      enqueueSnackbar(get(resp, 'errorText') || 'Uživatele se nepodařilo uložit', {
        variant: 'error',
      });
    }
    setLoader(false);
  };

  useEffect(() => {
    loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return checkRole('ROLE_USER_UPDATE') ? (
    <div>
      <Typography variant="h6" gutterBottom component="div">
        Úprava uživatele
      </Typography>
      {user ? (
        <UserForm edit={true} user={user} workplaces={workplaces} onSubmit={onSubmit} />
      ) : null}
      {user === false ? 'Nepodařilo se načíst uživatele' : null}
    </div>
  ) : (
    <div>Nemáte právo na úpravu uživatele</div>
  );
};

export default UserEdit;
