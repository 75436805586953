import React from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { Fade, CircularProgress, Box } from '@mui/material';

const Loader = () => {
  const isLoading = useSelector((state) => get(state, 'app.isLoading'));
  return (
    <Fade in={isLoading}>
      <Box
        sx={{
          left: 0,
          top: 0,
          background: 'rgba(255, 255, 255, .6)',
          zIndex: 2000,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100vw',
          height: '100vh',
          position: 'fixed',
          flexDirection: 'column',
        }}
      >
        <CircularProgress color="primary" size={100} />
      </Box>
    </Fade>
  );
};

export default React.memo(Loader);
