import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { get } from 'lodash';
import { useSnackbar } from 'notistack';

import CustomerForm from './CustomerForm';
import { getCustomerCompany, updateCustomerCompany } from './_api';
import useLoader from 'utils/hooks/useLoader';

const CustomerEdit: React.FC<any> = () => {
  const [customer, setCustomer] = useState<any>(null);
  const params = useParams();
  const setLoader = useLoader();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const loadCustomer = async () => {
    setLoader();
    const resp = await getCustomerCompany(get(params, 'customerId'));
    if (resp) {
      setCustomer(resp);
    } else {
      setCustomer(false);
    }
    setLoader(false);
  };

  const onSubmit = async (values: any) => {
    setLoader();
    const resp = await updateCustomerCompany({ ...values, id: get(params, 'customerId') });
    if (get(resp, 'status')) {
      enqueueSnackbar('Zákazník byl úspěšně uložen', { variant: 'success' });
      navigate(`/settings?active=customers`);
    } else {
      enqueueSnackbar(get(resp, 'errorText') || 'Zákazníka se nepodařilo uložit', {
        variant: 'error',
      });
    }
    setLoader(false);
  };

  useEffect(() => {
    loadCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Typography variant="h6" gutterBottom component="div">
        Úprava zákazníka
      </Typography>
      {customer ? <CustomerForm edit={true} customer={customer} onSubmit={onSubmit} /> : null}
      {customer === false ? 'Nepodařil se načíst zákazník' : null}
    </div>
  );
};

export default CustomerEdit;
