import { Grid, Checkbox, FormControlLabel } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { sortBy, get } from 'lodash';

const SelectForm = ({ name, items, disabled = false }: any) => {
  const {
    control,
    // formState: { errors },
  } = useFormContext();

  /*let isError = false;
  let errorMessage = '';
  if (!isEmpty(errors) && get(errors, name) !== undefined) {
    isError = true;
    errorMessage = get(errors, `${name}.message`);
  }*/

  return (
    <Grid container={true} spacing={0}>
      {sortBy(items, ['id']).map((item: any) => (
        <Grid key={item.id} item xs={12}>
          <Controller
            name={`${name}.${item.id}`}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <FormControlLabel
                label={item.name}
                control={
                  <Checkbox
                    onBlur={onBlur}
                    onChange={onChange} // send value to hook form
                    checked={value ? true : false}
                    inputRef={ref}
                    color="primary"
                    disabled={disabled ? true : false}
                  />
                }
              />
            )}
            control={control}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default SelectForm;
