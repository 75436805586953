import { get } from 'lodash';
import { api } from 'utils/hooks/useApi';
import { processError } from 'utils/utils';

export const getLendings = (values: any): Promise<any> =>
  api()
    .post(`/pae-api/loan/find`, values)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });

export const getLending = (id: number): Promise<any> =>
  api()
    .get(`/pae-api/loan/get?id=${id}`)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });

export const createLending = (values: any): Promise<any> =>
  api()
    .post(`/pae-api/loan/create`, values)
    .then((response: any) => ({ status: true, data: get(response, 'data') }))
    .catch((error) => {
      return processError(error);
    });

export const editLending = (values: any): Promise<any> =>
  api()
    .post(`/pae-api/loan/edit`, values)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });

export const returnItems = (values: any): Promise<any> =>
  api()
    .post(`/pae-api/loan/returnItems`, values)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });

export const getPrintablePdf = (id: number): Promise<any> =>
  api()
    .get(`/pae-api/loan/getPrintablePdf?id=${id}`)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });

export const extendLending = (values: any): Promise<any> =>
  api()
    .post(`/pae-api/loan/extend`, values)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });

export const sentEmailLending = (id: number): Promise<any> =>
  api()
    .get(`/pae-api/loan/sendMail?id=${id}`)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });
