import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { get, forEach, keys } from 'lodash';
import { useSnackbar } from 'notistack';

import WorkplaceForm from './WorkplaceForm';
import { getWorkplace, manageRoles, updateWorkplace } from './_api';

import useLoader from 'utils/hooks/useLoader';

const WorkplaceEdit: React.FC<any> = () => {
  const [workplace, setWorkplace] = useState<any>(null);
  const params = useParams();
  const setLoader = useLoader();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const workplaceId = parseInt(get(params, 'workplaceId', ''), 10);

  const loadWorkplace = async () => {
    setLoader();
    const resp = await getWorkplace(workplaceId);
    if (resp) {
      setWorkplace(resp);
    } else {
      setWorkplace(false);
    }
    setLoader(false);
  };

  const onSubmit = async (values: any) => {
    console.log(values);
    setLoader();
    let ids: any = [];
    forEach(keys(values.roles), (item: any) => {
      if (values.roles[item]) {
        ids = [...ids, parseInt(item, 10)];
      }
    });
    const resp = await updateWorkplace({ ...values, id: workplaceId, roles: undefined });
    if (resp) {
      const resp2 = await manageRoles({ workPlaceId: resp.id, roleIds: ids });
      if (resp2) {
        enqueueSnackbar('Pracovní místo bylo úspěšně změněno', { variant: 'success' });
        navigate(`/settings?active=workplaces`);
      } else {
        enqueueSnackbar('Pracovní místo se padařilo změnit, ale nepodařily se změnit role', {
          variant: 'error',
        });
      }
      navigate(`/settings?active=workplaces`);
    } else {
      enqueueSnackbar('Pracovní místo se nepovedlo změnit', { variant: 'error' });
    }
    setLoader(false);
  };

  useEffect(() => {
    loadWorkplace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Typography variant="h6" gutterBottom component="div">
        Úprava pracovního místa
      </Typography>
      {workplace ? <WorkplaceForm edit={true} workplace={workplace} onSubmit={onSubmit} /> : null}
      {workplace === false ? 'Nepodařilo se načíst pracovní místo' : null}
    </div>
  );
};

export default WorkplaceEdit;
