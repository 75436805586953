import { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { get, uniq } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import LendingForm from './LendingForm';
import { editLending, getLending } from './_api';
import useLoader from 'utils/hooks/useLoader';
import SampleAddDialog from './LendingsSampleAddDialog';
import { setSamplesInReservation } from 'modules/Samples/_actions';
import useItemConvert from 'utils/hooks/useItemConvert';

const LendingEdit: React.FC<any> = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const setLoader = useLoader();
  const params = useParams();
  const dispatch = useDispatch();
  const { sampleConvert } = useItemConvert();

  const [lending, setLending] = useState<any>(null);
  const [openDialog, setOpenDialog] = useState<any>(false);
  const reservationItems = useSelector((state: any) => get(state, 'app.reservationItems', []));
  const lendingId = parseInt(get(params, 'lendingId', ''), 10);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const loadLending = async () => {
    setLoader();
    const resp = await getLending(lendingId);
    if (resp) {
      setLending(resp);
      dispatch(
        setSamplesInReservation(get(resp, 'lentItems', []).map((item: any) => sampleConvert(item))),
      );
    } else {
      setLending(false);
    }
    setLoader(false);
  };

  const onSubmit = async (values: any) => {
    setLoader();
    const resp = await editLending({
      createdDate: values.createdDate,
      daysForLoan: get(values, 'daysForLoan.id') ? get(values, 'daysForLoan.id') : undefined,
      createdById: get(values, 'createdBy.id') ? get(values, 'createdBy.id') : undefined,
      dealerId: get(values, 'dealer.id') ? get(values, 'dealer.id') : undefined,
      company: get(values, 'company') ? get(values, 'company') : undefined,
      contactPersonFirstName: get(values, 'contactPerson')
        ? get(values, 'contactPerson.firstName')
        : undefined,
      contactPersonLastName: get(values, 'contactPerson')
        ? get(values, 'contactPerson.lastName')
        : undefined,
      companyAddress: get(values, 'companyAddress') ? get(values, 'companyAddress') : undefined,
      contactPhone: get(values, 'contactPhone') ? get(values, 'contactPhone') : undefined,
      contactMail: get(values, 'contactMail') ? get(values, 'contactMail') : undefined,
      description: get(values, 'description') ? get(values, 'description') : undefined,
      price: values.price,
      itemIds: uniq(reservationItems.map((item: any) => item.id)),
      protocolCompleted: true,
    });
    if (resp) {
      enqueueSnackbar('Zápůjčka byla úspěšně upraveno', { variant: 'success' });
      navigate(`/lendings/${lendingId}`);
    } else {
      enqueueSnackbar('Zápůjčku se nepovedlo upravit', { variant: 'error' });
    }
    setLoader(false);
  };

  useEffect(() => {
    loadLending();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Typography variant="h6" gutterBottom component="div">
        Úprava zápůjčky
      </Typography>
      <LendingForm onSubmit={onSubmit} setOpen={setOpenDialog} edit={true} lending={lending} />
      <SampleAddDialog open={openDialog} handleClose={handleCloseDialog} />
    </div>
  );
};

export default LendingEdit;
