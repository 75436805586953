import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import 'moment/locale/cs';

import { DatePicker } from '@mui/lab';
import { TextField } from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterMoment from '@mui/lab/AdapterMoment';

moment.locale('cs');

const FormDatePicker = (props: any) => {
  const { control } = useFormContext();
  const { name, label, defaultValue = null, required, clearable = false } = props;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      {...props}
      render={({ field: { ref, ...rest }, formState: { errors } }) => {
        let isError = false;
        let errorMessage = '';
        if (!isEmpty(errors) && get(errors, name) !== undefined) {
          isError = true;
          errorMessage = get(errors, `${name}.message`);
        }
        return (
          <LocalizationProvider dateAdapter={AdapterMoment} locale={'cs'}>
            <DatePicker
              label={label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  margin="dense"
                  fullWidth={true}
                  error={isError}
                  helperText={errorMessage}
                  InputLabelProps={{
                    className: required ? 'required-label' : '',
                    required: required || false,
                  }}
                />
              )}
              mask={'__.__.____'}
              clearable={clearable}
              {...rest}
            />
          </LocalizationProvider>
        );
      }}
    />
  );
};

export default FormDatePicker;
