import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Dialog, Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { get } from 'lodash';

import useLoader from 'utils/hooks/useLoader';
import { multipleUpdate } from 'modules/Samples/_api';

const ConfirmNewLocationDialog: React.FC<any> = ({
  dialog: { open = false, sample },
  setDialog,
  newLocation,
  confirmItems,
  stockTaking,
  loggedUser,
}) => {
  const setLoader = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const handleClose = () => {
    setDialog({ open: false });
  };

  const handleSubmit = async () => {
    setLoader();
    await confirmItems([get(sample, 'id')]);
    handleClose();
    setLoader(false);
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '90%', maxHeight: 500 } }}
      maxWidth="xs"
      open={open}
    >
      <DialogTitle>Potvrzení změn u vzorku</DialogTitle>
      <DialogContent dividers>
        <Box>
          Reference: {sample?.reference}, ID: {sample?.code}
        </Box>
        <Box>
          <br />
          Potvrzením dojde k následujícím akcím:
          <br />
          {get(sample, 'location.id', 1) !== get(newLocation, 'id', 2) &&
          !(
            get(sample, 'state', 1) === 'INTERNAL_USAGE' &&
            get(newLocation, 'id', 2) === 'internalUsage'
          )
            ? `- změna umístění vzorku z ${get(sample, 'location.name')} na ${get(
                newLocation,
                'label',
              )}`
            : ''}
          <br />
          {get(sample, 'state') !== 'AVAILABLE' || get(newLocation, 'id') === 'internalUsage'
            ? `- změna stavu vzorku z ${get(sample, 'stateLabel')} na ${
                get(newLocation, 'id') === 'internalUsage'
                  ? `Interní použití - ${get(loggedUser, 'lastName')}  `
                  : 'K dispozici'
              }`
            : ''}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          variant="contained"
          autoFocus
          onClick={() => {
            handleClose();
          }}
        >
          {'Zrušit'}
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          {'Potvrdit'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmNewLocationDialog;
