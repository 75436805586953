import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Dialog, Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { get } from 'lodash';

import useLoader from 'utils/hooks/useLoader';
import { multipleUpdate } from 'modules/Samples/_api';

const ConfirmNewLocationDialog: React.FC<any> = ({
  dialog: { open = false, warnings },
  setDialog,
  submitMultipleItems,
}) => {
  const setLoader = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const handleClose = () => {
    setDialog({ open: false });
  };

  const handleSubmit = async () => {
    submitMultipleItems();
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="md"
      open={open}
    >
      <DialogTitle>Potvrzení změn u vzorků</DialogTitle>
      <DialogContent dividers>
        <Box>
          Potvrzením dojde k následujícím akcím:
          <br />
          {(warnings || []).map((warning: any, i: number) => (
            <div key={i}>{`- ${warning} `}</div>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          variant="contained"
          autoFocus
          onClick={() => {
            handleClose();
          }}
        >
          {'Zrušit'}
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          {'Potvrdit'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmNewLocationDialog;
