import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { get } from 'lodash';
import { useSnackbar } from 'notistack';

import PersonForm from './PersonForm';
import { getCustomerPerson, updateCustomerPerson } from './_api';
import useLoader from 'utils/hooks/useLoader';

const PersonEdit: React.FC<any> = () => {
  const [person, setPerson] = useState<any>(null);
  const params = useParams();
  const setLoader = useLoader();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const loadPerson = async () => {
    setLoader();
    const resp = await getCustomerPerson(get(params, 'personId'));
    if (resp) {
      setPerson(resp);
    } else {
      setPerson(false);
    }
    setLoader(false);
  };

  const onSubmit = async (values: any) => {
    setLoader();
    const resp = await updateCustomerPerson({
      ...values,
      id: get(params, 'personId'),
      companyId: get(params, 'customerId'),
    });
    if (get(resp, 'status')) {
      enqueueSnackbar('Kontakní osoba byla úspěšně uložen', { variant: 'success' });
      navigate(`/settings?active=customers`);
    } else {
      enqueueSnackbar(get(resp, 'errorText') || 'Kontaktní osobu se nepodařilo uložit', {
        variant: 'error',
      });
    }
    setLoader(false);
  };

  useEffect(() => {
    loadPerson();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Typography variant="h6" gutterBottom component="div">
        Úprava zákazníka
      </Typography>
      {person ? <PersonForm edit={true} person={person} onSubmit={onSubmit} /> : null}
      {person === false ? 'Nepodařil se načíst zákazník' : null}
    </div>
  );
};

export default PersonEdit;
