import { Container, Box, CssBaseline, Button, Typography } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { get, isObject, find } from 'lodash';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import Input from 'components/Form/Input/Input';
import { useStorage } from 'utils/hooks/useStorage';
import useLoader from 'utils/hooks/useLoader';
import { getHierarchy, login } from './_api';
import { storeUser } from './_actions';
import { storeGridSettings } from 'modules/Main/_actions';
import { getUserGridSettings, getUserRoles } from 'modules/Main/_api';
import { getUserDepartments } from 'modules/Settings/Users/_api';

const Login: React.FC<any> = () => {
  const dispatch = useDispatch();
  const setLoader = useLoader();
  const { putItem } = useStorage();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const methods = useForm();
  const { handleSubmit } = methods;

  const onSubmit = async (values: any) => {
    setLoader();
    const resp = await login(values);
    if (resp && isObject(resp)) {
      putItem('jwtToken', get(resp, 'jwtToken'));
      const [resp2, resp3, resp4, resp5] = await Promise.all([
        getUserGridSettings(),
        getUserRoles(),
        getUserDepartments(get(resp, 'username')),
        getHierarchy(),
      ]);
      dispatch(
        storeUser({
          ...resp,
          jwtToken: undefined,
          roles: resp3,
          departments: resp4,
          allowedWorkplaces: get(
            find(resp5, (item) => get(item, 'workPlace.id') === get(resp, 'workplaces[0].id')),
            'allowedWorkPlace',
          ),
        }),
      );
      if (resp2) {
        dispatch(storeGridSettings(resp2));
      }
      navigate(`/samples`);
    } else {
      enqueueSnackbar('Při přihlášení došlo k chybě', { variant: 'error' });
    }
    setLoader(false);
  };
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h3" gutterBottom component="div">
          Databáze vzorků
        </Typography>
        <FormProvider {...methods}>
          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
            <Input
              name="username"
              label="Přihlašovací jméno"
              rules={{ required: 'Vyžadováno' }}
              autoComplete="username"
              autoFocus={true}
            />
            <Input
              name="password"
              label="Heslo"
              rules={{ required: 'Vyžadováno' }}
              autoComplete="current-password"
              type="password"
            />

            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Přihlásit
            </Button>
          </Box>
        </FormProvider>
      </Box>
    </Container>
  );
};

export default Login;
