import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import moment from 'moment';

import DateForm from 'components/Form/Date/Date';
import useLoader from 'utils/hooks/useLoader';
import { extendLending } from './_api';

const ExtendDialog: React.FC<any> = ({ open, setOpen, lending, loadLending }) => {
  const methods = useForm();
  const { handleSubmit } = methods;
  const setLoader = useLoader();
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (values: any) => {
    setLoader();
    const resp = await extendLending({ loanId: lending.id, loanedTo: values.loanedTo });
    if (resp) {
      setOpen(false);
      enqueueSnackbar('Zápůjčku byla úspěšně prodloužena', { variant: 'success' });
      setLoader(false);
      loadLending();
    } else {
      enqueueSnackbar('Zápůjčku se nepodařilo prodloužit', { variant: 'error' });
      setLoader(false);
    }
  };

  return (
    <>
      <Dialog fullWidth={true} maxWidth="sm" open={open} onClose={handleClose}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>Prodloužení zápůjčky</DialogTitle>
            <DialogContent>
              <Grid container={true} spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                  <DateForm
                    name="loanedTo"
                    label="Prodloužit do"
                    rules={{
                      required: 'Vyžadováno',
                      validate: (value: any) => {
                        return moment(lending.loanedTo) < value
                          ? true
                          : 'Datum musí být později než je součásné datum vrácení zápůjčky ';
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button color="secondary" variant="contained" onClick={handleClose}>
                Zavřít
              </Button>
              <Button color="primary" variant="contained" type="submit">
                Prodloužit
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </>
  );
};

export default ExtendDialog;
