import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { get } from 'lodash';
import { useSnackbar } from 'notistack';

import DepartmentForm from './DepartmentForm';
import { getDepartment, updateDepartment } from './_api';

import useLoader from 'utils/hooks/useLoader';

const DepartmentEdit: React.FC<any> = () => {
  const [department, setDepartment] = useState<any>(null);
  const params = useParams();
  const setLoader = useLoader();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const departmentId = parseInt(get(params, 'departmentId', ''), 10);

  const loadDepartment = async () => {
    setLoader();
    const resp = await getDepartment(departmentId);
    if (resp) {
      setDepartment(resp);
    } else {
      setDepartment(false);
    }
    setLoader(false);
  };

  const onSubmit = async (values: any) => {
    setLoader();

    const resp = await updateDepartment({
      ...values,
      id: departmentId,
    });
    if (get(resp, 'status')) {
      enqueueSnackbar('Oddělení bylo úspěšně uloženo', { variant: 'success' });
      navigate(`/settings?active=departments`);
    } else {
      enqueueSnackbar(get(resp, 'errorText') || 'Oddělení se nepodařilo uložit', {
        variant: 'error',
      });
    }
    setLoader(false);
  };

  useEffect(() => {
    loadDepartment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Typography variant="h6" gutterBottom component="div">
        Úprava oddělení
      </Typography>
      {department ? (
        <DepartmentForm edit={true} department={department} onSubmit={onSubmit} />
      ) : null}
      {department === false ? 'Nepodařilo se načíst oddělení' : null}
    </div>
  );
};

export default DepartmentEdit;
