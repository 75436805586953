import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { useSnackbar } from 'notistack';
import { get } from 'lodash';

import useLoader from 'utils/hooks/useLoader';

const ConfirmationDialog: React.FC<any> = ({
  deleteDialog: {
    open = false,
    id = null,
    title,
    text,
    snackBarText: { ok = false, error = false },
    apiFnc = true,
    deleteFnc,
    loadEntities,
    values = null,
    onClickSubmit,
    submitButtonTitle,
    closeButtonTitle,
    onClose,
  },
  setDeleteDialog,
}) => {
  const setLoader = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const handleClose = () => {
    setDeleteDialog({ open: false, snackBarText: { ok: '', error: '' } });
  };

  const handleSubmit = async () => {
    setLoader();
    if (deleteFnc) {
      if (apiFnc) {
        const resp = await deleteFnc(values || id);
        if (get(resp, 'status')) {
          if (ok) {
            enqueueSnackbar(ok, {
              variant: 'success',
            });
          }
        } else {
          if (error) {
            enqueueSnackbar(get(resp, 'errorText') || error, {
              variant: 'error',
            });
          }
        }
      } else {
        deleteFnc(values || id);
        enqueueSnackbar(ok, {
          variant: 'success',
        });
      }
    }
    if (onClickSubmit) {
      onClickSubmit();
    }
    if (loadEntities) {
      loadEntities();
    }

    setDeleteDialog({ open: false, snackBarText: { ok: '', error: '' } });
    setLoader(false);
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>{text}</DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          variant="contained"
          autoFocus
          onClick={() => {
            if (onClose) {
              onClose();
            } else {
              handleClose();
            }
          }}
        >
          {closeButtonTitle || 'Zrušit'}
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          {submitButtonTitle || 'Potvrdit'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
