import { get } from 'lodash';
import { useSnackbar } from 'notistack';
import useLoader from 'utils/hooks/useLoader';

const useDownloadPdf = () => {
  const { enqueueSnackbar } = useSnackbar();
  const setLoader = useLoader();
  const downloadPdf = async (apiFnc: any, lending: any) => {
    setLoader();
    const data = await apiFnc(lending.id);
    if (data) {
      const linkSource = `data:application/pdf;base64,${data}`;
      var a = document.createElement('a');
      a.href = linkSource;
      a.download = `zapujcka-${get(lending, 'serialNumber')}_${get(lending, 'company')}.pdf`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } else {
      enqueueSnackbar('Pdf dokument se nepodařilo vygenerovat.', {
        variant: 'error',
      });
    }
    setLoader(false);
  };
  return downloadPdf;
};

export default useDownloadPdf;
