import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import SampleForm from './SampleForm';
import { createSample } from './_api';
import useLoader from 'utils/hooks/useLoader';
import { get } from 'react-hook-form';
import useRoleValidation from 'utils/hooks/useRoleValidation';

const SampleCreate: React.FC<any> = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const setLoader = useLoader();

  const { checkRole } = useRoleValidation();

  const onSubmit = async (values: any) => {
    setLoader();
    const resp = await createSample(
      {
        ...values,
        case: values.case.id === 2 ? true : false,
        locationId: get(values, 'location.id'),
        location: undefined,
        departmentId: get(values, 'department.id'),
        department: undefined,
        isInCase: undefined,
        itemCase: get(values, 'isInCase.id') === 2 ? get(values, 'itemCase.id') : null,
        canLent: get(values, 'canLent.id') === 2 ? true : false,
        userId: values?.user ? values.user.id : undefined,
      },
      values.useObtainedCode ? true : false,
    );
    if (get(resp, 'status')) {
      enqueueSnackbar('Vzorek byl úspěšně vytvořen', { variant: 'success' });
      navigate(`/samples/${get(resp, 'data.id')}`);
    } else {
      enqueueSnackbar(get(resp, 'errorText') || 'Vzorek se nepovedlo vytvořit', {
        variant: 'error',
      });
    }
    setLoader(false);
  };

  return checkRole('ROLE_ITEM_CREATE') ? (
    <div>
      <Typography variant="h6" gutterBottom component="div">
        Přidání vzorku
      </Typography>
      <SampleForm onSubmit={onSubmit} />
    </div>
  ) : (
    <div>Nemáte právo na vytvoření vzorku</div>
  );
};

export default SampleCreate;
