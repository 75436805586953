import {
  ADD_TO_RESERVATION_ITEMS,
  REMOVE_FROM_RESERVATION_ITEMS,
  SET_RESERVATION_ITEMS,
  ADD_SAMPLES,
} from 'utils/constants';

export const addSampleToReservation = (sample: any) => ({
  type: ADD_TO_RESERVATION_ITEMS,
  payload: sample,
});

export const setSamplesInReservation = (samples: any) => ({
  type: SET_RESERVATION_ITEMS,
  payload: samples,
});

export const removeSampleToReservation = (sample: any) => ({
  type: REMOVE_FROM_RESERVATION_ITEMS,
  payload: sample,
});

export const addSamples = (samples: any) => ({
  type: ADD_SAMPLES,
  payload: samples,
});
