import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import CustomerForm from './CustomerForm';
import { createCustomerCompany } from './_api';
import useLoader from 'utils/hooks/useLoader';
import { get } from 'lodash';

const CustomerCreate: React.FC<any> = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const setLoader = useLoader();

  const onSubmit = async (values: any) => {
    setLoader();
    const resp = await createCustomerCompany(values);
    if (get(resp, 'status')) {
      enqueueSnackbar('Zákazník byl úspěšně vytvořen', { variant: 'success' });
      navigate(`/settings?active=customers`);
    } else {
      enqueueSnackbar(get(resp, 'errorText') || 'Zákazníka se nepovedlo vytvořit', {
        variant: 'error',
      });
    }
    setLoader(false);
  };

  return (
    <div>
      <Typography variant="h6" gutterBottom component="div">
        Přidání zákazníka
      </Typography>
      <CustomerForm onSubmit={onSubmit} />
    </div>
  );
};

export default CustomerCreate;
