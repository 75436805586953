import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import WorkplaceForm from './WorkplaceForm';
import { createWorkplace, manageRoles } from './_api';
import useLoader from 'utils/hooks/useLoader';
import { forEach, keys } from 'lodash';

const WorkplaceCreate: React.FC<any> = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const setLoader = useLoader();

  const onSubmit = async (values: any) => {
    setLoader();
    let ids: any = [];
    forEach(keys(values.roles), (item) => {
      if (values.roles[item]) {
        ids = [...ids, parseInt(item, 10)];
      }
    });

    const resp = await createWorkplace({ ...values, roles: undefined });
    if (resp) {
      const resp2 = await manageRoles({ workPlaceId: resp.id, roleIds: ids });
      if (resp2) {
        enqueueSnackbar('Pracovní místo bylo úspěšně vytvořen', { variant: 'success' });
        navigate(`/settings?active=workplaces`);
      } else {
        enqueueSnackbar('Pracovní místo se povedlo vytvořit, ale nepodařily se přiřadit role', {
          variant: 'error',
        });
      }
    } else {
      enqueueSnackbar('Pracovní místo se nepovedlo vytvořit', { variant: 'error' });
    }
    setLoader(false);
  };

  return (
    <div>
      <Typography variant="h6" gutterBottom component="div">
        Přidání pracovního místa
      </Typography>
      <WorkplaceForm onSubmit={onSubmit} />
    </div>
  );
};

export default WorkplaceCreate;
