import { useMemo, useRef } from 'react';

import {
  get,
  find,
  filter,
  isArray,
  includes,
  debounce,
  uniq,
  difference,
  intersection,
  compact,
  forEach,
  throttle,
} from 'lodash';
import { useSnackbar } from 'notistack';
import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useLoader from 'utils/hooks/useLoader';
import {
  getById,
  confirm,
  finishStockTakingApi,
  confirmMultipleItems,
  confirmMultipleCases,
} from './_api';
import {
  Button,
  Typography,
  Grid,
  Paper,
  Box,
  Tab,
  Tabs,
  Tooltip,
  IconButton,
  TextField,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import {
  Grid as GridTable,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableFilterRow,
} from '@devexpress/dx-react-grid-material-ui';
import {
  SortingState,
  PagingState,
  FilteringState,
  IntegratedPaging,
  IntegratedSorting,
  IntegratedFiltering,
  DataTypeProvider,
} from '@devexpress/dx-react-grid';
import { SAMPLE_STATES } from 'utils/constants';
import moment from 'moment';
import CheckIcon from '@mui/icons-material/Check';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import AutocompleteForm from 'components/Form/Autocomplete/Autocomplete';
import { getLocations } from 'modules/Settings/Locations/_api';
import { useForm, FormProvider } from 'react-hook-form';
import SamplesResults from 'modules/Samples/SamplesResults';
import useItemConvert from 'utils/hooks/useItemConvert';
import CaseConfirmDialog from './CaseConfirmDialog';
import useRoleValidation from 'utils/hooks/useRoleValidation';
import MultipleEditDialog from 'modules/Samples/MultipleEditDialog';
import ConfirmNewLocationDialog from './ItemConfirmNewLocationStateDialog';
import CaseConfirmNewLocationDialog from './CaseConfirmNewLocationStateDialog';
import MultipleItemsConfirmDialog from './MultipleItemsConfirmDialog';
import queryString from 'query-string';

const StockTakingDetail: React.FC<any> = () => {
  const [stockTaking, setStockTaking] = useState<any>(false);
  const [confirmedItems, setConfirmedItems] = useState<any>([]);
  const [unconfirmedItems, setUnconfirmedItems] = useState<any>([]);
  const [itemsToConfirm, setItemsToConfirm] = useState<any>([]);
  const [confirmCaseDialog, setConfirmCaseDialog] = useState<any>({ open: false });
  const [confirmNewLocationDialog, setConfirmNewLocationDialog] = useState<any>({ open: false });
  const [caseConfirmNewLocationDialog, setCaseConfirmNewLocationDialog] = useState<any>({
    open: false,
  });
  const [multipleItemsConfirmDialog, setMultipleItemsConfirmDialog] = useState<any>({
    open: false,
  });

  const [location, setLocation] = useState<any>(false);
  const [department, setDepartment] = useState<any>(false);
  const [confirmedSorting, setConfirmedSorting] = useState<any>([
    { columnName: 'confirmedDate', direction: 'desc' },
  ]);
  const [selection, setSelection] = useState<any>([]);
  const [multipleEditDialog, setMultipleEditDialog] = useState<any>(false);
  const [addSampleText, setAddSampleText] = useState<any>('');
  const [addSampleCheckbox, setAddSampleCheckbox] = useState<any>(false);
  const [value, setValue] = useState('unconfirmed');
  const [confirmedFilters, setConfirmedFilters] = useState<any>([]);
  const [locations, setLocations] = useState<any>([]);
  const [departments, setDepartments] = useState<any>([]);
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const setLoader = useLoader();
  const { checkRole } = useRoleValidation();
  const methods = useForm();
  const { handleSubmit } = methods;
  const stockTakingId = parseInt(get(params, 'stockTakingId', ''), 0);
  const { sampleConvert } = useItemConvert();
  const getUserDepartments = useSelector((state: any) => get(state, 'app.user.departments'));
  const loggedUser = useSelector((state: any) => get(state, 'app.user', null));

  // pole ID potvrzených vzorků
  const confirmedItemsIds = useMemo(
    () => confirmedItems.map((item: any) => get(item, 'item.id')),
    [confirmedItems],
  );

  console.log(location);
  // pole ID vzorků k potvrzení
  const itemsToConfirmIds = itemsToConfirm.map((item: any) => item.id);

  const analyzeCase = (item: any, itemsInCase: any, selection: any) => {
    console.log(item, itemsInCase);

    // pokud se mění umístění kufru
    const isChangedLocation = get(item, 'location.id') !== get(location, 'id');

    //pokud standardCaseContent nesedí s obsahem kufru
    const standardCaseContent = JSON.parse(get(item, 'standardCaseContent') || '[]');

    //musí sedět počet položek v kufru a v standardCaseContent a musí sedět i jejich počet
    const isStandardCaseContentOk =
      standardCaseContent.length === itemsInCase.length &&
      JSON.parse(get(item, 'standardCaseContent') || '[]')
        .map((item: any) => item.id)
        .every((el: any) => itemsInCase.map((item: any) => item.id).includes(el));

    const itemsInCaseIds = itemsInCase.map((item: any) => item.id);
    const confirmedOrSelectedItems = uniq([
      ...intersection(itemsInCaseIds, confirmedItemsIds),
      ...selection,
    ]);
    const selectedWithoutConfirmedIds = [...difference(selection, confirmedItemsIds)];
    const completeCase =
      itemsInCaseIds.every((item: any) => confirmedOrSelectedItems.includes(item)) &&
      itemsInCaseIds.length === confirmedOrSelectedItems.length;
    const isStateChangedFromAvailableToIncomplete =
      !completeCase && get(item, 'state') === 'AVAILABLE';

    const isStateChangedFromIncompleteToAvailable =
      completeCase && get(item, 'state') === 'INCOMPLETE';
    return {
      selectedWithoutConfirmedIds,
      isChangedLocation,
      isStandardCaseContentOk,
      isCompleteCase: completeCase,
      isStateChangedFromAvailableToIncomplete,
      warnings: compact([
        isChangedLocation && completeCase
          ? {
              id: 'locationChanged',
              message: `umístění kufru bude změněno z ${get(item, 'location.name')} na ${get(
                location,
                'label',
              )}`,
            }
          : null,
        isStateChangedFromAvailableToIncomplete && get(location, 'id') !== 'internalUsage'
          ? {
              id: 'incompleteCase',
              message: 'stav kufru bude změněn z K dispozici na Nekompletní',
            }
          : null,
        isStateChangedFromIncompleteToAvailable && get(location, 'id') !== 'internalUsage'
          ? {
              id: 'availableCase',
              message: 'stav kufru bude změněn z Nekompletní na K dispozici',
            }
          : null,
      ]),
      errors: compact([
        isStandardCaseContentOk
          ? null
          : {
              id: 'standardCaseContent',
              message:
                'Kufr obsahuje vzorky, které neodpovídají výchozímu obsahu kufru, proto nelze pokračovat',
            },
      ]),
    };
  };

  const analyzeToConfirmItems = () => {
    console.log(itemsToConfirm);
    const warnings: any = [];
    forEach(itemsToConfirm, (item) => {
      const info = [];
      if (location.id !== item.location.id) {
        info.push(`změna umístění z ${item.location.name} na ${location.label}`);
      }
      if (item.case) {
        if (item.itemsInCase.length !== item.caseItemIds.length) {
          info.push(
            `změna stavu z ${get(
              find(SAMPLE_STATES, { id: item.state }),
              'label',
              '',
            )} na Nekompletní`,
          );
        }
        if (info.length > 0) {
          warnings.push(
            `Kufr (Reference: ${item.reference}, ID: ${item.code}): ${info.join(', ')}`,
          );
        }
      } else {
        if (item.state !== 'AVAILABLE') {
          info.push(
            `změna stavu z ${get(
              find(SAMPLE_STATES, { id: item.state }),
              'label',
              '',
            )} na K dispozici`,
          );
        }
        if (info.length > 0) {
          warnings.push(
            `Vzorek (Reference: ${item.reference}, ID: ${item.code}): ${info.join(', ')}`,
          );
        }
      }
    });

    console.log(warnings);
    return warnings;
  };

  const unConfirmedColumns = [
    { name: 'reference', title: 'Reference' },
    { name: 'code', title: 'ID' },
    { name: 'description', title: 'Popis' },
    { name: 'stateLabel', title: 'Stav' },
    { name: 'case_note', title: 'Kufr/Poznámka' },
    {
      name: 'location',
      title: 'Umístění',
      getCellValue: (row: any) => (get(row, 'user') ? '' : get(row, 'location.name', '')),
    },
    {
      name: 'department',
      title: 'Oddělení',
      getCellValue: (row: any) => get(row, 'department.name', ''),
    },
    { name: 'createdDate', title: 'Vytvořeno' },
    {
      name: 'actions',
      title: 'Akce',
      getCellValue: (row: any) =>
        get(stockTaking, 'state', '') === 'FINISHED' || get(row, 'state') === 'LENT' ? null : (
          <>
            {!includes(itemsToConfirmIds, row.id) ? (
              <Button
                variant="contained"
                onClick={async () => {
                  if (row.case) {
                    setConfirmCaseDialog({ open: true, sample: row });
                  } else if (location.id !== row.location.id || row.state !== 'AVAILABLE') {
                    setConfirmNewLocationDialog({ open: true, sample: row });
                  } else {
                    setLoader();
                    await confirmItems([get(row, 'id')]);
                    setLoader(false);
                  }
                }}
                startIcon={<CheckIcon />}
                size="small"
                sx={{ ml: '10px' }}
                disabled={get(row, 'itemCase')}
              >
                Potvrdit
              </Button>
            ) : (
              ''
            )}
            {!includes(itemsToConfirmIds, row.id) ? (
              <Tooltip
                title={`Přidat k potvrzení ${
                  row?.itemCase ? '(Nelze potvrdit vzorek z kufru)' : ''
                }`}
              >
                <span>
                  <IconButton
                    color="primary"
                    sx={{ p: '5px' }}
                    size="small"
                    onClick={() => {
                      if (row.case) {
                        setConfirmCaseDialog({ open: true, sample: row, addToConfirm: true });
                      } else {
                        addToConfirm(row);
                      }
                    }}
                    disabled={get(row, 'itemCase')}
                  >
                    <AddCircleIcon />
                  </IconButton>
                </span>
              </Tooltip>
            ) : (
              ''
            )}
          </>
        ),
    },
  ];

  const toConfirmColumns = useMemo(
    () => [
      { name: 'reference', title: 'Reference' },
      { name: 'code', title: 'ID' },
      { name: 'description', title: 'Popis' },
      { name: 'stateLabel', title: 'Stav' },
      { name: 'case_note', title: 'Kufr/Poznámka' },
      {
        name: 'location',
        title: 'Umístění',
        getCellValue: (row: any) => get(row, 'location.name', ''),
      },
      {
        name: 'department',
        title: 'Oddělení',
        getCellValue: (row: any) => get(row, 'department.name', ''),
      },
      { name: 'createdDate', title: 'Vytvořeno' },
      {
        name: 'actions',
        title: 'Akce',
        getCellValue: (row: any) =>
          get(stockTaking, 'state', '') === 'FINISHED' ? null : (
            <>
              <Tooltip title="Odebrat">
                <IconButton
                  color="primary"
                  sx={{ p: '5px' }}
                  size="small"
                  onClick={() => {
                    setItemsToConfirm([
                      ...filter(itemsToConfirm, (item: any) => item.id !== row.id),
                    ]);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </>
          ),
      },
    ],
    [itemsToConfirm, stockTaking, setItemsToConfirm],
  );

  const addToConfirm = (row: any) => {
    setItemsToConfirm((items: any) => [...items, row]);
  };

  const confirmedColumns = [
    { name: 'code', title: 'ID' },
    { name: 'reference', title: 'Reference' },
    { name: 'description', title: 'Popis' },
    { name: 'case', title: 'Kufr' },
    { name: 'stateLabel', title: 'Stav' },
    {
      name: 'location',
      title: 'Umístění',
      getCellValue: (row: any) => (get(row, 'user') ? '' : get(row, 'location.name')),
    },

    // { name: 'confirmedAtLocation', title: 'Potvrzeno v umístění' },
    { name: 'confirmedDate', title: 'Potvrzeno dne' },
    { name: 'confirmedBy', title: 'Potvrzeno uživatelem' },
  ];

  const onLocationAndDepartmentSubmit = async (values: any) => {
    setLocation(get(values, 'location', false));
    setDepartment(get(values, 'department', false));
  };

  const finishStockTaking = async (stockTakingId: Number) => {
    setLoader();
    const resp = await finishStockTakingApi(stockTakingId);
    if (resp) {
      setStockTaking(resp);
      enqueueSnackbar('Inventura ukončena.', { variant: 'success' });
    } else {
      enqueueSnackbar('Inventuru nelze ukončit.', { variant: 'error' });
    }
    setLoader(false);
  };

  const submitCase = (caseItem: any, itemsInCase: any, selection: any) => {
    console.log(caseItem, itemsInCase, selection);
    const itemsInCaseIds = itemsInCase.map((item: any) => item.id);
    const confirmedOrSelectedItems = uniq([
      ...intersection(itemsInCaseIds, confirmedItemsIds),
      ...selection,
    ]);
    const selectedWithoutConfirmedIds = [...difference(selection, confirmedItemsIds)];
    console.log(selection, confirmedItemsIds, selectedWithoutConfirmedIds);
    console.log(itemsInCaseIds, confirmedOrSelectedItems);
    confirmCases([
      {
        id: caseItem.id, // case
        caseItemIds: selectedWithoutConfirmedIds,
      },
    ]);
  };

  const setConfirmedAndUnconfirmedItems = (items: any) => {
    setUnconfirmedItems((unconfItems: any) => [
      ...filter(
        unconfItems,
        (item: any) =>
          !includes(
            items.map((confirmed: any) => get(confirmed, 'item.id', 0)),
            item.id,
          ),
      ),
    ]);
    setConfirmedItems((confItems: any) => [...confItems, ...items]);
  };

  const confirmItems = async (itemsToConfirm: any) => {
    setLoader();
    console.log();
    const req = {
      stockTakingId: get(stockTaking, 'id'),
      locationId: get(location, 'id') === 'internalUsage' ? undefined : get(location, 'id'),
      internalUsage: get(location, 'id') === 'internalUsage' ? true : undefined,
      departmentId: get(department, 'id'),
      itemIds: itemsToConfirm,
    };
    const resp = await confirmMultipleItems(req);
    setLoader(false);
    if (resp && isArray(resp) && resp.length > 0) {
      setConfirmedAndUnconfirmedItems(resp);
      if (resp.length > 1) {
        enqueueSnackbar('Vzorky byly potvrzeny', { variant: 'success' });
      } else if (resp.length === 1) {
        enqueueSnackbar('Vzorek byl potvrzen', { variant: 'success' });
      }
    } else {
      enqueueSnackbar('Při potvrzování vzorků do inventury došlo k chybě', { variant: 'error' });
    }
    return resp;
  };

  const confirmCases = async (itemsToConfirm: any) => {
    console.log(itemsToConfirm);
    setLoader();
    const req = {
      stockTakingId: get(stockTaking, 'id'),
      locationId: get(location, 'id') === 'internalUsage' ? undefined : get(location, 'id'),
      internalUsage: get(location, 'id') === 'internalUsage' ? true : undefined,
      cases: itemsToConfirm.map((item: any) => ({
        stockTakingId: get(stockTaking, 'id'),
        itemId: item.id,
        locationId: get(location, 'id') === 'internalUsage' ? undefined : get(location, 'id'),
        internalUsage: get(location, 'id') === 'internalUsage' ? true : undefined,
        caseItemIds: item.caseItemIds,
      })),
    };
    const resp = await confirmMultipleCases(req);
    setLoader(false);
    if (resp) {
      setConfirmedAndUnconfirmedItems(resp);
      enqueueSnackbar('Kufr/y byly potvrzeny', { variant: 'success' });
    } else {
      enqueueSnackbar('Při potvrzování vzorků do inventury došlo k chybě', { variant: 'error' });
    }
    return resp;
  };

  const submitMultipleItems = async () => {
    const cases = filter(itemsToConfirm, (item: any) => item.case);
    const items = filter(itemsToConfirm, (item: any) => !item.case);

    let submitOk = true;

    if (cases.length > 0) {
      const resp = await confirmCases(
        cases.map((caseItem) => ({
          id: caseItem.id,
          caseItemIds: caseItem.caseItemIds,
        })),
      );
      submitOk = submitOk && resp;
    }
    if (items.length > 0) {
      const resp = await confirmItems(items.map((item) => item.id));
      submitOk = submitOk && resp;
    }
    if (submitOk) {
      setItemsToConfirm([]);
      setMultipleItemsConfirmDialog({ open: false });
    }
  };

  const loadLocations = async () => {
    const resp = await getLocations();
    if (resp) {
      setLocations([...resp, { id: 'internalUsage', name: 'Interní použití' }]);
    }
    const { locationId } = queryString.parse(window.location.search) || '';
    console.log(locationId);
    if (locationId) {
      const loc = find(resp, { id: parseInt(locationId as string, 10) });
      console.log(loc);
      setLocation({ id: get(loc, 'id'), label: get(loc, 'name') });
    }
  };

  const loadDepartments = () => {
    setDepartments(getUserDepartments);
  };

  const loadStockTaking = async (stockTakingId: any) => {
    setLoader(true);
    const resp = await getById(stockTakingId);
    if (resp) {
      setStockTaking({ ...resp, confirmedItems: undefined, unconfirmedItems: undefined });
      setConfirmedItems(get(resp, 'confirmedItems', []));
      setUnconfirmedItems(
        get(resp, 'unconfirmedItems', []).map((item: any) => sampleConvert(item, location)),
      );
    } else {
      enqueueSnackbar('Inventura nenalezena.', { variant: 'error' });
    }
    setLoader(false);
  };

  const findSampleAndToConfirm = (
    code: string,
    itemsToConfirm2: any,
    unconfirmedItems2: any,
    confirmedItems2: any,
  ) => {
    console.log(itemsToConfirm2);
    const itemsToConfirmCodes = itemsToConfirm2.map((item: any) => item.code.toUpperCase());
    const itemConfirmed = find(confirmedItems2, (item: any) => get(item, 'item.code') === code);
    const findSample = find(unconfirmedItems2, { code: code.toUpperCase() });
    if (includes(itemsToConfirmCodes, code.toUpperCase())) {
      enqueueSnackbar(`Vzorek s ID ${code} je již v seznamu pro potvrzení`, {
        variant: 'warning',
      });
    } else if (itemConfirmed) {
      enqueueSnackbar(
        `Vzorek s ID ${code} byl již potvrzen v čase ${moment(itemConfirmed.confirmedDate).format(
          'DD.MM.YYYY HH:mm:ss',
        )}, uživatel ${get(itemConfirmed, 'confirmedBy.lastName')} ${get(
          itemConfirmed,
          'confirmedBy.firstName',
        )}`,
        {
          variant: 'warning',
        },
      );
    } else if (get(findSample, 'itemCase')) {
      const caseItem = get(findSample, 'itemCase');
      const action = (snackbarId: any) => (
        <>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              setConfirmCaseDialog({ open: true, sample: caseItem });
            }}
          >
            Otevřít kufr
          </Button>
        </>
      );
      enqueueSnackbar(
        `Vzorek s ID ${code} je součástí kufru (Reference: ${get(caseItem, 'reference')}, ID: ${get(
          caseItem,
          'code',
        )})`,
        {
          variant: 'warning',
          action,
        },
      );
    } else {
      if (findSample) {
        if (findSample.case) {
          setConfirmCaseDialog({ open: true, sample: findSample, addToConfirm: true });
        } else {
          addToConfirm(findSample);
          enqueueSnackbar(
            `Vzorek ${get(findSample, 'reference')} (ID: ${code}) přidán k potvrzení `,
            {
              variant: 'success',
            },
          );
        }
      } else {
        enqueueSnackbar(`Vzorek s ID ${code} nenalezen v nepotvrzených vzorcích`, {
          variant: 'error',
        });
      }
    }
    setAddSampleText('');
  };

  /*const debouncedFindSampleAndToConfirm = useCallback(
    debounce((addSampleText) => {
      console.log(addSampleText);
      findSampleAndToConfirm(addSampleText);
    }, 300),
    [unconfirmedItems, confirmedItems, itemsToConfirm],
  );*/

  const throttled = useRef(
    throttle(
      (newValue, itemsToConfirm, unconfirmedItems, confirmedItems) =>
        findSampleAndToConfirm(
          parseInt(newValue, 10).toString(),
          itemsToConfirm,
          unconfirmedItems,
          confirmedItems,
        ),
      1000,
      { leading: false },
    ),
  );

  useEffect(() => {
    if (addSampleText !== '' && addSampleCheckbox) {
      // debouncedFindSampleAndToConfirm(addSampleText);
      throttled.current(addSampleText, itemsToConfirm, unconfirmedItems, confirmedItems);
    }
  }, [
    addSampleText,
    addSampleCheckbox,
    throttled,
    unconfirmedItems /*debouncedFindSampleAndToConfirm*/,
  ]);

  const TableCell = (props: any) => (
    <Table.Cell {...props} style={{ paddingTop: 5, paddingBottom: 5 }} />
  );

  const DateFormatter = ({ value }: any) =>
    moment(value).isValid() ? moment(value).format('DD.MM.YYYY HH:mm:ss') : '';

  const DateTypeProvider = (props: any) => (
    <DataTypeProvider formatterComponent={DateFormatter} {...props} />
  );

  const StateFormatter = ({ value }: any) => {
    const state = find(SAMPLE_STATES, { id: value });
    return state ? get(state, 'label') : '';
  };

  const StateTypeProvider = (props: any) => (
    <DataTypeProvider formatterComponent={StateFormatter} {...props} />
  );

  const handleChange = (event: any, newValue: string) => {
    setValue(newValue);
  };

  const TableRowConfirmed = ({ row, ...restProps }: any) => {
    const confirmedLocation = get(row, 'confirmedAtLocation');
    const location = get(row, 'location.name');
    let color = 'white';
    if (confirmedLocation !== location) {
      color = '#f2e9b6';
    }

    return (
      <Table.Row
        {...restProps}
        sx={{
          backgroundColor: color,
        }}
      />
    );
  };

  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        if (!addSampleCheckbox) {
          event.preventDefault();
          findSampleAndToConfirm(addSampleText, itemsToConfirm, unconfirmedItems, confirmedItems);
        }
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [addSampleCheckbox, addSampleText, itemsToConfirm, unconfirmedItems, confirmedItems]);

  useEffect(() => {
    if (stockTakingId > 0) {
      loadStockTaking(stockTakingId);
      loadDepartments();
      if (locations.length < 1) {
        loadLocations();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div>
      {stockTaking !== false ? (
        <>
          {get(stockTaking, 'state', '') === 'IN_PROGRESS' &&
          /* department === false && */
          location === false ? (
            <>
              <Typography variant="h6" gutterBottom component="div">
                Pro pokračování zvolte svoje umístění nebo interní použití
              </Typography>
              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onLocationAndDepartmentSubmit)}>
                  <Grid container={true} spacing={0}>
                    {/*<Grid item xs={6} md={3} sx={{ mr: '5px' }}>
                      <AutocompleteForm
                        name="department"
                        label="Oddělení"
                        options={departments.map((item: any) => ({
                          id: item.id,
                          label: item.name,
                        }))}
                        rules={{ required: 'Vyžadováno' }}
                      />
                    </Grid> */}
                    <Grid item xs={6} md={3} sx={{ mr: '5px' }}>
                      <AutocompleteForm
                        name="location"
                        label="Umístění/interní použití"
                        options={locations.map((item: any) => ({ id: item.id, label: item.name }))}
                        rules={{ required: 'Vyžadováno' }}
                      />
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Button variant="contained" color="primary" type="submit" sx={{ mt: '10px' }}>
                        Pokračovat
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </FormProvider>
            </>
          ) : (
            <>
              <Typography variant="h6" gutterBottom component="div">
                {`Inventura, oddělení: ${get(stockTaking, 'department.name')}, umístění: ${get(
                  location,
                  'label',
                )}, začátek: ${
                  moment(get(stockTaking, 'startedDate')).isValid()
                    ? moment(get(stockTaking, 'startedDate')).format('DD.MM.YYYY HH:mm:ss')
                    : ''
                }`}
                {get(stockTaking, 'state', '') === 'IN_PROGRESS' && (
                  <Tooltip
                    title={`Ukončit inventuru${
                      checkRole('ROLE_STOCK_TAKING_FINISH') ? '' : ' (nemáte právo)'
                    }`}
                  >
                    <span>
                      <Button
                        variant="contained"
                        onClick={() => {
                          if (window.confirm('Opravdu chcete ukončit inventuru?')) {
                            finishStockTaking(get(stockTaking, 'id', 0));
                          }
                        }}
                        startIcon={<CheckIcon />}
                        size="small"
                        sx={{ ml: '10px' }}
                        disabled={!checkRole('ROLE_STOCK_TAKING_FINISH')}
                      >
                        Ukončit
                      </Button>
                    </span>
                  </Tooltip>
                )}
              </Typography>
              {get(stockTaking, 'state', '') === 'FINISHED' && (
                <Typography variant="body1" gutterBottom component="div">
                  Ukončená:{' '}
                  {moment(get(stockTaking, 'finishedDate')).isValid()
                    ? moment(get(stockTaking, 'finishedDate')).format('DD.MM.YYYY HH:mm:ss')
                    : ''}
                </Typography>
              )}
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab
                      label={`Nepotvrzené vzorky (${unconfirmedItems.length})`}
                      value="unconfirmed"
                    />
                    <Tab label={`Potvrzené vzorky (${confirmedItems.length})`} value="confirmed" />
                  </Tabs>
                </Box>
                {value === 'confirmed' && (
                  <Paper sx={{ p: '10px' }}>
                    <GridTable
                      rows={confirmedItems.map((confirmedItem: any) => {
                        const item = get(confirmedItem, 'item');
                        console.log(item);
                        return {
                          ...sampleConvert(item),
                          case: get(item, 'case', false) ? 'Ano' : 'Ne',
                          confirmedDate: get(confirmedItem, 'confirmedDate'),
                          confirmedBy: get(confirmedItem, 'confirmedBy.username'),
                          confirmedAtLocation: get(confirmedItem, 'location.name'),
                          confirmedAtDepartment: get(confirmedItem, 'department.name'),
                        };
                      })}
                      columns={confirmedColumns}
                    >
                      <DateTypeProvider for={['createdDate', 'confirmedDate']} />
                      <StateTypeProvider for={['state']} />
                      <FilteringState
                        filters={confirmedFilters}
                        onFiltersChange={setConfirmedFilters}
                      />
                      <SortingState
                        sorting={confirmedSorting}
                        onSortingChange={setConfirmedSorting}
                      />
                      <PagingState defaultCurrentPage={0} pageSize={100} />
                      <IntegratedFiltering />
                      <IntegratedSorting />
                      <IntegratedPaging />
                      <Table cellComponent={TableCell} rowComponent={TableRowConfirmed} />
                      <TableHeaderRow showSortingControls={true} />
                      <TableFilterRow />
                      <PagingPanel />
                    </GridTable>
                  </Paper>
                )}
                {value === 'unconfirmed' && (
                  <Box>
                    {get(stockTaking, 'state', '') !== 'FINISHED' && (
                      <Box>
                        <Box sx={{ m: '15px' }}>
                          <b>Vzorky k potvrzení</b>
                        </Box>
                        <Paper sx={{ p: '10px', mt: '10px' }}>
                          <SamplesResults columns={toConfirmColumns} samples={itemsToConfirm} />
                          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                              variant="contained"
                              sx={{ ml: '10px' }}
                              disabled={itemsToConfirm.length < 1}
                              onClick={() => {
                                setMultipleItemsConfirmDialog({
                                  open: true,
                                  warnings: analyzeToConfirmItems(),
                                });
                              }}
                            >
                              Potvrdit vzorky
                            </Button>
                          </Box>
                        </Paper>
                        <Box sx={{ m: '15px' }}>
                          <b>Nepotvrzené vzorky</b>
                          <Box
                            sx={{
                              m: '15px',
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                            }}
                          >
                            <TextField
                              variant="outlined"
                              margin="dense"
                              size="small"
                              label="ID vzorku k potvrzení"
                              onChange={(e) => {
                                setAddSampleText(e.target.value);
                              }}
                              value={addSampleText}
                            />
                            <FormControlLabel
                              label={'Použít čtečku'}
                              style={{ marginLeft: '10px' }}
                              control={
                                <Checkbox
                                  checked={addSampleCheckbox}
                                  onChange={(e) => {
                                    setAddSampleCheckbox(e.target.checked);
                                  }}
                                />
                              }
                            />
                            <Box>
                              <Button
                                variant="contained"
                                sx={{ ml: '10px' }}
                                disabled={addSampleCheckbox}
                                onClick={() => {
                                  findSampleAndToConfirm(
                                    addSampleText,
                                    itemsToConfirm,
                                    unconfirmedItems,
                                    confirmedItems,
                                  );
                                }}
                              >
                                Přidat vzorek k potvrzení
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    )}
                    {get(stockTaking, 'state', '') === 'FINISHED' && (
                      <Box sx={{ pt: '20px' }}>
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{ ml: '10px' }}
                          onClick={() => {
                            setMultipleEditDialog(true);
                          }}
                        >
                          Hromadná úprava
                        </Button>
                      </Box>
                    )}
                    {!addSampleCheckbox && (
                      <Paper sx={{ p: '10px', mt: '10px' }}>
                        <SamplesResults
                          columns={unConfirmedColumns}
                          samples={unconfirmedItems}
                          selection={selection}
                          setSelection={setSelection}
                          canSelect={get(stockTaking, 'state', '') === 'FINISHED'}
                        />
                      </Paper>
                    )}
                  </Box>
                )}
              </Box>
            </>
          )}
        </>
      ) : null}
      {get(confirmCaseDialog, 'open', false) && (
        <CaseConfirmDialog
          data={confirmCaseDialog}
          setOpen={setConfirmCaseDialog}
          confirmCases={confirmCases}
          confirmItems={confirmItems}
          addToConfirm={addToConfirm}
          setCaseConfirmNewLocationDialog={setCaseConfirmNewLocationDialog}
          newLocation={location}
          confirmedIds={confirmedItemsIds}
          analyzeCase={analyzeCase}
          submitCase={submitCase}
        />
      )}

      {multipleEditDialog && (
        <MultipleEditDialog
          open={multipleEditDialog}
          samples={unconfirmedItems}
          setSamples={() => {}}
          selection={selection}
          setSelection={setSelection}
          setOpen={setMultipleEditDialog}
          stocking={true}
          onSubmitCallback={() => {
            loadStockTaking(stockTakingId);
          }}
        />
      )}
      {get(confirmNewLocationDialog, 'open', false) && (
        <ConfirmNewLocationDialog
          dialog={confirmNewLocationDialog}
          setDialog={setConfirmNewLocationDialog}
          newLocation={location}
          confirmItems={confirmItems}
          stockTaking={stockTaking}
          loggedUser={loggedUser}
        />
      )}

      {get(caseConfirmNewLocationDialog, 'open', false) && (
        <CaseConfirmNewLocationDialog
          dialog={caseConfirmNewLocationDialog}
          setDialog={setCaseConfirmNewLocationDialog}
          setCaseDialog={setConfirmCaseDialog}
          newLocation={location}
          confirmCases={confirmCases}
          confirmItems={confirmItems}
          stockTaking={stockTaking}
          confirmedIds={confirmedItemsIds}
        />
      )}
      {get(multipleItemsConfirmDialog, 'open', false) && (
        <MultipleItemsConfirmDialog
          dialog={multipleItemsConfirmDialog}
          setDialog={setMultipleItemsConfirmDialog}
          submitMultipleItems={submitMultipleItems}
        />
      )}
    </div>
  );
};

export default StockTakingDetail;
