import { get, find } from 'lodash';
import { useParams } from 'react-router-dom';
import { SAMPLE_STATES } from 'utils/constants';

const useItemConvert = () => {
  const params = useParams();
  const stockTakingId = get(params, 'stockTakingId');

  const sampleConvert = (sample: any, location: any = null) => {
    const itemCase = get(sample, 'itemCase');
    const user = get(sample, 'user');
    const state = get(find(SAMPLE_STATES, { id: sample.state }), 'label', '');
    let stateResult = state;
    if (user && sample.state === 'INTERNAL_USAGE') {
      stateResult += ` - ${get(user, 'lastName')} ${get(user, 'firstName')}`;
    }
    if (itemCase) {
      stateResult = get(find(SAMPLE_STATES, { id: 'IN_CASE' }), 'label', '');
    }

    return {
      ...sample,
      code: get(sample, 'code') ? get(sample, 'code').toUpperCase() : '',
      stateLabel: stateResult,
      itemCaseLink: itemCase
        ? `/samples/${get(itemCase, 'id')}${
            stockTakingId ? `?stockTakingId=${stockTakingId}&locationId=${get(location, 'id')}` : ''
          }`
        : undefined,
      isCaseLabel: get(sample, 'case') ? 'Ano' : 'Ne',
      canLentLabel: get(sample, 'canLent') ? 'Ano' : 'Ne',
      createdByLabel: `${get(sample, 'createdBy.lastName') || ''} ${
        get(sample, 'createdBy.firstName') || ''
      }`,
    };
  };
  return { sampleConvert };
};

export default useItemConvert;
