import { get, filter } from 'lodash';
import {
  IS_LOADING,
  USER_STORE,
  SET_NOTIFICATION,
  GRID_SETTINGS_STORE,
  ADD_TO_RESERVATION_ITEMS,
  SET_RESERVATION_ITEMS,
  REMOVE_FROM_RESERVATION_ITEMS,
  CLEAR_RESERVATION_ITEMS,
  ADD_SAMPLES,
  ADD_LENDINGS,
  SAVE_LENDINGS_VALUES,
} from '../constants';

const initialState = {
  isLoading: false,
  notification: { open: false },
  user: { isLoggedIn: null },
  settings: { grids: {} },
  reservationItems: [],
  samples: [],
  lendings: [],
  lendingForm: null,
};

const reducer = (state = initialState, { type, payload }: any) => {
  switch (type) {
    case IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case USER_STORE:
      return {
        ...state,
        user: payload,
      };
    case GRID_SETTINGS_STORE:
      return {
        ...state,
        settings: { grids: payload },
      };
    case ADD_TO_RESERVATION_ITEMS:
      return {
        ...state,
        reservationItems: [...get(state, 'reservationItems', []), payload],
      };
    case SET_RESERVATION_ITEMS:
      return {
        ...state,
        reservationItems: payload,
      };
    case REMOVE_FROM_RESERVATION_ITEMS:
      return {
        ...state,
        reservationItems: [
          ...filter(get(state, 'reservationItems', []), (item: any) => item.id !== payload.id),
        ],
      };
    case CLEAR_RESERVATION_ITEMS:
      return {
        ...state,
        reservationItems: [],
      };
    case SET_NOTIFICATION:
      return {
        ...state,
        notification: payload,
      };
    case ADD_SAMPLES:
      return {
        ...state,
        samples: payload,
      };
    case ADD_LENDINGS:
      return {
        ...state,
        lendings: payload,
      };
    case SAVE_LENDINGS_VALUES:
      return {
        ...state,
        lendingForm: payload,
      };
    default:
      return state;
  }
};

export default reducer;
