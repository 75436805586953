import { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Button, Checkbox, FormControlLabel, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { get, filter } from 'lodash';
import useLoader from 'utils/hooks/useLoader';
import { getDepartments, getDepartment } from 'modules/Settings/Departments/_api';
import AutocompleteForm from 'components/Form/Autocomplete/Autocomplete';

import InputForm from 'components/Form/Input/Input';

const BarCodeForm: React.FC<any> = ({ onSubmit }) => {
  const methods = useForm();
  const setLoader = useLoader();
  const { handleSubmit, getValues } = methods;
  const [departments, setDepartments] = useState<any>([]);
  const [department, setDepartment] = useState<any>([]);
  const [generateOldCodes, setGenerateOldCodes] = useState<any>(false);

  const userDepartments = useSelector((state) => get(state, 'app.user.departments', []));
  const userWorkplace = useSelector((state: any) => get(state, 'app.user.workplaces[0].level', ''));

  const loadEntities = async () => {
    setLoader();
    const [resp1] = await Promise.all([getDepartments()]);
    if (resp1) {
      setDepartments(filter(resp1, { active: true }));
    }

    setLoader(false);
  };

  const loadDepartment = async () => {
    setLoader();
    const resp = await getDepartment(formDepartment.id);
    if (resp) {
      setDepartment(resp);
      if (!generateOldCodes) {
        methods.setValue('from', resp.generatedTags + 1);
      }
    } else {
      setDepartment(false);
    }
    setLoader(false);
  };

  const formDepartment = methods.watch('department');

  useEffect(() => {
    if (formDepartment?.id) {
      loadDepartment();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formDepartment]);

  useEffect(() => {
    if (!generateOldCodes && department) {
      methods.setValue('from', department.generatedTags + 1);
    }
    if (generateOldCodes) {
      methods.setValue('from', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateOldCodes]);

  useEffect(() => {
    loadEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(async (values) => {
          methods.setValue('department', null);
          methods.setValue('count', '');
          methods.setValue('from', '');
          setDepartment(null);
          await onSubmit({ ...values, generateOldCodes });
          loadEntities();
        })}
      >
        <Grid container={true} spacing={1}>
          <Grid item xs={12}>
            <FormControlLabel
              label={'Generovat štítky zpětně'}
              style={{ marginLeft: '10px' }}
              control={
                <Checkbox
                  checked={generateOldCodes}
                  onChange={(e) => {
                    setGenerateOldCodes(e.target.checked);
                  }}
                />
              }
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3} lg={2}>
            <AutocompleteForm
              name="department"
              label="Oddělení (aktivní)"
              options={(userWorkplace === 'TOP_ADMIN' ? departments : userDepartments)
                .sort((a: any, b: any) => (a.name || '').localeCompare(b.name || ''))
                .map((item: any) => ({
                  id: item.id,
                  label: `${item.name}`,
                  generatedTags: item.generatedTags,
                  // disabled: !(find(userDepartments, { id: item.id }) || userIsAdmin),
                }))}
              rules={{ required: 'Vyžadováno' }}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <InputForm
              name="from"
              label="Od čísla"
              type="number"
              disabled={!generateOldCodes}
              rules={{
                validate: (value: any) => {
                  if (parseInt(value, 10) < 1) {
                    return `Číslo musí být větší než 0`;
                  } else {
                    return true;
                  }
                },
                required: 'Vyžadováno',
              }}
            />
          </Grid>
          {/* <Grid item xs={6} md={3}>
            <InputForm
              name="to"
              label="Do čísla"
              type="number"
              rules={{
                validate: (value: any) => {
                  if (parseInt(getValues().to, 10) <= parseInt(getValues().from, 10)) {
                    return `Číslo musí být větší než ${getValues().from}`;
                  } else {
                    return true;
                  }
                },
                required: 'Vyžadováno',
              }}
            />
          </Grid>*/}
          <Grid item xs={6} md={3}>
            <InputForm
              name="count"
              label="Počet vzorků"
              rules={{
                validate: (value: any) => {
                  if (parseInt(value, 10) < 1) {
                    return `Číslo musí být větší než 0`;
                  } else {
                    if (generateOldCodes) {
                      console.log(formDepartment, parseInt(getValues().from, 10));
                      return parseInt(getValues().from, 10) + parseInt(value, 10) - 1 <=
                        formDepartment.generatedTags
                        ? true
                        : `Počet vzorků musí být menší než  ${
                            formDepartment.generatedTags - parseInt(getValues().from, 10) + 1
                          } Maximální číslo vzorku, které můžete zpětně tisknout je ${
                            formDepartment.generatedTags
                          }.`;
                    }
                    return true;
                  }
                },
                required: 'Vyžadováno',
              }}
              type="number"
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container={true} spacing={2}>
              <Grid item xs={6} md={3}>
                <InputForm
                  name="skipPositions"
                  label="Vynecháno položek na papíře"
                  type="number"
                  rules={{
                    validate: (value: any) => {
                      const number = parseInt(value, 10);
                      if (number < 0) {
                        return `Číslo musí být větší nebo rovno 0`;
                      } else if (number > 132) {
                        return `Maximální počet vynechaných položek je 132`;
                      } else {
                        return true;
                      }
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" type="submit">
              Generovat
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default BarCodeForm;
