import { useState, useMemo, useEffect } from 'react';
import { Button, Typography, Paper, Box, IconButton, Tooltip } from '@mui/material';
import { Link, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import ArticleIcon from '@mui/icons-material/Article';
import { isArray, get, includes, head } from 'lodash';
import { useSnackbar } from 'notistack';

import { getSamples } from './_api';
import { addSamples, addSampleToReservation } from './_actions';
import useLoader from 'utils/hooks/useLoader';
import SampleSearchForm from './SampleSearchForm';
import SamplesResults from './SamplesResults';

import useItemConvert from 'utils/hooks/useItemConvert';
import useUserInfo from 'utils/hooks/useUserInfo';
import MultipleEditDialog from './MultipleEditDialog';
import useRoleValidation from 'utils/hooks/useRoleValidation';

const Samples: React.FC<any> = () => {
  const setLoader = useLoader();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { sampleConvert } = useItemConvert();
  const { userIsAdmin, userDepartments } = useUserInfo();
  const [selection, setSelection] = useState<any>([]);
  const [multipleEditDialog, setMultipleEditDialog] = useState<any>(false);
  const [actualRows, setActualRows] = useState<any>([]);

  const [searchParams] = useSearchParams();
  const action = searchParams.get('action');

  const reservationItems = useSelector((state: any) => get(state, 'app.reservationItems', []));
  const reservationItemsIds = reservationItems.map((item: any) => item.id);
  const reservationDepartmentId = get(head(reservationItems), 'department.id', null);

  const samples = useSelector((state: any) => get(state, 'app.samples', []));

  const { checkRole, checkRoleLabel } = useRoleValidation();

  const columns = useMemo(
    () => [
      { name: 'reference', title: 'Reference' },
      { name: 'code', title: 'ID' },
      {
        name: 'stateLabel',
        title: 'Stav',
      },
      {
        name: 'case_note',
        title: 'Ikony',
      },
      {
        name: 'department',
        title: 'Oddělení',
        getCellValue: (row: any) => get(row, 'department.name', ''),
      },
      {
        name: 'location',
        title: 'Umístění',
        getCellValue: (row: any) => (get(row, 'user') ? '' : get(row, 'location.name', '')),
      },
      {
        name: 'actions',
        title: 'Akce',
        getCellValue: (row: any) => {
          const canByDepartments =
            userIsAdmin ||
            includes(
              userDepartments.map((dep: any) => dep.id),
              get(row, 'department.id'),
            );

          return (
            <Box>
              <>
                <Tooltip title="Detail vzorku">
                  <IconButton
                    color="primary"
                    sx={{ p: '5px' }}
                    size="small"
                    component={Link}
                    to={`/samples/${row.id}`}
                  >
                    <ArticleIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={`Upravit vzorek ${checkRoleLabel('ROLE_ITEM_UPDATE', canByDepartments)}`}
                >
                  <span>
                    <IconButton
                      color="primary"
                      sx={{ p: '5px' }}
                      component={Link}
                      to={`/samples/${row.id}/edit`}
                      disabled={!(checkRole('ROLE_ITEM_UPDATE') && canByDepartments)}
                    >
                      <EditIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                {!includes(reservationItemsIds, row.id) &&
                checkRole('ROLE_LOAN_CREATE') &&
                row.state === 'AVAILABLE' &&
                row.canLent &&
                !row.itemCase ? (
                  <Tooltip title="Přidat do zápůjčky">
                    <IconButton
                      color="primary"
                      sx={{ p: '5px' }}
                      size="small"
                      onClick={() => {
                        dispatch(addSampleToReservation(row));
                        enqueueSnackbar(
                          `Vzorek ${row.reference} (id: ${row.code}) byl přidán do zápůjčky`,
                          {
                            variant: 'success',
                          },
                        );
                      }}
                      disabled={
                        !canByDepartments ||
                        (reservationDepartmentId !== null &&
                          reservationDepartmentId !== get(row, 'department.id', 0))
                      }
                    >
                      <AddCircleIcon />
                    </IconButton>
                  </Tooltip>
                ) : null}
              </>
            </Box>
          );
        },
      },
      { name: 'description', title: 'Popis' },
      {
        name: 'discountGroup',
        title: 'Rabat',
      },
      {
        name: 'activity',
        title: 'Aktivita',
      },
      {
        name: 'commercializationStatus',
        title: 'Stav komercializace',
      },
      { name: 'createdDate', title: 'Vytvořeno' },
      { name: 'createdByLabel', title: 'Vytvořil' },
    ],
    [
      dispatch,
      reservationItemsIds,
      enqueueSnackbar,
      checkRole,
      checkRoleLabel,
      userDepartments,
      userIsAdmin,
    ],
  );

  const setSamples = (values: any) => {
    dispatch(addSamples(values));
  };

  const handleScrollToElement = () => {
    const element = document.getElementById('search-results');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const onSearch = async (values: any) => {
    setLoader();
    const resp = await getSamples({
      code: values.code ? values.code.trim() : undefined,
      reference: values.reference ? values.reference.trim() : undefined,
      state:
        isArray(values.state) && values.state.length > 0
          ? values.state.map((item: any) => item.id)
          : undefined,
      userid: values.user ? values.user.id : undefined,
      user: undefined,
      description: values.description ? values.description.trim() : undefined,
      isCase: includes([1, 2], get(values, 'isCase.id'))
        ? values.isCase.id === 1
          ? true
          : false
        : undefined,
      locationId: get(values, 'location.id'),
      departmentId: get(values, 'department.id'),
      discountGroup: get(values, 'discountGroup') ? values.discountGroup : undefined,
      activity: get(values, 'activity') ? values.activity : undefined,
      commercializationStatus: get(values, 'commercializationStatus')
        ? values.commercializationStatus
        : undefined,
    });
    if (isArray(resp)) {
      setSamples(resp.map((item: any) => sampleConvert(item)));
    } else {
      setSamples([]);
    }
    setSelection([]);
    setLoader(false);

    setTimeout(handleScrollToElement, 300);
  };

  useEffect(() => {
    if (action !== 'back') {
      setSamples([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  return checkRole('ROLE_ITEM_VIEW') ? (
    <div>
      <Typography variant="h6" gutterBottom component="div">
        Správa vzorků
        <Tooltip title={`Přidat vzorek ${checkRoleLabel('ROLE_ITEM_CREATE')}`}>
          <span>
            <Button
              variant="contained"
              component={Link}
              to={'/samples/create'}
              startIcon={<AddCircleIcon />}
              size="small"
              sx={{ ml: '10px' }}
              disabled={!checkRole('ROLE_ITEM_CREATE')}
            >
              Nový
            </Button>
          </span>
        </Tooltip>
      </Typography>
      <Box sx={{ mb: '10px' }}>
        <SampleSearchForm
          onSearch={onSearch}
          resultsCount={samples.length}
          setSamples={setSamples}
          selection={selection}
          setSelection={setSelection}
          setMultipleEditDialog={setMultipleEditDialog}
          actualRows={actualRows}
          columns={columns}
        />
      </Box>

      {samples !== false ? (
        <Paper>
          <SamplesResults
            columns={columns}
            samples={samples}
            selection={selection}
            setSelection={setSelection}
            canSelect={true}
            virtualTable={true}
            setActualRows={setActualRows}
            isExport={true}
          />
        </Paper>
      ) : null}
      <MultipleEditDialog
        open={multipleEditDialog}
        samples={samples}
        setSamples={setSamples}
        selection={selection}
        setSelection={setSelection}
        setOpen={setMultipleEditDialog}
      />
    </div>
  ) : (
    <div>Nemáte právo prohlížet vzorky</div>
  );
};

export default Samples;
