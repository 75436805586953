import { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { Table as Table2, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import { get, intersection, includes, uniq, without, difference } from 'lodash';

import { getItemsInCase, multipleUpdate } from 'modules/Samples/_api';
import useItemConvert from 'utils/hooks/useItemConvert';
import useLoader from 'utils/hooks/useLoader';
import SamplesResults from 'modules/Samples/SamplesResults';
import { confirm } from './_api';

const CaseConfirmDialog: React.FC<any> = ({
  data,
  setOpen,
  addToConfirm,
  setCaseConfirmNewLocationDialog,
  confirmedIds,
  analyzeCase,
  submitCase,
}) => {
  const setLoader = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const { sampleConvert } = useItemConvert();
  const [itemsInCase, setItemsInCase] = useState<any>([]);
  const [selection, setSelection] = useState<any>([]);

  const itemCase = get(data, 'sample');
  console.log(itemCase);

  const handleClose = () => {
    setSelection([]);
    setOpen({ open: false });
  };

  console.log(selection);

  const fields = [
    { name: 'reference', label: 'Reference' },
    { name: 'code', label: 'ID' },
    { name: 'description', label: 'Popis' },
    { name: 'isCaseLabel', label: 'Vzorek je kufr' },
    { name: 'stateLabel', label: 'Stav' },
    {
      name: 'location',
      label: 'Umístění',
      getValue: (row: any) => get(row, 'location.name', ''),
    },
  ];

  const columnsSample = [
    { name: 'code', title: 'ID' },
    { name: 'reference', title: 'Reference' },
    { name: 'description', title: 'Popis' },
    { name: 'stateLabel', title: 'Stav' },
    { name: 'case_note', title: 'Kufr/Poznámka' },
    {
      name: 'location',
      title: 'Umístění',
      getCellValue: (row: any) => get(row, 'location.name', ''),
    },
    {
      name: 'department',
      title: 'Oddělení',
      getCellValue: (row: any) => get(row, 'department.name', ''),
    },
    { name: 'createdDate', title: 'Vytvořeno' },
    {
      name: 'actions',
      title: 'Akce',
    },
  ];

  const confirmedItems = intersection(
    itemsInCase.map((item: any) => item.id),
    confirmedIds,
  );

  const analyze = analyzeCase(itemCase, itemsInCase, selection);
  console.log(analyze);

  const onSubmit = async () => {
    const selectedWithoutConfirmedIds = get(analyze, 'selectedWithoutConfirmedIds', []);
    if (get(data, 'addToConfirm', false)) {
      addToConfirm({
        ...get(data, 'sample'),
        itemsInCase,
        caseItemIds: selectedWithoutConfirmedIds,
      });
      enqueueSnackbar(
        `Kufr ${get(data, 'sample.reference')} (ID: ${get(
          data,
          'sample.code',
        )}) přidán k potvrzení `,
        {
          variant: 'success',
        },
      );
      setOpen({ open: false });
    } else {
      if (get(analyze, 'warnings', []).length > 0) {
        setCaseConfirmNewLocationDialog({
          open: true,
          sample: get(data, 'sample'),
          selection,
          itemsInCase,
          confirmedItems,
          submitCase,
          analyze,
        });
      } else {
        setLoader();
        await submitCase(itemCase, itemsInCase, selectedWithoutConfirmedIds);
        setOpen({ open: false });
        setLoader(false);
      }
    }
  };

  const loadItemsInCase = async (id: number) => {
    setLoader();
    const resp = await getItemsInCase(id);
    setLoader(false);
    if (resp) {
      setItemsInCase(resp.map((item: any) => sampleConvert(item)));
    } else {
    }
  };

  useEffect(() => {
    if (get(data, 'sample.id')) {
      loadItemsInCase(get(data, 'sample.id'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const SelectionCol = ({ row, ...restProps }: any) => {
    return includes(confirmedItems, get(row, 'id', false)) ? (
      <Table2.Cell {...restProps} />
    ) : (
      <TableSelection.Cell row={row} {...restProps} />
    );
  };

  return (
    <>
      <Dialog fullWidth={true} maxWidth="xl" open={get(data, 'open', false)} onClose={handleClose}>
        <DialogTitle>Potvrzení kufru při inventuře</DialogTitle>
        <DialogContent>
          <Grid container={true} spacing={2}>
            <Grid item xs={12}>
              <TableContainer>
                <Table aria-label="simple table" size="small">
                  <TableBody>
                    {fields.map((field: any) => (
                      <TableRow key={field.name} sx={{ border: 0 }}>
                        <TableCell component="th" scope="row">
                          <b>{field.label}</b>
                        </TableCell>
                        <TableCell align="left">
                          {typeof get(field, 'getValue', '') === 'function'
                            ? field.getValue(get(data, 'sample'))
                            : get(get(data, 'sample'), field.name, '')}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Paper sx={{ mt: '15px' }}>
            <SamplesResults
              columns={columnsSample}
              samples={itemsInCase}
              canSelect={true}
              selection={selection}
              setSelection={setSelection}
              SelectionCol2={SelectionCol}
            />
          </Paper>
          {get(analyze, 'errors', []).map((item: any) => (
            <div style={{ color: 'red' }}>{`${item.message}`}</div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button color="secondary" variant="contained" onClick={handleClose}>
            Zavřít
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              onSubmit();
            }}
            disabled={
              get(analyze, 'errors', []).length > 0 ||
              get(analyze, 'selectedWithoutConfirmedIds', [0]).length === 0
            }
          >
            Potvrdit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CaseConfirmDialog;
