import { ADD_LENDINGS, SAVE_LENDINGS_VALUES } from 'utils/constants';

export const addLendings = (lendings: any) => ({
  type: ADD_LENDINGS,
  payload: lendings,
});

export const saveLendigsValues = (values: any) => ({
  type: SAVE_LENDINGS_VALUES,
  payload: values,
});
