import { IS_LOADING, SET_NOTIFICATION } from 'utils/constants';

export const ToggleLoader = (isLoading = true) => ({
  type: IS_LOADING,
  payload: isLoading,
});

export const SetNotification = (open: any, variant: any, message: any) => ({
  type: SET_NOTIFICATION,
  payload: { open, variant, message },
});
