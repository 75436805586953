import { api } from 'utils/hooks/useApi';
import { get } from 'lodash';
import { processError } from 'utils/utils';

export const startStockTakingApi = (departmentId: Number): Promise<any> =>
  api()
    .get(`/pae-api/stock-taking/start?departmentId=${departmentId}`)
    .then((response: any) => ({ status: true, data: get(response, 'data') }))
    .catch((error) => {
      return processError(error);
    });

export const finishStockTakingApi = (stockTakingId: Number): Promise<any> =>
  api()
    .get(`/pae-api/stock-taking/finish?stockTakingId=${stockTakingId}`)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });

export const getAll = (): Promise<any> =>
  api()
    .get(`/pae-api/stock-taking/getAll`)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });

export const getById = (stockTakingId: Number): Promise<any> =>
  api()
    .get(`/pae-api/stock-taking/get?id=${stockTakingId}`)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });

export const confirm = (confirmReq: any): Promise<any> =>
  api()
    .post(`/pae-api/stock-taking/confirmItem`, confirmReq)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });

export const confirmMultipleItems = (confirmReq: any): Promise<any> =>
  api()
    .post(`/pae-api/stock-taking/confirmMultipleItems`, confirmReq)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });

export const confirmMultipleCases = (confirmReq: any): Promise<any> =>
  api()
    .post(`/pae-api/stock-taking/confirmMultipleCases`, confirmReq)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });
