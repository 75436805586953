import { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Button, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { find, get, filter } from 'lodash';

import useLoader from 'utils/hooks/useLoader';
import InputForm from 'components/Form/Input/Input';
import SwitchForm from 'components/Form/Switch/Switch';
import AutocompleteForm from 'components/Form/Autocomplete/Autocomplete';
import { getWorkplaces } from 'modules/Settings/Workplaces/_api';
import { getDepartments } from '../Departments/_api';
import AutocompleteMultipleForm from 'components/Form/Autocomplete/AutocompleteMultiple';

const UserForm: React.FC<any> = ({ user, edit, onSubmit }) => {
  const methods = useForm();
  const { handleSubmit, reset, getValues, watch, setValue } = methods;
  const [workplaces, setWorkplaces] = useState<any>([]);
  const [departments, setDepartments] = useState<any>([]);
  const setLoader = useLoader();
  const allowedWorkplaces = useSelector((state: any) =>
    get(state, 'app.user.allowedWorkplaces', []),
  );
  const allowedDepartments = useSelector((state: any) => get(state, 'app.user.departments', []));

  const workplaceForm = watch('workplace');
  const isHost = get(workplaceForm, 'id') === 4;

  const loadEntities = async () => {
    setLoader();
    const [workplaces, departments] = await Promise.all([getWorkplaces(), getDepartments()]);
    if (workplaces) {
      setWorkplaces(workplaces);
    } else {
      setWorkplaces(false);
    }
    if (departments) {
      setDepartments(departments);
    } else {
      setDepartments(false);
    }
    setLoader(false);
  };

  useEffect(() => {
    if (user && edit) {
      reset({
        firstName: get(user, 'firstName', '') || '',
        lastName: get(user, 'lastName', '') || '',
        username: get(user, 'username', '') || '',
        email: get(user, 'email', '') || '',
        enabled: get(user, 'enabled', false) || false,
        workplace: get(user, 'workplaces[0]'),
        departments:
          get(user, 'workplaces[0].id') === 1
            ? get(user, 'departments')
            : get(user, 'departments[0]'),
      });
    } else {
      reset({ enabled: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, edit]);

  useEffect(() => {
    // při výběru admina zaškrtnout všechna oddělení
    if (get(user, 'workplaces[0].id') === get(workplaceForm, 'id')) {
      if (get(workplaceForm, 'id') === 1) {
        setValue('departments', get(user, 'departments'));
      } else {
        setValue('departments', get(user, 'departments[0]'));
      }
    } else {
      setValue('departments', null);
      if (get(workplaceForm, 'id') === 1) {
        setValue(
          'departments',
          departments.map((department: any) => ({ id: department.id, label: department.name })),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workplaceForm]);

  useEffect(() => {
    loadEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container={true} spacing={2}>
          <Grid item xs={6} md={3}>
            <InputForm name="firstName" label="Jméno" rules={{ required: 'Vyžadováno' }} />
          </Grid>
          <Grid item xs={6} md={3}>
            <InputForm name="lastName" label="Příjmení" rules={{ required: 'Vyžadováno' }} />
          </Grid>
          <Grid item xs={12}>
            <Grid container={true} spacing={2}>
              <Grid item xs={6} md={3}>
                <InputForm
                  name="username"
                  label="Uživatelské jméno"
                  rules={{ required: 'Vyžadováno' }}
                  disabled={edit}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <InputForm
                  name="email"
                  label="Email"
                  rules={{
                    required: 'Vyžadováno',
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: 'Špatný formát emailu',
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container={true} spacing={2}>
              <Grid item xs={6} md={3}>
                <AutocompleteForm
                  name="workplace"
                  label="Pracovní místo"
                  options={workplaces.map((item: any) => ({
                    id: item.id,
                    label: item.name || '',
                    disabled: !find(
                      [...allowedWorkplaces, { id: 4, code: 'Guest', name: 'Host' }],
                      { id: item.id },
                    ),
                  }))}
                  rules={{ required: 'Vyžadováno' }}
                />
              </Grid>
              {!isHost && (
                <Grid item xs={6} md={3}>
                  {get(workplaceForm, 'id') === 1 ? ( //pokud je admin, může mít více oddělení
                    <AutocompleteMultipleForm
                      name="departments"
                      label="Oddělení (aktivní)"
                      options={filter(departments, { active: true })
                        .sort((a: any, b: any) => (a.name || '').localeCompare(b.name || ''))
                        .map((item: any) => ({
                          id: item.id,
                          label: `${item.name}`,
                        }))}
                      disableClearable={false}
                      multiple={true}
                      rules={{ required: 'Vyžadováno' }}
                    />
                  ) : (
                    <AutocompleteForm
                      name="departments"
                      label="Oddělení (aktivní)"
                      options={filter(allowedDepartments, { active: true })
                        .sort((a: any, b: any) => (a.name || '').localeCompare(b.name || ''))
                        .map((item: any) => ({
                          id: item.id,
                          label: `${item.name}`,
                        }))}
                      rules={{ required: 'Vyžadováno' }}
                    />
                  )}
                </Grid>
              )}
              <Grid item xs={12}>
                <SwitchForm name="enabled" label="Účet aktivován" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} md={3}>
            <InputForm
              name="password"
              label="Heslo"
              type="password"
              rules={{
                required: edit ? undefined : 'Vyžadováno',
                validate: (value: any) => {
                  if (getValues().password || getValues().password2) {
                    return getValues().password === getValues().password2
                      ? true
                      : 'Hesla nejsou stejná';
                  } else {
                    return true;
                  }
                },
              }}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <InputForm name="password2" label="Heslo znovu" type="password" />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="secondary"
              component={Link}
              to={'/settings?active=users'}
              sx={{ mr: '10px' }}
            >
              Zpět
            </Button>
            <Button variant="contained" color="primary" type="submit">
              Uložit
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default UserForm;
