import { useState, useEffect } from 'react';
import { Typography, Box, Tabs, Tab, Paper, Button, Tooltip, IconButton } from '@mui/material';
import { RowDetailState, SortingState, IntegratedSorting } from '@devexpress/dx-react-grid';
import {
  Grid as GridTable,
  Table,
  TableHeaderRow,
  TableRowDetail,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import { useSelector } from 'react-redux';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { get, find, includes, forEach } from 'lodash';
import { useDropzone } from 'react-dropzone';
import ExcelJS from 'exceljs';

import useLoader from 'utils/hooks/useLoader';
import { getCustomerCompanies, /*deleteCustomer,*/ deleteCustomerPerson } from './Customers/_api';
import { getDepartments } from './Departments/_api';
import { getLocations } from './Locations/_api';
import { getDealers } from './Dealers/_api';
import { deleteUser, getUsers } from './Users/_api';
import { getWorkplaces } from './Workplaces/_api';
import ConfirmationDialog from 'components/ConfirmDialog/ConfirmDialog';
import useGridUtils from 'utils/hooks/useGridUtils';
import useRoleValidation from 'utils/hooks/useRoleValidation';
import BarCodeCreate from './BarCodes/BarCodeCreate';
import { addItemsToCase, multipleCreateSample } from 'modules/Samples/_api';
import { SAMPLE_STATES } from 'utils/constants';
import useUserInfo from 'utils/hooks/useUserInfo';

const Settings: React.FC<any> = () => {
  const navigate = useNavigate();
  const setLoader = useLoader();
  const { compareName } = useGridUtils();
  const { enqueueSnackbar } = useSnackbar();
  const { userIsAdmin } = useUserInfo();
  const [value, setValue] = useState('users');
  const [value2, setValue2] = useState(true);
  const [items, setItems] = useState([]);
  const [columns, setColumns] = useState<any>([]);
  const [activeLocations, setActiveLocations] = useState<any>([]);
  const [activeDepartments, setActiveDepartments] = useState<any>([]);
  const [expandedRowIds, setExpandedRowIds] = useState<any>([]);
  const [deleteDialog, setDeleteDialog] = useState<any>({
    open: false,
    snackBarText: { ok: '', error: '' },
  });
  const [sorting, setSorting] = useState<any>([]);
  const [importError, setImportError] = useState<any>('');
  const [integratedSortingColumnExtensions] = useState([
    { columnName: 'name', compare: compareName },
  ]);
  const [integratedSortingColumnExtensions2] = useState([
    { columnName: 'lastName', compare: compareName },
  ]);
  const { checkRole, checkRoleLabel } = useRoleValidation();
  const [searchParams] = useSearchParams();
  const active = searchParams.get('active');
  const allowedWorkplaces = useSelector((state: any) =>
    get(state, 'app.user.allowedWorkplaces', []),
  );
  const userWorkplace = useSelector((state: any) => get(state, 'app.user.workplaces[0].level', ''));
  const userDepartments = useSelector((state: any) => get(state, 'app.user.departments', []));
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const [importedSamples, setImportedSamples] = useState<any>([]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setItems([]);
    setColumns([]);
    setValue(newValue);
    setValue2(true);
    setImportError('');
    setImportedSamples([]);
    if (newValue === 'locations') {
      loadLocations();
    }
    if (newValue === 'departments') {
      loadDepartments();
    }
    if (newValue === 'dealers') {
      loadDealers();
    }
    if (newValue === 'users') {
      loadUsers();
    }
    if (newValue === 'customers') {
      loadCustomers();
    }
    if (newValue === 'workplaces') {
      loadWorkplaces();
    }
  };

  const handleChange2 = (event: React.SyntheticEvent, newValue2: boolean) => {
    setItems([]);

    setColumns([]);
    setValue2(newValue2);
    if (value === 'locations') {
      loadLocations(newValue2);
    }
    if (value === 'departments') {
      loadDepartments(newValue2);
    }
    if (value === 'dealers') {
      loadDealers(newValue2);
    }
    if (value === 'users') {
      loadUsers(newValue2);
    }
    if (value === 'customers') {
      loadCustomers(newValue2);
    }
  };

  const a11yProps = (index: string) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };
  const a11yProps2 = (index: boolean) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const loadUsers = async (active: boolean = true) => {
    setLoader();
    const resp = await getUsers(active);
    if (resp) {
      setItems(resp);
      setColumns(colsUsers);
      setSorting([{ columnName: 'lastName', direction: 'asc' }]);
    } else {
      setItems([]);
      enqueueSnackbar('Nepodařil se načíst seznam uživatelů.', { variant: 'error' });
    }
    setLoader(false);
  };

  const loadDealers = async (active: boolean = true) => {
    setLoader();
    const resp = await getDealers(active);
    if (resp) {
      setItems(resp);
      setColumns(colsDealers);
      setSorting([{ columnName: 'lastName', direction: 'asc' }]);
    } else {
      setItems([]);
      enqueueSnackbar('Nepodařil se načíst seznam obchodníků.', { variant: 'error' });
    }
    setLoader(false);
  };

  const loadLocations = async (active = true) => {
    setLoader();
    const resp = await getLocations(active);
    if (resp) {
      setItems(resp);
      setColumns(colsLocations);
      setSorting([{ columnName: 'name', direction: 'asc' }]);
    } else {
      setItems([]);
      enqueueSnackbar('Nepodařily se načíst umístění.', { variant: 'error' });
    }
    setLoader(false);
  };

  const loadDepartments = async (active = true) => {
    setLoader();
    const resp = await getDepartments(active);
    if (resp) {
      setItems(resp);
      setColumns(colsDepartments);
      setSorting([{ columnName: 'name', direction: 'asc' }]);
    } else {
      setItems([]);
      enqueueSnackbar('Nepodařila se načíst oddělení.', { variant: 'error' });
    }
    setLoader(false);
  };

  const loadCustomers = async (active = true) => {
    setLoader();
    const resp = await getCustomerCompanies(active);
    setColumns(colsCustomers);
    setSorting([{ columnName: 'name', direction: 'asc' }]);
    if (resp) {
      setItems(resp);
    } else {
      setItems([]);
      enqueueSnackbar('Nepodařilo se načíst zákazníky.', { variant: 'error' });
    }
    setLoader(false);
  };

  const loadWorkplaces = async (active = true) => {
    setLoader();
    const resp = await getWorkplaces();
    if (resp) {
      setItems(resp);
      setColumns(colsWorkplaces);
    } else {
      setItems([]);
      enqueueSnackbar('Nepodařilo se načíst pracovní místa.', { variant: 'error' });
    }
    setLoader(false);
  };

  const colsUsers = [
    { name: 'lastName', title: 'Příjmení' },
    { name: 'firstName', title: 'Jméno' },
    { name: 'username', title: 'Uživatelské jméno' },
    { name: 'email', title: 'Email' },
    { name: 'enabled', title: 'Aktivní', getCellValue: (row: any) => (row.enabled ? 'Ano' : 'Ne') },
    {
      name: 'workplaces',
      title: 'Pracovní místo',
      getCellValue: (row: any) => get(row, 'workplaces[0].name'),
    },
    {
      name: 'departments',
      title: 'Oddělení',
      getCellValue: (row: any) =>
        get(row, 'departments', [])
          .map((item: any) => item.name)
          .join(', '),
    },
    {
      name: 'actions',
      title: 'Akce',
      getCellValue: (row: any) => {
        const canEditByWorkplace =
          !get(row, 'workplaces[0].id') ||
          !!find([...allowedWorkplaces, { id: 4, code: 'Guest', name: 'Host' }], {
            id: get(row, 'workplaces[0].id'),
          });

        const canEditByDepartments =
          userWorkplace === 'TOP_ADMIN' ||
          get(row, 'workplaces[0].level') === 'GUEST' ||
          get(row, 'departments', []).some((item: any) =>
            includes(
              userDepartments.map((dep: any) => dep.id),
              item.id,
            ),
          );

        return (
          <>
            <Tooltip title={`Upravit uživatele ${checkRoleLabel('ROLE_USER_UPDATE')}`}>
              <span>
                <IconButton
                  color="primary"
                  sx={{ p: '5px' }}
                  onClick={() => {
                    navigate(`/settings/users/${row.id}/edit`);
                  }}
                  disabled={
                    !(canEditByWorkplace && canEditByDepartments && checkRole('ROLE_USER_UPDATE'))
                  }
                >
                  <EditIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={`Smazat uživatele ${checkRoleLabel('ROLE_USER_DELETE')}`}>
              <span>
                <IconButton
                  color="primary"
                  sx={{ p: '5px' }}
                  onClick={() => {
                    setDeleteDialog({
                      open: true,
                      id: row.id,
                      title: 'Odstranění uživatele',
                      text: `Opravdu chcete odstranit uživatele - ${row.lastName} ${row.firstName}?`,
                      snackBarText: {
                        ok: 'Smazání uživatele proběhlo úspěšně',
                        error: 'Uživatele se nepodařilo smazat',
                      },
                      deleteFnc: deleteUser,
                      loadEntities: loadUsers,
                    });
                  }}
                  disabled={
                    !(canEditByWorkplace && canEditByDepartments && checkRole('ROLE_USER_DELETE'))
                  }
                >
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const colsDealers = [
    { name: 'lastName', title: 'Příjmení' },
    { name: 'firstName', title: 'Jméno' },
    { name: 'email', title: 'Email' },
    { name: 'phone', title: 'Telefon' },
    { name: 'description', title: 'Poznámka' },
    {
      name: 'actions',
      title: 'Akce',
      getCellValue: (row: any) => (
        <Tooltip title={`Upravit obchodníka ${checkRoleLabel('ROLE_DEALER_UPDATE')}`}>
          <span>
            <IconButton
              color="primary"
              sx={{ p: '5px' }}
              onClick={() => {
                navigate(`/settings/dealers/${row.id}/edit`);
              }}
              disabled={!checkRole('ROLE_DEALER_UPDATE')}
            >
              <EditIcon />
            </IconButton>
          </span>
        </Tooltip>
      ),
    },
  ];

  const colsLocations = [
    //{ name: 'id', title: 'ID' },
    { name: 'name', title: 'Název' },
    { name: 'address', title: 'Adresa' },
    {
      name: 'active',
      title: 'Aktivní',
      getCellValue: (row: any) => (get(row, 'active') ? 'Ano' : 'Ne'),
    },
    {
      name: 'actions',
      title: 'Akce',
      getCellValue: (row: any) => (
        <>
          <Tooltip title={`Upravit umístění ${checkRoleLabel('ROLE_LOCATION_UPDATE')}`}>
            <span>
              <IconButton
                color="primary"
                sx={{ p: '5px' }}
                onClick={() => {
                  navigate(`/settings/locations/${row.id}/edit`);
                }}
                disabled={!checkRole('ROLE_LOCATION_UPDATE')}
              >
                <EditIcon />
              </IconButton>
            </span>
          </Tooltip>
          {/*<Tooltip title="Smazat umístění">
            <IconButton
              color="primary"
              sx={{ p: '5px' }}
              onClick={() => {
                setDeleteDialog({
                  open: true,
                  id: row.id,
                  title: 'Odstranění umístění',
                  text: `Opravdu chcete odstranit umístění - ${row.name}?`,
                  snackBarText: {
                    ok: 'Smazání umístění proběhlo úspěšně',
                    error: 'Umístění se nepodařilo smazat',
                  },
                  deleteFnc: deleteLocation,
                  loadEntities: loadLocations,
                });
              }}
              disabled={!checkRole('ROLE_LOCATION_DELETE')}
            >
              <DeleteIcon />
            </IconButton>
            </Tooltip>*/}
        </>
      ),
    },
  ];

  const colsDepartments = [
    // { name: 'id', title: 'ID' },
    { name: 'name', title: 'Název' },
    { name: 'codePrefix', title: 'Prefix čárových kódů' },
    {
      name: 'active',
      title: 'Aktivní',
      getCellValue: (row: any) => (get(row, 'active') ? 'Ano' : 'Ne'),
    },
    {
      name: 'actions',
      title: 'Akce',
      getCellValue: (row: any) => (
        <>
          <Tooltip title={`Upravit oddělení ${checkRoleLabel('ROLE_DEPARTMENT_UPDATE')}`}>
            <span>
              <IconButton
                color="primary"
                sx={{ p: '5px' }}
                onClick={() => {
                  navigate(`/settings/departments/${row.id}/edit`);
                }}
                disabled={!checkRole('ROLE_DEPARTMENT_UPDATE')}
              >
                <EditIcon />
              </IconButton>
            </span>
          </Tooltip>
          {/*<Tooltip title="Smazat oddělení">
            <IconButton
              color="primary"
              sx={{ p: '5px' }}
              onClick={() => {
                setDeleteDialog({
                  open: true,
                  id: row.id,
                  title: 'Odstranění oddělení',
                  text: `Opravdu chcete odstranit oddělení - ${row.name}?`,
                  snackBarText: {
                    ok: 'Smazání oddělení proběhlo úspěšně',
                    error: 'Oddělení se nepodařilo smazat',
                  },
                  deleteFnc: deleteDepartment,
                  loadEntities: loadDepartments,
                });
              }}
              disabled={!checkRole('ROLE_LOCATION_DELETE')}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>*/}
        </>
      ),
    },
  ];

  const colsCustomers = [
    { name: 'name', title: 'Název' },
    {
      name: 'address',
      title: 'Adresa',
    },
    {
      name: 'active',
      title: 'Aktivní',
      getCellValue: (row: any) => (get(row, 'active') ? 'Ano' : 'Ne'),
    },
    {
      name: 'actions',
      title: 'Akce',
      getCellValue: (row: any) => (
        <>
          <Tooltip
            title={`Přidat kontaktní osobu ${checkRoleLabel('ROLE_CUSTOMER_PERSON_CREATE')}`}
          >
            <span>
              <IconButton
                color="primary"
                sx={{ p: '5px' }}
                size="small"
                onClick={() => {
                  navigate(`/settings/customers/${row.id}/person/create`);
                }}
                disabled={!checkRole('ROLE_CUSTOMER_PERSON_CREATE') || !row.active}
              >
                <AddCircleIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={`Upravit zákazníka ${checkRoleLabel('ROLE_CUSTOMER_COMPANY_UPDATE')}`}>
            <span>
              <IconButton
                color="primary"
                sx={{ p: '5px' }}
                onClick={() => {
                  navigate(`/settings/customers/${row.id}/edit`);
                }}
                disabled={!checkRole('ROLE_CUSTOMER_COMPANY_UPDATE')}
              >
                <EditIcon />
              </IconButton>
            </span>
          </Tooltip>
          {/*<Tooltip title={`Smazat zákazníka ${checkRoleLabel('ROLE_CUSTOMER_COMPANY_DELETE')}`}>
            <span>
              <IconButton
                color="primary"
                sx={{ p: '5px' }}
                onClick={() => {
                  setDeleteDialog({
                    open: true,
                    id: row.id,
                    title: 'Odstranění zákazníka',
                    text: `Opravdu chcete odstranit zákazníka - ${row.name}?`,
                    snackBarText: {
                      ok: 'Smazání zákazníka proběhlo úspěšně',
                      error: 'Zákazníka se nepodařilo smazat',
                    },
                    deleteFnc: deleteCustomer,
                    loadEntities: loadCustomers,
                  });
                }}
                disabled={!checkRole('ROLE_CUSTOMER_COMPANY_DELETE')}
              >
                <DeleteIcon />
              </IconButton>
            </span>
              </Tooltip>*/}
        </>
      ),
    },
  ];

  const colsWorkplaces = [
    { name: 'name', title: 'Název' },
    {
      name: 'code',
      title: 'Kód',
    },
    {
      name: 'actions',
      title: 'Akce',
      getCellValue: (row: any) => (
        <>
          <Tooltip title={`Upravit pracovní místo ${checkRoleLabel('ROLE_WORK_PLACE_UPDATE')}`}>
            <span>
              <IconButton
                color="primary"
                sx={{ p: '5px' }}
                onClick={() => {
                  navigate(`/settings/workplaces/${row.id}/edit`);
                }}
                disabled={!checkRole('ROLE_WORK_PLACE_UPDATE')}
              >
                <EditIcon />
              </IconButton>
            </span>
          </Tooltip>
          {/*<Tooltip title={`Upravit pracovní místo ${
              checkRole('ROLE_WORK_PLACE_UPDATE') ? '' : '(nemáte právo)'
            }`}>
            <IconButton
              color="primary"
              sx={{ p: '5px' }}
              onClick={() => {
                setDeleteDialog({
                  open: true,
                  id: row.id,
                  title: 'Odstranění pracovního místa',
                  text: `Opravdu chcete odstranit pracovní místo - ${row.name}?`,
                  snackBarText: {
                    ok: 'Smazání pracovního místa proběhlo úspěšně',
                    error: 'Pracovní místo se nepodařilo smazat',
                  },
                  deleteFnc: deleteWorkplace,
                  loadEntities: loadWorkplaces,
                });
              }}
              disabled={!checkRole('ROLE_WORK_PLACE_DELETE')}
            >
              <DeleteIcon />
            </IconButton>
            </Tooltip>*/}
        </>
      ),
    },
  ];

  const colsPersons = [
    { name: 'firstName', title: 'Jméno' },
    { name: 'lastName', title: 'Příjmení' },
    {
      name: 'phone',
      title: 'Telefon',
    },
    {
      name: 'email',
      title: 'Email',
    },
    {
      name: 'actions',
      title: 'Akce',
      getCellValue: (row: any) => (
        <>
          {' '}
          <Tooltip
            title={`Upravit kontaktní osobu ${checkRoleLabel('ROLE_CUSTOMER_PERSON_UPDATE')}`}
          >
            <span>
              <IconButton
                color="primary"
                sx={{ p: '5px' }}
                onClick={() => {
                  navigate(`/settings/customers/${row.companyId}/person/${row.id}/edit`);
                }}
                disabled={!checkRole('ROLE_CUSTOMER_PERSON_UPDATE')}
              >
                <EditIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip
            title={`Smazat kontaktní osobu ${checkRoleLabel('ROLE_CUSTOMER_PERSON_DELETE')}`}
          >
            <span>
              <IconButton
                color="primary"
                sx={{ p: '5px' }}
                onClick={() => {
                  setDeleteDialog({
                    open: true,
                    id: row.id,
                    title: 'Odstranění kontaktní osoby',
                    text: `Opravdu chcete odstranit kontaktní osobu - ${row.firstName} ${row.lastName} ?`,
                    snackBarText: {
                      ok: 'Smazání kontaktní osoby proběhlo úspěšně',
                      error: 'Kontaktní osobu se nepodařilo smazat',
                    },
                    deleteFnc: deleteCustomerPerson,
                    loadEntities: loadCustomers,
                  });
                }}
                disabled={!checkRole('ROLE_CUSTOMER_PERSON_DELETE')}
              >
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>
        </>
      ),
    },
  ];

  const TableCell = (props: any) => (
    <Table.Cell {...props} style={{ paddingTop: 5, paddingBottom: 5 }} />
  );

  const RowDetail = ({ row }: any) => {
    return (
      <GridTable
        rows={get(find(items, { id: row.id }), 'contactPersons', [])}
        columns={colsPersons || []}
      >
        <Table cellComponent={TableCell} /> <TableHeaderRow />
      </GridTable>
    );
  };
  const workbook = new ExcelJS.Workbook();

  const getInternalUsageId = (value: string) => {
    if (value.includes('PAU')) {
      return 2;
    }
    if (value.includes('ŠBU')) {
      return 3;
    }
    if (value.includes('MDO')) {
      return 4;
    }
    if (value.includes('JHA')) {
      return 5;
    }
    if (value.includes('PKO')) {
      return 6;
    }
    if (value.includes('MPA')) {
      return 7;
    }
    if (value.includes('JPI')) {
      return 8;
    }
    if (value.includes('VVA')) {
      return 10;
    }
    if (value.includes('RVA')) {
      return 11;
    }
    if (value.includes('MVE')) {
      return 12;
    }
    if (value.includes('SVE')) {
      return 13;
    }
    if (value.includes('JBA')) {
      return 15;
    }
    if (value.includes('MKŘ')) {
      return 16;
    }
    if (value.includes('MMA')) {
      return 17;
    }
    return '';
  };

  const loadFile = async (file: any) => {
    try {
      setLoader();
      setImportedSamples([]);
      await workbook.xlsx.load(file);
      //const worksheet = workbook.getWorksheet(1);
      const worksheet = workbook.getWorksheet('List1');
      const rows: any = [];
      let i = 0;
      let xlsRows = 0;
      worksheet.eachRow(
        {
          includeEmpty: false,
        },
        (row, rowNumber) => {
          xlsRows++;
          const state = (get(row, 'values[3]') || '').toLowerCase();
          if (
            state === 'k dispozici' ||
            state === 'prodán' ||
            state === 'nekompletní' ||
            state === 'zapůjčeno' ||
            state.startsWith('intern') ||
            state.startsWith('součást')
          ) {
            rows[i++] = {
              id: i,
              code: get(row, 'values[1]'),
              reference: get(row, 'values[2]'),
              state: find(SAMPLE_STATES, (st) => {
                const label = state.startsWith('intern')
                  ? 'Interní použití'
                  : state.startsWith('součást')
                  ? 'K dispozici'
                  : state;
                return st.label.toLowerCase() === label.toLowerCase();
              }),
              description: get(row, 'values[4]'),
              internalNote: get(row, 'values[5]'),
              location: find(activeLocations, { name: get(row, 'values[6]') }),
              department: find(activeDepartments, { name: get(row, 'values[7]') }),
              discountGroup: get(row, 'values[8]') || ' ',
              activity: get(row, 'values[9]') || ' ',
              commercializationStatus: get(row, 'values[10]') || ' ',
              case: get(row, 'values[11]'),
              caseId: get(row, 'values[12]'),
              internalUsage: state.startsWith('intern') ? getInternalUsageId(state.toString()) : '',
            };
          }
        },
      );
      setLoader(false);
      if (xlsRows - 1 === rows.length) {
        setImportedSamples([...rows]);
      } else {
        enqueueSnackbar('Některý vzorek v xlsx není správně zapsán', { variant: 'error' });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const importXlsxSamples = async () => {
    setImportError('');
    setLoader();
    const itemsToSend = importedSamples.map((sample: any) => ({
      ...sample,
      locationId: get(sample, 'location.id'),
      departmentId: get(sample, 'department.id'),
      location: undefined,
      department: undefined,
      canLent: true,
      state: get(sample, 'state.id'),
      userId:
        get(sample, 'state.id') === 'INTERNAL_USAGE' ? get(sample, 'internalUsage') : undefined,
      case: get(sample, 'case') === 'ANO' ? true : false,
      id: undefined,
      caseId: undefined,
      internalUsage: undefined,
    }));
    const resp = await multipleCreateSample(itemsToSend, true);
    const imported = get(resp, 'data', []);
    if (get(resp, 'status')) {
      enqueueSnackbar(`Import se povedl, počet nových vzorků: ${get(resp, 'data', []).length}`, {
        variant: 'success',
      });
      const cases: any = [];
      forEach(importedSamples, (sample: any) => {
        if (get(sample, 'caseId')) {
          const findCase = find(cases, { code: get(sample, 'caseId') });
          if (findCase) {
            findCase.samples.push(
              get(find(imported, { code: get(sample, 'code').toString() }), 'id'),
            );
          } else {
            cases.push({
              code: get(sample, 'caseId'),
              id: get(find(imported, { code: get(sample, 'caseId') }), 'id'),
              samples: [get(find(imported, { code: get(sample, 'code').toString() }), 'id')],
            });
          }
        }
      });
      forEach(cases, async (item: any) => {
        await addItemsToCase({
          caseId: item.id,
          itemIds: item.samples,
        });
      });
      setImportedSamples([]);
    } else {
      setImportError(JSON.stringify(resp?.errorMessage));
      enqueueSnackbar('Import se nepodařilo provést', { variant: 'error' });
    }
    setLoader(false);
  };

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      loadFile(acceptedFiles[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);

  const initialLoad = async () => {
    const resp1 = await getLocations();
    setActiveLocations(resp1);
    const resp2 = await getDepartments();
    setActiveDepartments(resp2);
  };

  useEffect(() => {
    if (active) {
      setValue(active);
    }
    if (active === 'locations') {
      loadLocations();
    } else if (active === 'dealers') {
      loadDealers();
    } else if (active === 'departments') {
      loadDepartments();
    } else if (active === 'users') {
      loadUsers();
    } else if (active === 'customers') {
      loadCustomers();
    } else if (active === 'workplaces') {
      loadWorkplaces();
    } else if (active === 'bar-codes') {
    } else if (active === 'import-items') {
    } else {
      loadUsers();
    }
    setImportedSamples([]);
    initialLoad();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Typography variant="h6" gutterBottom component="div">
        Nastavení
      </Typography>

      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="settings-tabs">
            <Tab
              label="Uživatelé"
              value="users"
              {...a11yProps('users')}
              disabled={!checkRole('ROLE_USER_VIEW')}
            />
            <Tab
              label="Obchodníci"
              value="dealers"
              {...a11yProps('dealers')}
              disabled={!checkRole('ROLE_DEALER_VIEW')}
            />
            <Tab
              label="Umístění"
              value="locations"
              {...a11yProps('locations')}
              disabled={!checkRole('ROLE_LOCATION_VIEW')}
            />
            <Tab
              label="Oddělení"
              value="departments"
              {...a11yProps('departments')}
              disabled={!checkRole('ROLE_DEPARTMENT_VIEW')}
            />
            <Tab
              label="Zákazníci"
              value="customers"
              {...a11yProps('customers')}
              disabled={!checkRole('ROLE_CUSTOMER_PERSON_VIEW')}
            />

            {checkRole('ROLE_WORK_PLACE_VIEW') && (
              <Tab
                label="Pracovní místa"
                value="workplaces"
                {...a11yProps('workplaces')}
                disabled={!userIsAdmin}
              />
            )}
            <Tab
              label="Štítky"
              value="bar-codes"
              {...a11yProps('bar-codes')}
              disabled={!checkRole('ROLE_BAR_CODE_REGENERATE')}
            />
            {checkRole('ROLE_WORK_PLACE_VIEW') && (
              <Tab
                label="Import vzorků"
                value="import-items"
                {...a11yProps('import-items')}
                // disabled={!checkRole('ROLE_CUSTOMER_PERSON_VIEW')}
                disabled={false}
              />
            )}
          </Tabs>
        </Box>
        <Box>
          {value === 'users' && (
            <Tooltip title={`Přidat uživatele ${checkRoleLabel('ROLE_USER_CREATE')}`}>
              <span>
                <Button
                  variant="contained"
                  startIcon={<AddCircleIcon />}
                  size="small"
                  sx={{ m: '10px' }}
                  component={Link}
                  to={`/settings/users/create`}
                  disabled={!checkRole('ROLE_USER_CREATE')}
                >
                  Přidat uživatele
                </Button>
              </span>
            </Tooltip>
          )}
          {value === 'dealers' && (
            <Tooltip title={`Přidat obchodníka ${checkRoleLabel('ROLE_DEALER_CREATE')}`}>
              <span>
                <Button
                  variant="contained"
                  startIcon={<AddCircleIcon />}
                  size="small"
                  sx={{ m: '10px' }}
                  component={Link}
                  to={`/settings/dealers/create`}
                  disabled={!checkRole('ROLE_DEALER_CREATE')}
                >
                  Přidat obchodníka
                </Button>
              </span>
            </Tooltip>
          )}
          {value === 'locations' && (
            <Tooltip title={`Přidat umístění ${checkRoleLabel('ROLE_LOCATION_CREATE')}`}>
              <span>
                <Button
                  variant="contained"
                  startIcon={<AddCircleIcon />}
                  size="small"
                  sx={{ m: '10px' }}
                  component={Link}
                  to={`/settings/locations/create`}
                  disabled={!checkRole('ROLE_LOCATION_CREATE')}
                >
                  Přidat umístění
                </Button>
              </span>
            </Tooltip>
          )}
          {value === 'departments' && (
            <Tooltip title={`Přidat oddělení ${checkRoleLabel('ROLE_DEPARTMENT_CREATE')}`}>
              <span>
                <Button
                  variant="contained"
                  startIcon={<AddCircleIcon />}
                  size="small"
                  sx={{ m: '10px' }}
                  component={Link}
                  to={`/settings/departments/create`}
                  disabled={!checkRole('ROLE_DEPARTMENT_CREATE')}
                >
                  Přidat oddělení
                </Button>
              </span>
            </Tooltip>
          )}
          {value === 'customers' && (
            <Tooltip title={`Přidat zákazníka ${checkRoleLabel('ROLE_CUSTOMER_COMPANY_CREATE')}`}>
              <span>
                <Button
                  variant="contained"
                  startIcon={<AddCircleIcon />}
                  size="small"
                  sx={{ m: '10px' }}
                  component={Link}
                  to={`/settings/customers/create`}
                  disabled={!checkRole('ROLE_CUSTOMER_COMPANY_CREATE')}
                >
                  Přidat zákazníka
                </Button>
              </span>
            </Tooltip>
          )}
          {/*value === 'workplaces' && (
            <Button
              variant="contained"
              startIcon={<AddCircleIcon />}
              size="small"
              sx={{ m: '10px' }}
              component={Link}
              to={`/settings/workplaces/create`}
              disabled={!checkRole('ROLE_WORK_PLACE_CREATE')}
            >
              Přidat pracovní místo
            </Button>
          */}
        </Box>
        <Paper key={value}>
          {value === 'customers' ? (
            <>
              <Tabs value={value2} onChange={handleChange2} aria-label="settings-active-tabs">
                <Tab label="Aktivní" value={true} {...a11yProps2(true)} />
                <Tab label="Neaktivní" value={false} {...a11yProps2(false)} />
              </Tabs>
              <GridTable rows={items} columns={columns}>
                <RowDetailState
                  expandedRowIds={expandedRowIds || []}
                  onExpandedRowIdsChange={setExpandedRowIds}
                />
                <SortingState sorting={sorting} onSortingChange={setSorting} />
                <IntegratedSorting columnExtensions={integratedSortingColumnExtensions} />
                <Table cellComponent={TableCell} />
                <TableHeaderRow showSortingControls={true} />
                <TableRowDetail contentComponent={RowDetail} />
              </GridTable>
            </>
          ) : (
            !includes(['bar-codes'], value) && (
              <div>
                {includes(['users', 'dealers', 'locations', 'departments', 'customers'], value) && (
                  <Tabs value={value2} onChange={handleChange2} aria-label="settings-active-tabs">
                    <Tab label="Aktivní" value={true} {...a11yProps2(true)} />
                    <Tab label="Neaktivní" value={false} {...a11yProps2(false)} />
                  </Tabs>
                )}
                <GridTable rows={items || []} columns={columns || []}>
                  <SortingState sorting={sorting} onSortingChange={setSorting} />
                  <IntegratedSorting
                    columnExtensions={
                      value === 'users'
                        ? integratedSortingColumnExtensions2 // sort column lastName
                        : integratedSortingColumnExtensions // sort column name
                    }
                  />
                  <Table
                    cellComponent={TableCell}
                    columnExtensions={
                      value === 'users'
                        ? [{ columnName: 'departments', wordWrapEnabled: true }]
                        : undefined
                    }
                  />
                  <TableHeaderRow showSortingControls={true} />
                </GridTable>
              </div>
            )
          )}
        </Paper>
        {value === 'bar-codes' && <BarCodeCreate />}
        {value === 'import-items' && (
          <div style={{ margin: 10 }}>
            <a href={process.env.PUBLIC_URL + '/import.xlsx'} download={'import.xlsx'}>
              Stáhnout šablonu
            </a>
            <Paper>
              <section className="container" style={{ padding: 20, marginTop: 10 }}>
                <div {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />
                  <p>Vložte xlsx soubor nebo klikněte sem</p>
                </div>
              </section>
            </Paper>
            <GridTable
              rows={importedSamples || []}
              columns={[
                { name: 'reference', title: 'Reference' },
                { name: 'code', title: 'ID' },
                { name: 'state', title: 'Stav', getCellValue: (row) => row.state?.label },
                { name: 'description', title: 'Popis' },
                { name: 'internalNote', title: 'Poznámka' },
                {
                  name: 'location',
                  title: 'Umístění',
                  getCellValue: (row) => row.location?.name,
                },
                {
                  name: 'department',
                  title: 'Oddělení',
                  getCellValue: (row) => row.department?.name,
                },
                {
                  name: 'discountGroup',
                  title: 'Rabatová skupina',
                },
                {
                  name: 'activity',
                  title: 'Aktivita',
                },
                {
                  name: 'commercializationStatus',
                  title: 'Status komercializace',
                },
                {
                  name: 'case',
                  title: 'je vzorek kufr?',
                },
                {
                  name: 'caseId',
                  title: 'ID kufru',
                },
                {
                  name: 'internalUsage',
                  title: 'Interní použití',
                },
              ]}
            >
              <SortingState sorting={sorting} onSortingChange={setSorting} />
              <IntegratedSorting columnExtensions={integratedSortingColumnExtensions} />
              <VirtualTable cellComponent={TableCell} />
              <TableHeaderRow showSortingControls={true} />
            </GridTable>
            <pre>{importError}</pre>
            <div style={{ marginTop: 20 }}>
              <Button
                variant="contained"
                color="primary"
                disabled={importedSamples.length === 0}
                onClick={() => {
                  importXlsxSamples();
                }}
              >
                Importovat
              </Button>
            </div>
          </div>
        )}
      </Box>
      <ConfirmationDialog deleteDialog={deleteDialog} setDeleteDialog={setDeleteDialog} />
    </Box>
  );
};

export default Settings;
