import { api } from 'utils/hooks/useApi';
import { get } from 'lodash';
import { processError } from 'utils/utils';

export const getAllDepartments = (): Promise<any> =>
  api()
    .get(`/pae-api/dictionary/departments/getAll`)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });

export const getDepartments = (active: boolean = true): Promise<any> =>
  api()
    .get(`/pae-api/dictionary/departments/getAll?active=${active}`)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });

export const createDepartment = (values: any): Promise<any> =>
  api()
    .post(`/pae-api/dictionary/departments/create`, values)
    .then((response: any) => ({ status: true, data: get(response, 'data') }))
    .catch((error) => {
      return processError(error);
    });

export const getDepartment = (id: any): Promise<any> =>
  api()
    .get(`/pae-api/dictionary/departments/get?id=${id}`)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });

export const updateDepartment = (values: any): Promise<any> =>
  api()
    .post(`/pae-api/dictionary/departments/update`, values)
    .then((response: any) => ({ status: true, data: get(response, 'data') }))
    .catch((error) => {
      return processError(error);
    });

export const addDepartmentToLocation = (values: any): Promise<any> =>
  api()
    .post(`/pae-api/dictionary/locations/manageDepartmentRelationShip`, { locations: values })
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });

export const deleteDepartment = (id: any): Promise<any> =>
  api()
    .delete(`/pae-api/dictionary/departments/delete?id=${id}`)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });
