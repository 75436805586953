import { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Button, Grid, Paper, Tooltip, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { get, find, includes, filter } from 'lodash';

import InputForm from 'components/Form/Input/Input';
import AutocompleteForm from 'components/Form/Autocomplete/Autocomplete';
import { getDepartments } from 'modules/Settings/Departments/_api';
import { getLocations } from 'modules/Settings/Locations/_api';
import useLoader from 'utils/hooks/useLoader';
import { SAMPLE_STATES } from 'utils/constants';
import SamplesResults from './SamplesResults';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ConfirmationDialog from 'components/ConfirmDialog/ConfirmDialog';
import { getUsers } from 'modules/Settings/Users/_api';
import useSort from 'utils/hooks/useSort';
import useUserInfo from 'utils/hooks/useUserInfo';
import SwitchForm from 'components/Form/Switch/Switch';

const SampleForm: React.FC<any> = ({
  sample,
  edit,
  onSubmit,
  caseItems,
  newCaseItems,
  removeCaseItems = [],
  setOpenAddSampleDialog,
  loadSample,
  removeNewItem,
  removeOldItem,
}) => {
  const methods = useForm();
  const { handleSubmit, reset, watch } = methods;
  const [locations, setLocations] = useState<any>([]);
  const [departments, setDepartments] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);
  const [deleteDialog, setDeleteDialog] = useState<any>({
    open: false,
    snackBarText: { ok: '', error: '' },
  });
  const csSort = useSort();

  const removeCaseItemsIds = removeCaseItems.map((item: any) => item.id);

  const setLoader = useLoader();
  const { userDepartments, userIsAdmin } = useUserInfo();

  const columns = [
    { name: 'reference', title: 'Reference' },
    { name: 'code', title: 'ID' },
    { name: 'description', title: 'Popis' },
    { name: 'stateLabel', title: 'Stav' },
    { name: 'case_note', title: 'Ikony' },
    {
      name: 'location',
      title: 'Umístění',
      getCellValue: (row: any) => get(row, 'location.name', ''),
    },
    {
      name: 'department',
      title: 'Oddělení',
      getCellValue: (row: any) => get(row, 'department.name', ''),
    },
    { name: 'createdDate', title: 'Vytvořeno' },
    {
      name: 'actions',
      title: 'Akce',
      getCellValue: (row: any) => (
        <Tooltip title="Odstranit vzorek z kufru">
          <span>
            <IconButton
              color="primary"
              sx={{ p: '5px' }}
              size="small"
              onClick={() => {
                if (row.new) {
                  removeNewItem(row.id);
                } else {
                  setDeleteDialog({
                    open: true,
                    id: row.id,
                    title: 'Odstranění vzorku z kufru',
                    text: `Opravdu chcete odstranit vzorek (${row.reference}, id: ${row.code}) z kufru?`,
                    snackBarText: {
                      ok: 'Vzorek byl odebrán z kufru, změny se projeví až po uložení celého kufru',
                      error: 'Smazání vzorku z kufru se nepodařilo',
                    },
                    deleteFnc: removeOldItem,
                    values: row,
                    loadEntities: loadSample,
                    apiFnc: false,
                  });
                }
              }}
              disabled={sample.lent}
            >
              <DeleteIcon />
            </IconButton>
          </span>
        </Tooltip>
      ),
    },
  ];

  const cantEditStates = ['IN_CASE', 'LENT'];
  const cantEditState = includes(cantEditStates, get(sample, 'state'));
  const state = watch('state');

  const isCase = watch('case');

  const loadEntities = async () => {
    setLoader();
    const [resp1, resp2, resp3] = await Promise.all([getLocations(), getDepartments(), getUsers()]);
    if (resp1) {
      setLocations(resp1);
    }
    if (resp2) {
      setDepartments(filter(resp2, { active: true }));
    }
    if (resp3) {
      setUsers(
        resp3.map((item: any) => ({
          id: item.id,
          lastName: item.lastName,
          firstName: item.firstName,
        })),
      );
    }
    setLoader(false);
  };

  useEffect(() => {
    if (sample && edit) {
      const user = get(sample, 'user') ? find(users, { id: get(sample, 'user.id') }) : undefined;
      reset({
        reference: get(sample, 'reference', '') || '',
        code: get(sample, 'code', '') || '',
        description: get(sample, 'description', '') || '',
        internalNote: get(sample, 'internalNote', '') || '',
        case: get(sample, 'case', null) ? { id: 2, label: 'Ano' } : { id: 1, label: 'Ne' },
        location: { id: get(sample, 'location.id'), label: get(sample, 'location.name') },
        department: { id: get(sample, 'department.id'), label: get(sample, 'department.name') },
        // isInCase: get(sample, 'itemCase', null) ? { id: 2, label: 'Ano' } : { id: 1, label: 'Ne' },
        itemCase: get(sample, 'itemCase')
          ? { id: get(sample, 'itemCase.id'), label: get(sample, 'itemCase.reference') }
          : null,
        state: find(SAMPLE_STATES, { id: get(sample, 'state') }),
        canLent: get(sample, 'canLent', null) ? { id: 2, label: 'Ano' } : { id: 1, label: 'Ne' },
        user: user ? { id: user.id, label: `${user.lastName} ${user.firstName}` } : null,
        discountGroup: get(sample, 'discountGroup', '') || '',
        activity: get(sample, 'activity', '') || '',
        commercializationStatus: get(sample, 'commercializationStatus', '') || '',
        updateStandardCaseContent: { id: 1, label: 'Ne' },
      });
    } else {
      reset({ useObtainedCode: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sample, edit, locations, users]);

  useEffect(() => {
    loadEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container={true} spacing={2}>
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <InputForm name="reference" label="Reference" rules={{ required: 'Vyžadováno' }} />
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <InputForm
                name="code"
                label="ID"
                rules={{
                  validate: (value: any) => {
                    if (parseInt(value, 10) < 1) {
                      return `Číslo musí být větší než 0`;
                    } else {
                      return true;
                    }
                  },
                  required: 'Vyžadováno',
                }}
                disabled={edit}
                type={edit ? 'text' : 'number'}
              />
            </Grid>
            {userIsAdmin && !edit && (
              <Grid item xs={6} sm={4} md={3} lg={2}>
                <SwitchForm name="useObtainedCode" label="Použít ID bez prefixu" />
              </Grid>
            )}

            <Grid item xs={12} sm={4} md={3} lg={4}>
              <InputForm name="internalNote" label="Interní poznámka" />
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
              <InputForm name="description" label="Popis" />
            </Grid>

            <Grid item xs={12}>
              <Grid container={true} spacing={2}>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <AutocompleteForm
                    name="department"
                    label="Oddělení (aktivní)"
                    options={departments
                      .sort((a: any, b: any) => (a.name || '').localeCompare(b.name || ''))
                      .map((item: any) => ({
                        id: item.id,
                        label: `${item.name}`,
                        disabled: !(find(userDepartments, { id: item.id }) || userIsAdmin),
                      }))}
                    rules={{ required: 'Vyžadováno' }}
                    disabled={!!sample?.itemCase}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <AutocompleteForm
                    name="location"
                    label="Umístění (aktivní)"
                    options={locations
                      .sort((a: any, b: any) => (a.name || '').localeCompare(b.name || ''))
                      .map((item: any) => ({
                        id: item.id,
                        label: item.name,
                      }))}
                    rules={{ required: 'Vyžadováno' }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container={true} spacing={2}>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <AutocompleteForm
                    name="case"
                    label="Vzorek je kufr"
                    options={[
                      { id: 1, label: 'Ne' },
                      { id: 2, label: 'Ano' },
                    ]}
                    rules={{ required: 'Vyžadováno' }}
                    disabled={(edit && caseItems.length > 0) || cantEditState}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <AutocompleteForm
                    name="canLent"
                    label="Lze zapůjčit"
                    options={[
                      { id: 1, label: 'Ne' },
                      { id: 2, label: 'Ano' },
                    ]}
                    rules={{ required: 'Vyžadováno' }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container={true} spacing={2}>
                <Grid item xs={12} md={4} lg={3}>
                  <InputForm
                    name="discountGroup"
                    label="Rabatová skupina"
                    rules={{ required: get(isCase, 'id') === 1 ? 'Vyžadováno' : undefined }}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <InputForm
                    name="activity"
                    label="Aktivita"
                    rules={{ required: get(isCase, 'id') === 1 ? 'Vyžadováno' : undefined }}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <InputForm
                    name="commercializationStatus"
                    label="Status komercializace"
                    rules={{ required: get(isCase, 'id') === 1 ? 'Vyžadováno' : undefined }}
                  />
                </Grid>
              </Grid>
            </Grid>
            {edit && (
              <Grid item xs={12}>
                <Grid container={true} spacing={2}>
                  <Grid item xs={12} sm={4} md={3} lg={2}>
                    <AutocompleteForm
                      name="state"
                      label="Stav"
                      options={
                        cantEditState
                          ? SAMPLE_STATES
                          : filter(SAMPLE_STATES, (item: any) => !includes(cantEditStates, item.id))
                      }
                      disabled={cantEditState}
                    />
                  </Grid>
                  {get(state, 'id') === 'INTERNAL_USAGE' && (
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                      <AutocompleteForm
                        name="user"
                        label="Uživatel"
                        options={csSort(users, 'lastName').map((item: any) => ({
                          id: item.id,
                          label: `${item.lastName} ${item.firstName}`,
                        }))}
                        rules={{ required: 'Vyžadováno' }}
                      />
                    </Grid>
                  )}
                  {edit && get(sample, 'case') && (
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                      <AutocompleteForm
                        name="updateStandardCaseContent"
                        label="Změnit výchozí obsahu kufru"
                        options={[
                          { id: 1, label: 'Ne' },
                          { id: 2, label: 'Ano' },
                        ]}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
            {edit && get(sample, 'itemCase') && (
              <Grid item xs={12}>
                Vzorek je součástí kufru{' '}
                <Link to={`/samples/${get(sample, 'itemCase.id')}`}>
                  {get(sample, 'itemCase.description')} (id: {get(sample, 'itemCase.code')})
                </Link>
              </Grid>
            )}

            {/*<Grid item xs={12}>
              <Grid container={true} spacing={2}>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <AutocompleteForm
                    name="isInCase"
                    label="Vzorek je součástí kufru"
                    options={[
                      { id: 1, label: 'Ne' },
                      { id: 2, label: 'Ano' },
                    ]}
                    rules={{ required: 'Vyžadováno' }}
                  />
                </Grid>
                {get(isInCase, 'id') === 2 && (
                  <Grid item xs={12} sm={4} md={3} lg={2}>
                    <AutocompleteForm
                      name="itemCase"
                      label="Kufr"
                      options={cases.map((item: any) => ({
                        id: item.id,
                        label: `${item.reference} (id: ${item.id})`,
                      }))}
                      rules={{ required: 'Vyžadováno' }}
                    />
                  </Grid>
                )}
              </Grid>
                    </Grid>*/}
            {edit && get(sample, 'case') && (
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  startIcon={<AddCircleIcon />}
                  size="small"
                  sx={{ ml: '10px' }}
                  onClick={() => {
                    setOpenAddSampleDialog(true);
                  }}
                >
                  Přidat vzorky do kufru
                </Button>
              </Grid>
            )}
            {edit && get(sample, 'case') && (
              <Grid item xs={12}>
                <Paper>
                  <SamplesResults
                    columns={columns}
                    samples={[
                      ...filter(caseItems, (item) => !includes(removeCaseItemsIds, item.id)),
                      ...newCaseItems.map((item: any) => ({ ...item, new: true })),
                    ]}
                    isLendingDetail={true}
                  />
                </Paper>
              </Grid>
            )}
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="secondary"
                component={Link}
                to={edit ? `/samples/${sample.id}` : '/samples'}
                sx={{ mr: '10px' }}
              >
                Zpět
              </Button>
              <Button variant="contained" color="primary" type="submit">
                Uložit
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <ConfirmationDialog deleteDialog={deleteDialog} setDeleteDialog={setDeleteDialog} />
    </>
  );
};

export default SampleForm;
