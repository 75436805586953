import { useState, useEffect } from 'react';
import { Button, Typography, Paper, Tooltip, Autocomplete, TextField } from '@mui/material';
import {
  Grid as GridTable,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableFilterRow,
} from '@devexpress/dx-react-grid-material-ui';
import {
  SortingState,
  PagingState,
  FilteringState,
  IntegratedPaging,
  IntegratedSorting,
  IntegratedFiltering,
  DataTypeProvider,
} from '@devexpress/dx-react-grid';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import { getAll, startStockTakingApi } from './_api';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { get, find } from 'lodash';
import { STOCK_TAKING_STATES } from 'utils/constants/index';
import useLoader from 'utils/hooks/useLoader';
import useRoleValidation from 'utils/hooks/useRoleValidation';

const StockTaking: React.FC<any> = () => {
  const [stockTaking, setStockaking] = useState<any>(false);
  const [sorting, setSorting] = useState<any>([{ columnName: 'startedDate', direction: 'desc' }]);
  const [filters, setFilters] = useState<any>([]);
  const [department, setDepartment] = useState<any>(null);
  const departments = useSelector((state) => get(state, 'app.user.departments', []));
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const setLoader = useLoader();
  const { checkRole } = useRoleValidation();

  const columns = [
    { name: 'id', title: 'ID' },
    {
      name: 'department',
      title: 'Oddělení',
      getCellValue: (row: any) => get(row, 'department.name', ''),
    },
    { name: 'state', title: 'Stav' },
    {
      name: 'startedBy',
      title: 'Spušteno uživatelem',
      getCellValue: (row: any) => get(row, 'startedBy.username', ''),
    },
    { name: 'startedDate', title: 'Spuštěno' },
    { name: 'finishedDate', title: 'Dokončeno' },
    {
      name: 'actions',
      title: 'Akce',
      getCellValue: (row: any) => (
        <>
          <Button
            variant="contained"
            component={Link}
            to={`/stockTakings/${row.id}`}
            startIcon={<EditIcon />}
            size="small"
            sx={{ ml: '10px' }}
            disabled={!find(departments, { id: get(row, 'department.id', '') })}
          >
            {get(row, 'state', '') === 'IN_PROGRESS' ? <>Pokračovat</> : <>Zobrazit</>}
          </Button>
        </>
      ),
    },
  ];

  const startStockTaking = async () => {
    setLoader();
    const resp = await startStockTakingApi(get(department, 'id', null));
    if (get(resp, 'status')) {
      enqueueSnackbar('Inventura spuštěna.', { variant: 'success' });
      navigate(`/stockTakings/${resp.data.id}`);
    } else {
      enqueueSnackbar(get(resp, 'errorText') || 'Novou inventuru nelze spustit.', {
        variant: 'error',
      });
    }
    setLoader(false);
  };

  const loadStockTaking = async () => {
    setLoader();
    const resp = await getAll();
    if (resp) {
      setStockaking(resp);
    } else {
      enqueueSnackbar('Inventury nenalezeny.', { variant: 'error' });
    }
    setLoader(false);
  };

  const TableCell = (props: any) => (
    <Table.Cell {...props} style={{ paddingTop: 5, paddingBottom: 5 }} />
  );

  const DateFormatter = ({ value }: any) =>
    moment(value).isValid() ? moment(value).format('DD.MM.YYYY HH:mm:ss') : '';

  const DateTypeProvider = (props: any) => (
    <DataTypeProvider formatterComponent={DateFormatter} {...props} />
  );

  const StateFormatter = ({ value }: any) => {
    const state = find(STOCK_TAKING_STATES, { id: value });
    return state ? get(state, 'label') : '';
  };

  const StateTypeProvider = (props: any) => (
    <DataTypeProvider formatterComponent={StateFormatter} {...props} />
  );

  useEffect(() => {
    loadStockTaking();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return checkRole('ROLE_STOCK_TAKING_VIEW') ? (
    <div>
      <Typography
        variant="h6"
        gutterBottom
        component="div"
        sx={{ display: 'flex', justifyContent: 'flex-start' }}
      >
        Správa inventur
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          value={department}
          options={departments}
          getOptionLabel={(option: any) => option.name}
          isOptionEqualToValue={(option: any, value: any) =>
            get(option, 'id', 1) === get(value, 'id', 2)
          }
          sx={{ width: 300, ml: '10px' }}
          renderInput={(params: any) => <TextField {...params} label="Oddělení" size="small" />}
          onChange={(event: any, newValue: any) => {
            setDepartment(newValue);
          }}
        />
        <Tooltip
          title={`Spustit inventuru${
            checkRole('ROLE_STOCK_TAKING_START') ? '' : ' (nemáte právo)'
          }`}
        >
          <span>
            <Button
              variant="contained"
              onClick={() => {
                startStockTaking();
              }}
              startIcon={<AddCircleIcon />}
              size="small"
              sx={{ ml: '10px' }}
              disabled={!checkRole('ROLE_STOCK_TAKING_START') || !department}
            >
              Spustit
            </Button>
          </span>
        </Tooltip>
      </Typography>

      {stockTaking !== false ? (
        <Paper>
          <GridTable rows={stockTaking} columns={columns}>
            <DateTypeProvider for={['startedDate', 'finishedDate']} />
            <StateTypeProvider for={['state']} />
            <FilteringState filters={filters} onFiltersChange={setFilters} />
            <SortingState sorting={sorting} onSortingChange={setSorting} />
            <PagingState defaultCurrentPage={0} pageSize={100} />
            <IntegratedFiltering />
            <IntegratedSorting />
            <IntegratedPaging />
            <Table cellComponent={TableCell} />
            <TableHeaderRow showSortingControls={true} />
            <TableFilterRow />
            <PagingPanel />
          </GridTable>
        </Paper>
      ) : null}
    </div>
  ) : (
    <div>Nemáte právo prohlížet inventury</div>
  );
};

export default StockTaking;
