import { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Button, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { get } from 'lodash';

import InputForm from 'components/Form/Input/Input';
import SwitchForm from 'components/Form/Switch/Switch';

const CustomerForm: React.FC<any> = ({ customer, edit, onSubmit, backFunction }) => {
  const methods = useForm();
  const { handleSubmit, reset } = methods;

  useEffect(() => {
    if (customer && edit) {
      reset({
        name: get(customer, 'name', '') || '',
        address: get(customer, 'address', '') || '',
        active: get(customer, 'active', false),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer, edit]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {console.log(backFunction)}
        <Grid container={true} spacing={2}>
          <Grid item xs={6} md={3}>
            <InputForm name="name" label="Název" rules={{ required: 'Vyžadováno' }} />
          </Grid>
          <Grid item xs={6} md={3}>
            <InputForm name="address" label="Adresa" rules={{ required: 'Vyžadováno' }} />
          </Grid>
          <Grid item xs={12}>
            <SwitchForm name="active" label="Je aktivní?" />
          </Grid>
          <Grid item xs={12}>
            {backFunction ? (
              <Button
                variant="contained"
                color="secondary"
                sx={{ mr: '10px' }}
                onClick={() => {
                  backFunction();
                }}
              >
                Zpět
              </Button>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                component={Link}
                to={'/settings?active=customers'}
                sx={{ mr: '10px' }}
              >
                Zpět
              </Button>
            )}

            <Button variant="contained" color="primary" type="submit">
              Uložit
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default CustomerForm;
