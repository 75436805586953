import { api } from 'utils/hooks/useApi';
import { get } from 'lodash';

export const login = (values: any): Promise<any> =>
  api()
    .post(`/pae-api/login`, values)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });

export const getHierarchy = (): Promise<any> =>
  api()
    .get(`/pae-api/workplace/getHierarchy`)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });
