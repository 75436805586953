import { api } from 'utils/hooks/useApi';
import { get } from 'lodash';
import { processError } from 'utils/utils';

export const getAllLocations = (): Promise<any> =>
  api()
    .get(`/pae-api/dictionary/locations/getAll`)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });

export const getLocations = (active: boolean = true): Promise<any> =>
  api()
    .get(`/pae-api/dictionary/locations/getAll?active=${active}`)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });

export const createLocation = (values: any): Promise<any> =>
  api()
    .post(`/pae-api/dictionary/locations/create`, values)
    .then((response: any) => ({ status: true, data: get(response, 'data') }))
    .catch((error) => {
      return processError(error);
    });

export const getLocation = (id: any): Promise<any> =>
  api()
    .get(`/pae-api/dictionary/locations/get?id=${id}`)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });

export const updateLocation = (values: any): Promise<any> =>
  api()
    .post(`/pae-api/dictionary/locations/update`, values)
    .then((response: any) => ({ status: true, data: get(response, 'data') }))
    .catch((error) => {
      return processError(error);
    });

export const deleteLocation = (id: any): Promise<any> =>
  api()
    .delete(`/pae-api/dictionary/locations/delete?id=${id}`)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });
