import axios from 'axios';

import { getItem } from './useStorage';

export const api = (settings = {}) => {
  const storageToken: string = getItem('jwtToken') as string;

  const api = axios.create({
    headers: {
      Authorization: `Bearer ${storageToken}`,
      'Content-Type': 'application/json',
    },
    ...settings,
  });

  return api;
};
