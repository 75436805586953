import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  SortingState,
  PagingState,
  FilteringState,
  IntegratedPaging,
  IntegratedSorting,
  IntegratedFiltering,
  RowDetailState,
  SelectionState,
  IntegratedSelection,
} from '@devexpress/dx-react-grid';
import {
  Grid as GridTable,
  Table,
  VirtualTable,
  TableHeaderRow,
  PagingPanel,
  TableFilterRow,
  TableColumnResizing,
  TableRowDetail,
  TableColumnReordering,
  DragDropProvider,
  TableSelection,
  TableColumnVisibility,
  Toolbar,
  ColumnChooser,
  ExportPanel,
} from '@devexpress/dx-react-grid-material-ui';
import moment from 'moment';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { get, find, filter, includes } from 'lodash';
import { IconButton, Box, Paper, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import useFormatters from 'utils/hooks/useFormatters';
import { storeGridSettings } from 'modules/Main/_actions';
import { setUserGridSettings } from 'modules/Main/_api';
import useLoader from 'utils/hooks/useLoader';
import { getItemsInCase } from './_api';
import useItemConvert from 'utils/hooks/useItemConvert';
import useGridUtils from 'utils/hooks/useGridUtils';
import saveAs from 'file-saver';

const defaultColumnWidths = [
  { columnName: 'reference', width: 180 },
  { columnName: 'code', width: 180 },
  { columnName: 'stateLabel', width: 180 },
  { columnName: 'case_note', width: 180 },
  { columnName: 'department', width: 180 },
  { columnName: 'location', width: 180 },
  { columnName: 'actions', width: 180 },
  { columnName: 'description', width: 180 },
  { columnName: 'discountGroup', width: 180 },
  { columnName: 'activity', width: 180 },
  { columnName: 'commercializationStatus', width: 180 },
  { columnName: 'createdDate', width: 180 },
  { columnName: 'createdByLabel', width: 180 },
  { columnName: 'returnedBy', width: 180 },
];

const defaultColumnOrder = defaultColumnWidths.map((item) => item.columnName);

const SamplesResults: React.FC<any> = ({
  samples,
  columns,
  selection,
  setSelection,
  canSelect = false,
  selectionInCase,
  setSelectionInCase,
  canSelectInCase = false,
  detailColumns,
  refreshItemCase,
  isLendingDetail,
  virtualTable = false,
  setActualRows,
  SelectionCol2,
  toolbar = true,
  isExport = false,
}) => {
  const dispatch = useDispatch();
  const [sorting, setSorting] = useState<any>([{ columnName: 'reference', direction: 'asc' }]);
  const [filters, setFilters] = useState<any>([]);
  const [expandedRowIds, setExpandedRowIds] = useState<any>([]);
  const [itemsInCase, setItemsInCase] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const setLoader = useLoader();
  const { DateTimeTypeProvider, StateTypeProvider, CaseNoteTypeProvider, LinkTypeProvider } =
    useFormatters();
  const { sampleConvert } = useItemConvert();
  const { translateGrid } = useGridUtils();

  const gridSettings = useSelector((state: any) => get(state, 'app.settings.grids.samples'));

  const exporterRef = useRef<any>(null);

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);

  const TableCell = (props: any) => (
    <Table.Cell {...props} style={{ paddingTop: 0, paddingBottom: 0 }} />
  );

  const TableCellCase = (props: any) => (
    <Table.Cell {...props} style={{ paddingTop: 5, paddingBottom: 5 }} />
  );

  const columnWidths =
    get(gridSettings, 'columnWidths', []).length === defaultColumnWidths.length
      ? get(gridSettings, 'columnWidths')
      : defaultColumnWidths;

  const columnOrder =
    get(gridSettings, 'columnOrder', []).length === defaultColumnOrder.length
      ? get(gridSettings, 'columnOrder')
      : defaultColumnOrder;

  const hiddenColumnNames = get(gridSettings, 'hiddenColumnNames', []);

  const setColumnWidths = async (values: any) => {
    dispatch(storeGridSettings({ samples: { ...gridSettings, columnWidths: values } }));
    await setUserGridSettings(
      JSON.stringify({ samples: { ...gridSettings, columnWidths: values } }),
    );
  };

  const setColumnOrder = async (values: any) => {
    console.log(values);
    dispatch(storeGridSettings({ samples: { ...gridSettings, columnOrder: values } }));
    await setUserGridSettings(
      JSON.stringify({ samples: { ...gridSettings, columnOrder: values } }),
    );
  };

  const setHiddenColumnNames = async (values: any) => {
    dispatch(storeGridSettings({ samples: { ...gridSettings, hiddenColumnNames: values } }));
    await setUserGridSettings(
      JSON.stringify({ samples: { ...gridSettings, hiddenColumnNames: values } }),
    );
  };

  const loadItemsInCase = async (id: number, refresh = false) => {
    setLoader();
    let items = [];
    if (refresh) {
      items = filter(itemsInCase, (item) => item.caseId !== id);
    } else {
      items = itemsInCase;
    }
    if (!find(items, { caseId: id })) {
      const resp = await getItemsInCase(id);
      if (resp) {
        setItemsInCase([
          ...items,
          {
            caseId: id,
            items: resp.map((item: any) => sampleConvert(item)),
          },
        ]);
      }
    }
    setLoader(false);
  };

  const ToggleCellComponent = ({ expanded, onToggle, row, ...otherProps }: any) => {
    const handleClick = (e: any) => {
      onToggle();
      const caseId = parseInt(row.id, 10);
      loadItemsInCase(caseId);
    };
    return row.case ? (
      <Table.Cell {...otherProps} sx={{ p: 0 }}>
        <Box sx={{ p: 0 }} onClick={handleClick}>
          <IconButton sx={{ p: 0 }}>
            {expanded ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
      </Table.Cell>
    ) : (
      <Table.Cell {...otherProps} />
    );
  };

  const RowDetail = ({ row }: any) => {
    return (
      <Paper>
        <GridTable
          rows={get(find(itemsInCase, { caseId: row.id }), 'items', [])}
          columns={
            detailColumns || filter(columns, (item) => !includes(['case', 'actions'], item.name))
          }
          getRowId={getRowId}
        >
          <DateTimeTypeProvider for={['createdDate']} />
          <StateTypeProvider for={['stateLabel']} />
          <CaseNoteTypeProvider for={['case_note']} />
          <FilteringState filters={filters} onFiltersChange={setFilters} />
          <SortingState sorting={sorting} onSortingChange={setSorting} />
          {canSelectInCase && (
            <SelectionState selection={selectionInCase} onSelectionChange={setSelectionInCase} />
          )}
          <PagingState defaultCurrentPage={0} pageSize={50} />
          <RowDetailState
            expandedRowIds={expandedRowIds}
            onExpandedRowIdsChange={setExpandedRowIds}
          />
          <IntegratedFiltering />
          <IntegratedSorting />
          <IntegratedPaging />
          {canSelectInCase && <IntegratedSelection />}

          <Table
            columnExtensions={[{ columnName: 'description', wordWrapEnabled: true }]}
            cellComponent={TableCellCase}
            messages={get(translateGrid, 'tableMessages')}
          />
          <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={setColumnWidths} />
          <TableColumnReordering order={columnOrder} onOrderChange={setColumnOrder} />
          <TableHeaderRow showSortingControls={true} />
          {canSelectInCase && <TableSelection showSelectAll={true} />}
          <TableFilterRow messages={get(translateGrid, 'filterRowMessages')} />
          <TableRowDetail contentComponent={RowDetail} toggleCellComponent={ToggleCellComponent} />
          <PagingPanel messages={get(translateGrid, 'pagingPanelMessages')} />
        </GridTable>

        {selectionInCase && selectionInCase.length > 0 ? (
          <Box sx={{ pb: '10px', display: 'flex', justifyContent: 'center' }}>
            <Button variant="contained" onClick={() => console.log('test')}>
              Vrátit vybrané
            </Button>
          </Box>
        ) : (
          ''
        )}
      </Paper>
    );
  };

  const SelectionCol = ({ row, ...restProps }: TableSelection.CellProps) => {
    return isLendingDetail && !(row.lent && row.lentId === row.lendingId) ? (
      virtualTable ? (
        <VirtualTable.Cell {...restProps} value={undefined} row={row} column={{ name: 'sort' }} />
      ) : (
        <Table.Cell {...restProps} value={undefined} row={row} column={{ name: 'sort' }} />
      )
    ) : (
      <TableSelection.Cell row={row} {...restProps} />
    );
  };

  const getRowId = (row: any) => get(row, 'id');

  const onPageSizeChange = (value: any) => {
    setCurrentPage(value);
    const element = document.getElementById('search-results');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      // window.scrollTo(0, 0);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  const gridHeight = window.innerHeight - 130;

  useEffect(() => {
    if (refreshItemCase > 0) {
      loadItemsInCase(refreshItemCase, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshItemCase]);

  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Vzorky.xlsx');
    });
  };

  return (
    <div>
      <GridTable rows={samples} columns={columns} getRowId={getRowId}>
        <DateTimeTypeProvider for={['createdDate']} />
        <StateTypeProvider for={['stateLabel']} />
        <CaseNoteTypeProvider for={['case_note']} />
        <LinkTypeProvider for={['code']} />
        <FilteringState filters={filters} onFiltersChange={setFilters} />
        <SortingState sorting={sorting} onSortingChange={setSorting} />
        {canSelect && <SelectionState selection={selection} onSelectionChange={setSelection} />}
        <DragDropProvider />
        <PagingState
          currentPage={currentPage}
          onCurrentPageChange={onPageSizeChange}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
        />
        <RowDetailState
          expandedRowIds={expandedRowIds}
          onExpandedRowIdsChange={setExpandedRowIds}
        />
        <IntegratedFiltering />
        <IntegratedSorting />
        <IntegratedPaging />
        {/* <RowsProvider onRowsUpdate={onRowsUpdate} /> */}
        {canSelect && <IntegratedSelection />}
        {virtualTable ? (
          <VirtualTable
            height={gridHeight}
            columnExtensions={[{ columnName: 'description', wordWrapEnabled: true }]}
            cellComponent={TableCell}
            messages={get(translateGrid, 'tableMessages')}
          />
        ) : (
          <Table
            columnExtensions={[{ columnName: 'description', wordWrapEnabled: true }]}
            cellComponent={TableCell}
            messages={get(translateGrid, 'tableMessages')}
          />
        )}
        <TableColumnReordering order={columnOrder} onOrderChange={setColumnOrder} />
        <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={setColumnWidths} />
        <TableHeaderRow showSortingControls={true} />
        {canSelect && (
          <TableSelection
            showSelectAll={isLendingDetail ? false : true}
            cellComponent={SelectionCol2 || SelectionCol}
          />
        )}
        <TableColumnVisibility
          hiddenColumnNames={hiddenColumnNames}
          onHiddenColumnNamesChange={setHiddenColumnNames}
        />
        <TableFilterRow messages={get(translateGrid, 'filterRowMessages')} />
        <TableRowDetail contentComponent={RowDetail} toggleCellComponent={ToggleCellComponent} />
        {toolbar && (
          <PagingPanel
            messages={get(translateGrid, 'pagingPanelMessages')}
            pageSizes={[50, 100, 200, 500, 1000]}
          />
        )}
        {toolbar && <Toolbar />}
        {toolbar && <ColumnChooser />}
        {isExport && (
          <ExportPanel
            startExport={startExport}
            messages={{ exportAll: 'Exportovat vše', exportSelected: '' }}
          />
        )}
      </GridTable>
      {isExport && (
        <GridExporter
          ref={exporterRef}
          columns={[
            ...columns.filter(
              (item: any) => !includes(['actions', 'createdDate', 'case_note'], item.name),
            ),
            {
              name: 'createdDate',
              title: 'Vytvořeno',
              getCellValue: (row: any) =>
                moment(get(row, 'createdDate')).isValid()
                  ? moment(get(row, 'createdDate')).format('DD.MM.YYYY HH:mm:ss')
                  : '',
            },
            {
              name: 'case_note',
              title: 'Kufr',
              getCellValue: (row: any) => (get(row, 'case') ? 'Ano' : 'Ne'),
            },
          ]}
          rows={samples}
          getRowId={getRowId}
          filters={filters}
          sorting={sorting}
          columnOrder={columnOrder}
          hiddenColumnNames={hiddenColumnNames}
          selection={selection}
          onSave={onSave}
        />
      )}
    </div>
  );
};

export default SamplesResults;
