import { TextField } from '@mui/material';
import { useController } from 'react-hook-form';
import { get, isEmpty } from 'lodash';

const InputForm = ({
  control,
  name,
  label,
  type = 'text',
  disabled = false,
  multiline,
  rows,
  inputProps,
  defaultValue = '',
  placeholder,
  rules,
  autoComplete = '',
}: any) => {
  const {
    field: { ref, value, ...props },
    formState: { errors },
    // meta: { invalid, isTouched, isDirty },
  } = useController({
    name,
    control,
    defaultValue,
    rules,
  });
  let isError = false;
  let errorMessage = '';
  if (!isEmpty(errors) && get(errors, name) !== undefined) {
    isError = true;
    errorMessage = get(errors, `${name}.message`);
  }
  const required = get(rules, 'required', false);
  return (
    <TextField
      {...props}
      value={value === undefined ? '' : value}
      type={type}
      label={label}
      fullWidth={true}
      error={isError}
      helperText={errorMessage}
      margin="dense"
      size="small"
      InputLabelProps={{
        className: required ? 'required-label' : '',
        required: !!required,
      }}
      inputRef={ref}
      placeholder={
        placeholder ? placeholder : /* compactMode ? formatLabelForRequired(label, required) : */ ''
      }
      disabled={disabled}
      multiline={multiline}
      rows={rows}
      autoComplete={autoComplete}
      inputProps={{
        ...inputProps,
        // ...(resize ? { style: { ...style, resize: 'vertical' } } : { style }),
      }}
    />
  );
};

export default InputForm;
