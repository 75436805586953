import { get, find } from 'lodash';
import { useSelector } from 'react-redux';

const useRoleValidation = () => {
  const userRoles = useSelector((state: any) => get(state, 'app.user.roles', []));
  const checkRole = (role: string) => {
    const check = find(userRoles, { authority: role });
    return check ? true : false;
  };

  const checkRoleLabel = (role: string, nextCondition = true) =>
    checkRole(role) && nextCondition ? '' : '(Nemáte právo)';
  return { checkRole, checkRoleLabel };
};

export default useRoleValidation;
