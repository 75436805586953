import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Dialog, Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { get } from 'lodash';

import useLoader from 'utils/hooks/useLoader';
import { multipleUpdate } from 'modules/Samples/_api';

const CaseConfirmNewLocationDialog: React.FC<any> = ({
  dialog: { open = false, sample, selection, itemsInCase, confirmedItems, submitCase, analyze },
  setDialog,
  setCaseDialog,
}) => {
  const setLoader = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const handleClose = () => {
    setDialog({ open: false });
  };

  const handleCaseClose = () => {
    setCaseDialog({ open: false });
  };

  const handleSubmit = async () => {
    const selectedWithoutConfirmedIds = get(analyze, 'selectedWithoutConfirmedIds', []);

    await submitCase(sample, itemsInCase, selectedWithoutConfirmedIds);
    handleClose();
    handleCaseClose();

    /*if (get(analyze, 'isStateChangedFromAvailableToIncomplete')) {
      const resp = await multipleUpdate({
        state: 'INCOMPLETE',
        description: ' ',
        itemIds: [sample.id],
      });
    }*/

    //submitCase(sample, itemsInCase, selection);
    /*setLoader();
    let values = {};
    if (get(sample, 'location.id', 1) !== get(newLocation, 'id', 2)) {
      const resp = await multipleUpdate({
        locationId: newLocation.id,
        itemIds: [sample.id, ...itemsInCase.map((item: any) => item.id)],
      });
    }

    if (uncompleteCase()) {
      const resp = await multipleUpdate({
        state: 'INCOMPLETE',
        description: ' ',
        itemIds: [sample.id],
      });
      confirmItems([
        ...selection, // items in case
      ]);
    }
    handleClose();
    handleCaseClose();
   
    setLoader(false);*/
  };

  console.log(sample);

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
    >
      <DialogTitle>Potvrzení změn u kufru</DialogTitle>
      <DialogContent dividers>
        <Box>
          Reference: {sample?.reference}, ID: {sample?.code}
        </Box>
        <Box>
          <br />
          Potvrzením dojde k následujícím akcím:
          <br />
          {(analyze?.warnings || []).map((warning: any, i: number) => (
            <div key={i}>{`- ${warning.message} `}</div>
          ))}
          {get(analyze, 'selectedWithoutConfirmedIds', []).length > 0 ? (
            <div> - potvrzení vybraných vzorků</div>
          ) : null}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          variant="contained"
          autoFocus
          onClick={() => {
            handleClose();
          }}
        >
          {'Zrušit'}
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={get(analyze, 'selectedWithoutConfirmedIds', [0]).length === 0}
        >
          {'Potvrdit'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CaseConfirmNewLocationDialog;
