import moment from 'moment';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Tooltip } from '@mui/material';
import WorkIcon from '@mui/icons-material/Work';
import NotesIcon from '@mui/icons-material/Notes';
import BlockIcon from '@mui/icons-material/Block';
import { Link } from 'react-router-dom';
import { get } from 'lodash';

const DateTimeFormatter = ({ value }: any) =>
  moment(value).isValid() ? moment(value).format('DD.MM.YYYY HH:mm:ss') : '';

const DateFormatter = ({ value }: any) =>
  moment(value).isValid() ? moment(value).format('DD.MM.YYYY') : '';

const StateFormatter = ({ value, row }: any) => {
  return (
    <>
      {value}
      {row.itemCaseLink ? (
        <>
          {' '}
          <Link to={row.itemCaseLink}>{get(row, 'itemCase.reference', '')}</Link>
        </>
      ) : undefined}
    </>
  );
};

const LinkFormatter = ({ value, row }: any) => {
  return (
    <>
      <Link target="_blank" to={`/samples/${row.id}`}>
        {value}
      </Link>
    </>
  );
};

const CaseNoteFormatter = ({ value, row }: any) => {
  return (
    <>
      {get(row, 'case') ? (
        <Tooltip title="Vzorek je kufr">
          <WorkIcon color="primary" />
        </Tooltip>
      ) : (
        ''
      )}
      {get(row, 'internalNote') ? (
        <Tooltip title={get(row, 'internalNote', '')}>
          <NotesIcon color="primary" />
        </Tooltip>
      ) : (
        ''
      )}
      {get(row, 'canLent') ? (
        ''
      ) : (
        <Tooltip title="Nelze zapůjčit">
          <BlockIcon sx={{ color: 'red' }} />
        </Tooltip>
      )}
    </>
  );
};

const useFormatters = () => {
  const DateTypeProvider = (props: any) => (
    <DataTypeProvider formatterComponent={DateFormatter} {...props} />
  );
  const DateTimeTypeProvider = (props: any) => (
    <DataTypeProvider formatterComponent={DateTimeFormatter} {...props} />
  );
  const StateTypeProvider = (props: any) => (
    <DataTypeProvider formatterComponent={StateFormatter} {...props} />
  );
  const CaseNoteTypeProvider = (props: any) => (
    <DataTypeProvider formatterComponent={CaseNoteFormatter} {...props} />
  );

  const LinkTypeProvider = (props: any) => (
    <DataTypeProvider formatterComponent={LinkFormatter} {...props} />
  );
  return {
    DateTimeTypeProvider,
    DateTypeProvider,
    StateTypeProvider,
    CaseNoteTypeProvider,
    LinkTypeProvider,
  };
};

export default useFormatters;
