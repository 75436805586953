import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { get, isArray } from 'lodash';

import UserForm from './UserForm';
import { createUser, manageUserDepartments } from './_api';
import { manageUsers } from '../Workplaces/_api';
import useLoader from 'utils/hooks/useLoader';
import useRoleValidation from 'utils/hooks/useRoleValidation';

const UserCreate: React.FC<any> = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const setLoader = useLoader();
  const { checkRole } = useRoleValidation();

  const onSubmit = async (values: any) => {
    setLoader();
    const resp = await createUser({ ...values });
    if (resp) {
      const resp2 = await manageUsers({
        users: [{ userId: get(resp, 'id'), workPlaceIds: [get(values, 'workplace.id')] }],
      });

      const departments = isArray(get(values, 'departments'))
        ? get(values, 'departments')
        : get(values, 'departments')
        ? [get(values, 'departments')]
        : [];

      const resp3 = await manageUserDepartments({
        userId: get(resp, 'id'),
        departmentIds: (departments || []).map((item: any) => item.id),
      });

      if (resp) {
        enqueueSnackbar('Uživatel byl úspěšně uložen', { variant: 'success' });
        navigate(`/settings?active=users`);
      } else {
        enqueueSnackbar('Uživatele se nepodařilo uložit', { variant: 'error' });
      }
    } else {
      enqueueSnackbar('Uživatele se nepovedlo vytvořit', { variant: 'error' });
    }
    setLoader(false);
  };

  return checkRole('ROLE_USER_CREATE') ? (
    <div>
      <Typography variant="h6" gutterBottom component="div">
        Přidání uživatele
      </Typography>
      <UserForm onSubmit={onSubmit} />
    </div>
  ) : (
    <div>Nemáte právo na vytvoření uživatele</div>
  );
};

export default UserCreate;
