import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { get, find } from 'lodash';
import { Box } from '@mui/material';

import { Bar } from 'components/Bar';

import Login from 'modules/Login/Login';

import Samples from 'modules/Samples/Samples';
import SampleCreate from 'modules/Samples/SampleCreate';
import SampleDetail from 'modules/Samples/SampleDetail';
import SampleEdit from 'modules/Samples/SampleEdit';
import Lendings from 'modules/Lendings/Lendings';
import LendingCreate from 'modules/Lendings/LendingCreate';
import LendingDetail from 'modules/Lendings/LendingDetail';
import LendingEdit from 'modules/Lendings/LendingEdit';
import Settings from 'modules/Settings/Settings';
import UserCreate from 'modules/Settings/Users/UserCreate';
import UserEdit from 'modules/Settings/Users/UserEdit';
import DealerCreate from 'modules/Settings/Dealers/DealerCreate';
import DealerEdit from 'modules/Settings/Dealers/DealerEdit';
import LocationCreate from 'modules/Settings/Locations/LocationCreate';
import LocationEdit from 'modules/Settings/Locations/LocationEdit';
import DepartmentCreate from 'modules/Settings/Departments/DepartmentCreate';
import DepartmentEdit from 'modules/Settings/Departments/DepartmentEdit';
import CustomerCreate from 'modules/Settings/Customers/CustomerCreate';
import CustomerEdit from 'modules/Settings/Customers/CustomerEdit';
import PersonCreate from 'modules/Settings/Customers/PersonCreate';
import PersonEdit from 'modules/Settings/Customers/PersonEdit';
import WorkplaceEdit from 'modules/Settings/Workplaces/WorkplaceEdit';

import { storeUser } from 'modules/Login/_actions';
import { storeGridSettings } from './_actions';
import { refreshToken, getUserGridSettings, getUserRoles } from './_api';

import { useStorage } from 'utils/hooks/useStorage';
import StockTaking from 'modules/StockTaking/StockTaking';
import StockTakingDetail from 'modules/StockTaking/StockTakingDetail';
import WorkplaceCreate from 'modules/Settings/Workplaces/WorkplaceCreate';
import { getUserDepartments } from 'modules/Settings/Users/_api';
import { getHierarchy } from 'modules/Login/_api';

const Main: React.FC<any> = () => {
  const isLoggedIn = useSelector((state: any) => get(state, 'app.user.isLoggedIn', null));
  const { putItem } = useStorage();
  const dispatch = useDispatch();

  const checkToken = async () => {
    const resp = await refreshToken();
    if (resp) {
      putItem('jwtToken', get(resp, 'jwtToken'));
      const [resp2, resp3, resp4, resp5] = await Promise.all([
        getUserGridSettings(),
        getUserRoles(),
        getUserDepartments(get(resp, 'username')),
        getHierarchy(),
      ]);
      dispatch(
        storeUser({
          ...resp,
          jwtToken: undefined,
          roles: resp3,
          departments: resp4,
          allowedWorkplaces: get(
            find(resp5, (item) => get(item, 'workPlace.id') === get(resp, 'workplaces[0].id')),
            'allowedWorkPlace',
          ),
        }),
      );
      if (resp2) {
        dispatch(storeGridSettings(resp2));
      }
      // await setUserGridSettings(JSON.stringify({ samples: null }));
    } else {
      dispatch(storeUser(null));
    }
  };

  useEffect(() => {
    checkToken();
    const interval = setInterval(checkToken, 1000 * 120);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLoggedIn ? (
    <>
      <Bar />
      <Box sx={{ p: '10px' }}>
        <Routes>
          <Route path="/" element={<div />} />
          <Route path="/stockTakings" element={<StockTaking />} />
          <Route path="/stockTakings/:stockTakingId" element={<StockTakingDetail />} />
          <Route path="/samples" element={<Samples />} />
          <Route path="/samples/create" element={<SampleCreate />} />
          <Route path="/samples/:sampleId" element={<SampleDetail />} />
          <Route path="/samples/:sampleId/edit" element={<SampleEdit />} />
          <Route path="/lendings" element={<Lendings />} />
          <Route path="/lendings/create" element={<LendingCreate />} />
          <Route path="/lendings/:lendingId" element={<LendingDetail />} />
          <Route path="/lendings/:lendingId/edit" element={<LendingEdit />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/settings/users/create" element={<UserCreate />} />
          <Route path="/settings/users/:userId/edit" element={<UserEdit />} />
          <Route path="/settings/dealers/create" element={<DealerCreate />} />
          <Route path="/settings/dealers/:dealerId/edit" element={<DealerEdit />} />
          <Route path="/settings/locations/create" element={<LocationCreate />} />
          <Route path="/settings/locations/:locationId/edit" element={<LocationEdit />} />
          <Route path="/settings/departments/create" element={<DepartmentCreate />} />
          <Route path="/settings/departments/:departmentId/edit" element={<DepartmentEdit />} />
          <Route path="/settings/customers/create" element={<CustomerCreate />} />
          <Route path="/settings/customers/:customerId/edit" element={<CustomerEdit />} />
          <Route path="/settings/customers/:customerId/person/create" element={<PersonCreate />} />
          <Route path="/settings/workplaces/create" element={<WorkplaceCreate />} />
          <Route path="/settings/workplaces/:workplaceId/edit" element={<WorkplaceEdit />} />
          <Route
            path="/settings/customers/:customerId/person/:personId/edit"
            element={<PersonEdit />}
          />
        </Routes>
      </Box>
    </>
  ) : isLoggedIn === null ? (
    <>{''}</>
  ) : (
    <Login />
  );
};

export default Main;
