import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { get } from 'lodash';
import PersonForm from 'modules/Settings/Customers/PersonForm';

const AddPersonDialog: React.FC<any> = ({ open, setOpen, onSubmit }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog fullWidth={true} maxWidth="lg" open={get(open, 'open')} onClose={handleClose}>
        <DialogTitle>Přidání nového zákazníka</DialogTitle>
        <DialogContent>
          <PersonForm onSubmit={onSubmit} backFunction={() => handleClose()} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddPersonDialog;
