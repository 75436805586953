import { api } from 'utils/hooks/useApi';
import { get } from 'lodash';
import { processError } from 'utils/utils';

export const getCustomerCompanies = (active: any = true): Promise<any> =>
  api()
    .get(`/pae-api/dictionary/customer/company/getAll?active=${active}`)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });

export const createCustomerCompany = (values: any): Promise<any> =>
  api()
    .post(`/pae-api/dictionary/customer/company/create`, values)
    .then((response: any) => ({ status: true, data: get(response, 'data') }))
    .catch((error) => {
      return processError(error);
    });

export const getCustomerCompany = (id: any): Promise<any> =>
  api()
    .get(`/pae-api/dictionary/customer/company/get?id=${id}`)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });

export const updateCustomerCompany = (values: any): Promise<any> =>
  api()
    .post(`/pae-api/dictionary/customer/company/update`, values)
    .then((response: any) => ({ status: true, data: get(response, 'data') }))
    .catch((error) => {
      return processError(error);
    });

export const createCustomerPerson = (values: any): Promise<any> =>
  api()
    .post(`/pae-api/dictionary/customer/company/person/create`, values)
    .then((response: any) => ({ status: true, data: get(response, 'data') }))
    .catch((error) => {
      return processError(error);
    });

export const getCustomerPerson = (id: any): Promise<any> =>
  api()
    .get(`/pae-api/dictionary/customer/company/person/get?id=${id}`)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });

export const updateCustomerPerson = (values: any): Promise<any> =>
  api()
    .post(`/pae-api/dictionary/customer/company/person/update`, values)
    .then((response: any) => ({ status: true, data: get(response, 'data') }))
    .catch((error) => {
      return processError(error);
    });

export const deleteCustomer = (id: any): Promise<any> =>
  api()
    .delete(`/pae-api/dictionary/customer/company/delete?id=${id}`)
    .then((response: any) => ({ status: true, data: get(response, 'data') }))
    .catch((error) => {
      return processError(error);
    });

export const deleteCustomerPerson = (id: any): Promise<any> =>
  api()
    .delete(`/pae-api/dictionary/customer/company/person/delete?id=${id}`)
    .then((response: any) => ({ status: true, data: get(response, 'data') }))
    .catch((error) => {
      return processError(error);
    });
