import { Dialog, DialogContent, DialogTitle } from '@mui/material';

import CustomerForm from 'modules/Settings/Customers/CustomerForm';

const AddCustomerDialog: React.FC<any> = ({
  open,
  setOpen,

  onSubmit,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog fullWidth={true} maxWidth="lg" open={open} onClose={handleClose}>
        <DialogTitle>Přidání nového zákazníka</DialogTitle>
        <DialogContent>
          <CustomerForm onSubmit={onSubmit} backFunction={() => handleClose()} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddCustomerDialog;
