import React from 'react';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Button, ButtonGroup } from '@mui/material';
import { ExitToApp } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { get, pickBy, isEmpty } from 'lodash';

import { useStorage } from 'utils/hooks/useStorage';
import { storeUser } from 'modules/Login/_actions';
import useRoleValidation from 'utils/hooks/useRoleValidation';

export const Bar: React.FC = () => {
  const dispatch = useDispatch();
  const { checkRole } = useRoleValidation();
  const reservationItems = useSelector((state: any) => get(state, 'app.reservationItems', []));
  const lendingForm = useSelector((state: any) => get(state, 'app.lendingForm'));
  const { removeItem } = useStorage();

  const location = get(useLocation(), 'pathname');

  const leftMenu = [
    { label: 'Vzorky', link: 'samples', role: 'ROLE_ITEM_VIEW' },
    {
      label: 'Zápůjčky',
      link: 'lendings',
      role: 'ROLE_LOAN_VIEW',
    },
    { label: 'Inventury', link: 'stockTakings', role: 'ROLE_STOCK_TAKING_VIEW' },
    { label: 'Nastavení', link: 'settings', role: 'ROLE_USER_UPDATE' },
  ];

  const logout = () => {
    removeItem('jwtToken');
    dispatch(storeUser(null));
  };

  const username = useSelector((state: any) => get(state, 'app.user.username', ''));

  return (
    <AppBar position="static">
      <Toolbar variant="dense">
        <Box sx={{ flexGrow: 1 }}>
          {leftMenu.map((menuItem: any) => {
            return (
              <Button
                sx={{ mr: '20px' }}
                color="inherit"
                key={menuItem.label}
                size="small"
                component={Link}
                to={menuItem.link}
                disabled={!checkRole(menuItem.role)}
              >
                {menuItem.label}
              </Button>
            );
          })}
        </Box>
        <ButtonGroup variant="text" aria-label="outlined primary button group">
          {(reservationItems.length > 0 || !isEmpty(pickBy(lendingForm))) &&
            location !== '/lendings/create' &&
            checkRole('ROLE_LOAN_CREATE') && (
              <Button
                sx={{ mr: '30px' }}
                color="inherit"
                size="small"
                component={Link}
                to={'/lendings/create?action=fast'}
              >
                {`Rychlá zápůjčka (${reservationItems.length})`}
              </Button>
            )}
          <Button color="inherit" size="small">
            {`Účet (${username})`}
          </Button>
        </ButtonGroup>
        <Button
          color="inherit"
          size="small"
          endIcon={<ExitToApp />}
          onClick={() => {
            logout();
          }}
        >
          Odhlásit
        </Button>
      </Toolbar>
    </AppBar>
  );
};
