import { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Button, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { get } from 'lodash';

import InputForm from 'components/Form/Input/Input';
import SwitchForm from 'components/Form/Switch/Switch';

const DepartmentForm: React.FC<any> = ({ department, edit, onSubmit }) => {
  const methods = useForm();
  const { handleSubmit, reset } = methods;

  useEffect(() => {
    if (department && edit) {
      reset({
        name: get(department, 'name', '') || '',
        active: get(department, 'active'),
        codePrefix: get(department, 'codePrefix', '') || '',
      });
    } else {
      reset({
        active: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [department, edit]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container={true} spacing={2}>
          <Grid item xs={6} md={3}>
            <InputForm name="name" label="Název" rules={{ required: 'Vyžadováno' }} />
          </Grid>
          <Grid item xs={6} md={3}>
            <InputForm
              name="codePrefix"
              label="Prefix čárových kodů"
              rules={{
                required: 'Vyžadováno',
                maxLength: { value: 1, message: 'Max jeden znak' },
                pattern: { value: /[a-zA-Z]/, message: 'Lze použít pouze malé nebo velké písmeno' },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <SwitchForm name="active" label="Je aktivní?" />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="secondary"
              component={Link}
              to={'/settings?active=departments'}
              sx={{ mr: '10px' }}
            >
              Zpět
            </Button>
            <Button variant="contained" color="primary" type="submit">
              Uložit
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default DepartmentForm;
