export const IS_LOADING = 'IS_LOADING';
export const USER_STORE = 'USER_STORE';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const GRID_SETTINGS_STORE = 'GRID_SETTINGS_STORE';
export const ADD_TO_RESERVATION_ITEMS = 'ADD_TO_RESERVATION_ITEMS';
export const SET_RESERVATION_ITEMS = 'SET_RESERVATION_ITEMS';
export const REMOVE_FROM_RESERVATION_ITEMS = 'REMOVE_FROM_RESERVATION_ITEMS';
export const CLEAR_RESERVATION_ITEMS = 'CLEAR_RESERVATION_ITEMS';
export const ADD_SAMPLES = 'ADD_SAMPLES';
export const ADD_LENDINGS = 'ADD_LENDINGS';
export const SAVE_LENDINGS_VALUES = 'SAVE_LENDINGS_VALUES';

export const SAMPLE_STATES = [
  { id: 'AVAILABLE', label: 'K dispozici' },
  { id: 'LENT', label: 'Zapůjčen' },
  { id: 'DISCARDED', label: 'Vyřazený' },
  { id: 'SOLD', label: 'Prodán' },
  { id: 'INTERNAL_USAGE', label: 'Interní použití' },
  { id: 'INCOMPLETE', label: 'Nekompletní' },
  { id: 'IN_CASE', label: 'V kufru' },
  { id: 'SPECIAL', label: 'Speciální' },
  { id: 'UNDEFINED', label: 'Neznámý' },
];

export const LENDING_PERIODS = [
  { id: 7, label: '1 týden' },
  { id: 14, label: '2 týdny' },
  { id: 21, label: '3 týdny' },
  { id: 30, label: 'měsíc' },
  { id: 61, label: '2 měsíce' },
  { id: 91, label: '3 měsíce' },
];
export const STOCK_TAKING_STATES = [
  { id: 'IN_PROGRESS', label: 'probíhající' },
  { id: 'FINISHED', label: 'dokončená' },
];

export const ERROR_CODES = [
  {
    code: 'department.item.relation.exists',
    label: 'Nelze uložit. Oddělení musí zůstat aktivní, protože obsahuje vzorky.',
  },
  {
    code: 'department.user.relation.exists',
    label: 'Nelze uložit. Oddělení musí zůstat aktivní, protože obsahuje uživatele.',
  },
  {
    code: 'department.code.prefix.required.unique',
    label: 'Nelze uložit. Prefix pro oddělení už byl použit.',
  },
  {
    code: 'department.duplicity.error',
    label: 'Oddělení s tímto názvem již existuje.',
  },
  {
    code: 'items_can_not_be_borrowed',
    label: 'Některé vzorky nemohou být zapůjčeny.',
  },
  {
    code: 'location.item.relation.exists',
    label: 'Nelze uložit. Umístění musí zůstat aktivní, protože obsahuje vzorky.',
  },
  {
    code: 'location_duplicity.error',
    label: 'Umístění s tímto názvem a adresou již existuje.',
  },

  {
    code: 'user.item.relation.exists',
    label: 'Uživatel nemůže být smazán nebo neaktivní, protože má přiděleny vzorky.',
  },
  {
    code: 'user.loan.relation.error',
    label: 'Uživatel nemůže být smazán nebo neaktivní, protože má zápůjčky.',
  },
  {
    code: 'dealer.has.active.loans',
    label: 'Nelze uložit. Obchodník má aktivní zápůjčky.',
  },
  {
    code: 'dealer_exists',
    label: 'Obchodník s tímto jménem již existuje.',
  },
  {
    code: 'item.code.exists',
    label: 'Nelze uložit, duplikace ID vzorku.',
  },
  {
    code: 'user.and.item.department.not.match',
    label: 'Oddělení uživatele a vzorku se neshodují.',
  },
  {
    code: 'item.location.or.department.not-match.case',
    label: 'Nelze vložit do kufru, pokud umístění nebo oddělení neodpovídá kufru',
  },
  {
    code: 'company.delete.error',
    label: 'Nelze smazat zákazníka, protože má aktivní zápůjčky.',
  },
  {
    code: 'item_not_from_department',
    label: 'Nelze zapůjčit vzorek, který je z jiného oddělení než je uživatel.',
  },
  {
    code: 'item.without.activity.error',
    label: 'Nelze uložit vzorek, který neobsahuje aktivitu.',
  },
  {
    code: 'item.without.discount.group.error',
    label: 'Nelze uložit vzorek, který neobsahuje rabatovou skupinu.',
  },
  {
    code: 'item.without.commercialization.status.error',
    label: 'Nelze uložit vzorek, který neobsahuje status komercializace.',
  },
  {
    code: 'stock_taking_running',
    label: 'Inventura tohoto oddělení již probíhá.',
  },
  {
    code: 'customer_exists',
    label: 'Zákazník se stejným názvem a adresou již existuje.',
  },
];
