import { get } from 'lodash';
import { api } from 'utils/hooks/useApi';

export const getWorkplaces = (): Promise<any> =>
  api()
    .get(`/pae-api/workplace/getAll?allowedAuthMethodId=db`)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });

export const getWorkplace = (id: any): Promise<any> =>
  api()
    .get(`/pae-api/workplace/get?id=${id}&allowedAuthMethodId=db`)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });

export const getAllRoles = (): Promise<any> =>
  api()
    .get(`/pae-api/workplace/getAllRoles`)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });

export const createWorkplace = (values: any): Promise<any> =>
  api()
    .post(`/pae-api/workplace/create?allowedAuthMethodId=db`, values)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });

export const manageUsers = (values: any): Promise<any> =>
  api()
    .post(`/pae-api/workplace/manageUsers`, values)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });

export const updateWorkplace = (values: any): Promise<any> =>
  api()
    .post(`/pae-api/workplace/update?allowedAuthMethodId=db`, values)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });

export const deleteWorkplace = (id: any): Promise<any> =>
  api()
    .delete(`/pae-api/workplace/delete?id=${id}&allowedAuthMethodId=db`)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });

export const manageRoles = (values: any): Promise<any> =>
  api()
    .post(`/pae-api/workplace/manageRoles`, values)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });
