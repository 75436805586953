import { api } from 'utils/hooks/useApi';
import { get } from 'lodash';

export const refreshToken = (): Promise<any> =>
  api()
    .get(`/pae-api/refreshJwtToken`)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });

export const getUserGridSettings = (): Promise<any> =>
  api()
    .get(`/pae-api/user/getUserSetting?settingType=GRID`)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });

export const setUserGridSettings = (values: Object): Promise<any> =>
  api()
    .post(`/pae-api/user/setUserSetting`, { settingType: 'GRID', setting: values })
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });

export const getUserRoles = (): Promise<any> =>
  api()
    .get(`/pae-api/user/getUserRoles`)
    .then((response: any) => get(response, 'data'))
    .catch(() => {
      return false;
    });
