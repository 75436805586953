import { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Button, Grid, Stack, Chip } from '@mui/material';
import { find, filter, get, includes, pickBy, keys } from 'lodash';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import moment from 'moment';

import InputForm from 'components/Form/Input/Input';
import AutocompleteForm from 'components/Form/Autocomplete/Autocomplete';
import AutocompleteMultipleForm from 'components/Form/Autocomplete/AutocompleteMultiple';
import { getDepartments } from 'modules/Settings/Departments/_api';
import { getLocations } from 'modules/Settings/Locations/_api';
import useLoader from 'utils/hooks/useLoader';
import { SAMPLE_STATES } from 'utils/constants';
import { getUsers } from 'modules/Settings/Users/_api';
import useSort from 'utils/hooks/useSort';
import useRoleValidation from 'utils/hooks/useRoleValidation';
import { useSelector } from 'react-redux';
import useUserInfo from 'utils/hooks/useUserInfo';

const SampleSearchForm: React.FC<any> = ({
  onSearch,
  setSamples,
  resultsCount,
  lendings = false,
  selection,
  setSelection,
  setMultipleEditDialog,
  editCase = false,
  actualRows,
  columns,
  editedCase,
}) => {
  const methods = useForm();
  const { handleSubmit, reset, watch, setValue } = methods;
  const [users, setUsers] = useState<any>([]);
  const [locations, setLocations] = useState<any>([]);
  const [departments, setDepartments] = useState<any>([]);
  const setLoader = useLoader();
  const csSort = useSort();
  const { checkRole } = useRoleValidation();
  const columnOrder = useSelector((state: any) =>
    get(state, 'app.settings.grids.samples.columnOrder'),
  );
  const [searchChips, setSearchChips] = useState<any>([]);
  const { userIsAdmin, userDepartments } = useUserInfo();

  const handleChips = (values: any) => {
    const chips: any[] = [];
    keys(values).forEach((el: any) => {
      switch (el) {
        case 'state':
          chips.push(
            get(values, 'state')
              .map((state: any) => state.label)
              .join(', '),
          );
          return;
        case 'user':
          chips.push(get(values, 'user.label'));
          return;
        case 'isCase':
          chips.push(get(values, 'isCase.id') === 1 ? 'Kufr' : 'není kufr');
          return;
        case 'department':
          chips.push(get(values, 'department.label'));
          return;
        case 'location':
          chips.push(get(values, 'location.label'));
          return;
        default:
          chips.push(get(values, el));
          return;
      }
    });
    setSearchChips(chips.filter((x: any) => x !== ''));
  };

  const user = watch('user');

  const loadEntities = async () => {
    setLoader();
    const [users, locAct, locInact, depAct, depInact] = await Promise.all([
      getUsers(),
      getLocations(),
      getLocations(false),
      getDepartments(),
      getDepartments(false),
    ]);
    if (users) {
      setUsers(
        csSort(users, 'lastName').map((item: any) => ({
          id: item.id,
          label: `${item.lastName || item.username} ${item.firstName || ''} `,
        })),
      );
    }
    if (locAct && locInact) {
      const locations = [...locAct, ...locInact].map((item: any) => ({
        ...item,
        activeLabel: item.active ? 'aktivní' : 'neaktivní',
      }));
      const locationsActive = filter(locations, { active: true }).sort((a: any, b: any) =>
        (a.name || '').localeCompare(b.name || ''),
      );
      const locationsInactive = filter(locations, { active: false }).sort((a: any, b: any) =>
        (a.name || '').localeCompare(b.name || ''),
      );
      setLocations([...locationsActive, ...locationsInactive]);
    }
    if (depAct && depInact) {
      const departments = [...depAct, ...depInact].map((item: any) => ({
        ...item,
        activeLabel: item.active ? 'aktivní' : 'neaktivní',
      }));
      const departmentsActive = filter(departments, { active: true }).sort((a: any, b: any) =>
        (a.name || '').localeCompare(b.name || ''),
      );
      const departmentsInactive = filter(departments, { active: false }).sort((a: any, b: any) =>
        (a.name || '').localeCompare(b.name || ''),
      );

      setDepartments([...departmentsActive, ...departmentsInactive]);
    }
    setLoader(false);
  };

  const exportExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Export');
    const rowValues: any = [];
    columnOrder.forEach((order: any, i: number) => {
      const col = find(columns, { name: order });
      rowValues[i] = get(col, 'title', '');
    });
    worksheet.addRow(rowValues);

    actualRows.forEach((row: any) => {
      const rowValues: any = [];
      columnOrder.forEach((order: any, i: number) => {
        if (includes(['location', 'department'], order)) {
          rowValues[i] = get(row, `${order}.name`, '');
        } else if (includes(['createdDate'], order)) {
          rowValues[i] = moment(get(row, order, '')).isValid()
            ? moment(get(row, order, '')).format('DD.MM.YYYY HH:mm:ss')
            : '';
        } else {
          rowValues[i] = get(row, order, '');
        }
      });
      worksheet.addRow(rowValues);
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'export.xlsx');
    });
  };

  useEffect(() => {
    if (user) {
      setValue('state', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    loadEntities();
    if (editCase) {
      reset({
        isCase: { id: 2, label: 'Ne' },
        state: [find(SAMPLE_STATES, { id: 'AVAILABLE' })],
        location: { id: editedCase.location.id, label: editedCase.location.name },
        department: { id: editedCase.department.id, label: editedCase.department.name },
      });
    } else if (lendings) {
      reset({
        state: [find(SAMPLE_STATES, { id: 'AVAILABLE' })],
        department: userIsAdmin
          ? undefined
          : { id: get(userDepartments, '[0].id'), label: get(userDepartments, '[0].name') },
      });
    } else {
      reset({
        state: [
          find(SAMPLE_STATES, { id: 'AVAILABLE' }),
          // find(SAMPLE_STATES, { id: 'INTERNAL_USAGE' }),
        ],
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit((values) => {
          handleChips(pickBy(values));
          onSearch(values);
        })}
      >
        <Grid container={true} spacing={1}>
          <Grid item xs={12} md={2} lg={1}>
            <InputForm name="reference" label="Reference" autoComplete="off" />
          </Grid>
          <Grid item xs={12} md={2} lg={1}>
            <InputForm name="code" label="ID" />
          </Grid>

          <Grid item xs={12} md={3} lg={3}>
            <AutocompleteMultipleForm
              name="state"
              label="Stav"
              options={SAMPLE_STATES}
              disableClearable={false}
              disabled={lendings}
              multiple={true}
            />
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <AutocompleteForm
              name="user"
              label="Interní použití"
              options={users}
              disableClearable={false}
            />
          </Grid>
          <Grid item xs={12} md={2} lg={1}>
            <AutocompleteForm
              name="isCase"
              label="Vzorek je kufr"
              options={[
                { id: 1, label: 'Ano' },
                { id: 2, label: 'Ne' },
              ]}
              disableClearable={false}
              disabled={!!editCase}
            />
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <AutocompleteForm
              name="department"
              label="Oddělení"
              options={departments.map((item: any) => ({
                id: item.id,
                label: item.name,
                activeLabel: item.activeLabel,
              }))}
              disableClearable={false}
              groupBy={(option: any) => option.activeLabel}
            />
          </Grid>
          <Grid item xs={12} md={2} lg={1}>
            <AutocompleteForm
              name="location"
              label="Umístění"
              options={locations.map((item: any) => ({
                id: item.id,
                label: item.name,
                activeLabel: item.activeLabel,
              }))}
              disableClearable={false}
              groupBy={(option: any) => option.activeLabel}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container={true} spacing={1}>
              <Grid item xs={12} md={2} lg={2}>
                <InputForm name="description" label="Popis" />
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <InputForm name="discountGroup" label="Rabatová skupina" />
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <InputForm name="activity" label="Aktivita" />
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <InputForm name="commercializationStatus" label="Status komercializace" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} id="search-results">
            <Grid
              container={true}
              spacing={2}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={6} md={3}>
                <Button variant="contained" color="primary" type="submit">
                  Vyhledat
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ ml: '10px' }}
                  onClick={() => {
                    reset({
                      state: [
                        find(SAMPLE_STATES, { id: 'AVAILABLE' }),
                        //           find(SAMPLE_STATES, { id: 'INTERNAL_USAGE' }),
                      ],
                    });
                    setSamples([]);
                    setSelection([]);
                    setSearchChips([]);
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: 'smooth',
                    });
                  }}
                >
                  Reset
                </Button>
              </Grid>
              <Grid item xs={6} md={3}>
                <Stack direction="row" spacing={1}>
                  {searchChips.map((chip: any, i: any) => (
                    <Chip key={i} label={chip} variant="outlined" />
                  ))}
                </Stack>
              </Grid>
              {selection && selection.length > 0 && (
                <Grid item xs={6} md={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ ml: '10px' }}
                    onClick={() => {
                      setMultipleEditDialog(true);
                    }}
                    disabled={!checkRole('ROLE_ITEM_UPDATE')}
                  >
                    Hromadná úprava
                  </Button>
                  {/*<Button
                    variant="contained"
                    color="primary"
                    sx={{ ml: '10px' }}
                    onClick={() => {}}
                  >
                    Přidat do kufru
                  </Button> */}
                </Grid>
              )}
              <Grid item xs={6} md={3}>
                {resultsCount >= 0 ? `Počet nalezených vzorků: ${resultsCount}` : ''}
                {selection && selection.length > 0 ? `, Vybráno: ${selection.length}` : ''}
                {/*<span style={{ marginLeft: 30 }}>
                  <Tooltip title="Exportovat do xlsx">
                    <IconButton aria-label="export" onClick={() => exportExcel()}>
                      <img src="/excel-icon.png" alt="xls-export" />
                    </IconButton>
                  </Tooltip>
                </span> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default SampleSearchForm;
