import { useForm, FormProvider } from 'react-hook-form';
import { Button, Grid } from '@mui/material';

import DateForm from 'components/Form/Date/Date';
import AutocompleteForm from 'components/Form/Autocomplete/Autocomplete';

const LendingsSearchForm: React.FC<any> = ({ onSearch, resultsCount, lendings = false }) => {
  const methods = useForm();
  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSearch)}>
        <Grid container={true} spacing={1}>
          {console.log('lendings form')}
          <Grid item xs={6} md={2}>
            <AutocompleteForm
              name="returned"
              label="Stav"
              options={[
                { id: 1, label: 'Zapůjčeno' },
                { id: 2, label: 'Vráceno' },
              ]}
              disableClearable={false}
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <DateForm name="createdDateStart" label="Datum zapůjčení od" clearable={true} />
          </Grid>
          <Grid item xs={6} md={2}>
            <DateForm name="createdDateEnd" label="Datum zapůjčení do" clearable={true} />
          </Grid>
          <Grid item xs={12}>
            <Grid
              container={true}
              spacing={2}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={6} md={3}>
                <Button variant="contained" color="primary" type="submit">
                  Vyhledat
                </Button>
              </Grid>
              <Grid item xs={6} md={3}>
                {resultsCount >= 0 ? `Počet nalezených zápůjček: ${resultsCount}` : ''}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default LendingsSearchForm;
