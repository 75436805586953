import { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Button, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { filter, forEach, get } from 'lodash';

import InputForm from 'components/Form/Input/Input';
import CheckboxArray from 'components/Form/CheckboxArray/CheckboxArray';
import useLoader from 'utils/hooks/useLoader';
import { getAllRoles } from './_api';

const roleSections = [
  {
    name: 'ITEM',
    label: 'Vzorky',
  },
  {
    name: 'LOAN',
    label: 'Zápůjčky',
  },
  { name: 'STOCK_TAKING', label: 'Inventura' },
  { name: 'SETTING_USER', label: 'Nastavení uživatelů' },
  { name: 'SETTING_WORK_PLACE', label: 'Nastavení pracovních míst' },
  { name: 'SETTING_COMPANY', label: 'Nastavení zákazníků' },
  { name: 'SETTING_CUSTOMER', label: 'Nastavení kontaktní osoby u zákazníků' },
  { name: 'SETTING_DEPARTMENT', label: 'Nastavení oddělení' },
  { name: 'SETTING_LOCATION', label: 'Nastavení umístění' },
  { name: 'SETTING_DEALER', label: 'Nastavení obchodníků' },
  { name: 'OTHER', label: 'Ostatní' },
];

const WorkplaceForm: React.FC<any> = ({ workplace, edit, onSubmit }) => {
  const methods = useForm();
  const { handleSubmit, reset, watch } = methods;
  const [roles, setRoles] = useState<any>(null);
  const setLoader = useLoader();

  const loadRoles = async () => {
    setLoader();
    const resp = await getAllRoles();
    if (resp) {
      setRoles(resp);
    } else {
      setRoles(false);
    }
    setLoader(false);
  };

  useEffect(() => {
    if (workplace && edit) {
      let roles = {};
      forEach(get(workplace, 'roles', []), (item: any) => {
        roles = { ...roles, [item.id]: true };
      });
      reset({
        name: get(workplace, 'name', '') || '',
        code: get(workplace, 'code', '') || '',
        roles,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workplace, edit]);

  useEffect(() => {
    loadRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container={true} spacing={2}>
          <Grid item xs={6} md={3}>
            <InputForm
              name="name"
              label="Název"
              rules={{ required: 'Vyžadováno' }}
              disabled={edit && get(workplace, 'code') === 'ADMIN'}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <InputForm
              name="code"
              label="Kód"
              rules={{ required: 'Vyžadováno' }}
              disabled={edit && get(workplace, 'code') === 'ADMIN'}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container={true} spacing={2}>
              {roleSections.map((item) => (
                <Grid item xs={12} md={4} lg={3} key={item.name}>
                  <h3>{item.label}</h3>
                  <CheckboxArray
                    name={`roles`}
                    items={filter(roles, { section: item.name })}
                    disabled={
                      (edit && get(workplace, 'code') === 'ADMIN') ||
                      item.name === 'SETTING_WORK_PLACE'
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="secondary"
              component={Link}
              to={'/settings?active=workplaces'}
              sx={{ mr: '10px' }}
            >
              Zpět
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={edit && get(workplace, 'code') === 'ADMIN'}
            >
              Uložit
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default WorkplaceForm;
